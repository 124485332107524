<template>
  <base-layout>
    <div class="auth-container" id="register-page">
      <div class="guest-page-form" v-if="isShowForm">
        <div class="home">
          <router-link to="/">
            <img src="/images/nvx/nvx-banner-2.png" alt="logo" />
          </router-link>
          <h2>{{ $t('register.sub_title') }}</h2>
        </div>
        <div class="form-container">
          <!-- Form Title -->
          <div class="guest-page-form-body" autocomplete="off">
            <!-- Email -->
            <div class="input-group">
              <div class="custom-input">
                <div
                  class="form-input"
                  :class="{
                    error: errors.has('email'),
                  }"
                >
                  <img src="/images/mail-login.svg" alt="mail" />
                  <input
                    type="text"
                    :name="'email'"
                    :maxlength="maxlength"
                    data-vv-validate-on="none"
                    v-model.trim="email"
                    @focus="resetError"
                    :placeholder="$t('common.placeholders.email')"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                <span v-show="errors.has('email')">
                  {{ errors.first('email') }}
                </span>
              </div>
            </div>
            <!-- Mobile Number -->
            <div class="input-group">
              <div class="custom-input">
                <div class="form-input phone-number" :class="{ error: errors.has('phone_no') }">
                  <img src="@/assets/images/phone.svg" alt="" />
                  <select-box
                    v-model="code"
                    :options="countryCode"
                    propertyOption="value"
                    :placeholder="`+${code}`"
                    isSearchCountry
                    isSelectPhone
                  />
                  <input
                    ref="number"
                    name="phone_no"
                    @keypress="isNumber(e)"
                    data-vv-validate-on="none"
                    v-model.trim="number"
                    @focus="resetError"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                <span v-show="errors.has('phone_no')">
                  {{ errors.first('phone_no') }}
                </span>
              </div>
            </div>
            <!-- Password -->
            <div class="input-group">
              <div class="custom-input">
                <div
                  class="form-input"
                  :class="{
                    error: errors.has('password'),
                  }"
                >
                  <img src="@/assets/images/grey-lock.svg" alt="lock" />
                  <input
                    ref="password"
                    name="password"
                    :maxlength="maxlength"
                    data-vv-validate-on="none"
                    v-model.trim="password"
                    :placeholder="$t('common.placeholders.password')"
                    @focus="resetError"
                    :type="isEyeOpen ? 'text' : 'password'"
                  />
                  <img
                    @click="isEyeOpen = !isEyeOpen"
                    class="icon-last"
                    :src="`/images/eye-${isEyeOpen ? 'open' : 'close'}.svg`"
                    alt="eye"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                <span v-show="errors.has('password')">
                  {{ errors.first('password') }}
                </span>
              </div>
            </div>
            <!-- Confirm Password -->
            <div class="input-group">
              <div class="custom-input">
                <div class="form-input" :class="{ error: errors.has('confirm_password') }">
                  <img src="@/assets/images/grey-lock.svg" alt="lock" />
                  <input
                    name="confirm_password"
                    :maxlength="maxlength"
                    data-vv-validate-on="none"
                    v-model.trim="passwordConfirmation"
                    :placeholder="$t('common.placeholders.confirm_password')"
                    @focus="resetError"
                    :type="isEyeOpenConfirm ? 'text' : 'password'"
                  />
                  <img
                    @click="isEyeOpenConfirm = !isEyeOpenConfirm"
                    class="icon-last"
                    :src="`/images/eye-${isEyeOpenConfirm ? 'open' : 'close'}.svg`"
                    alt="eye"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                <span v-show="errors.has('confirm_password')">
                  {{ errors.first('confirm_password') }}
                </span>
              </div>
            </div>
            <!-- Refferal ID -->
            <div class="input-group">
              <div class="custom-input">
                <div
                  class="form-input"
                  :class="{
                    error: errors.has('referrer_code'),
                  }"
                >
                  <input
                    type="text"
                    name="referrer_code"
                    :maxlength="maxlength"
                    v-model.trim="referralCode"
                    :placeholder="$t('common.placeholders.referral')"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                <span v-show="errors.has('referrer_code')">
                  {{ errors.first('referrer_code') }}
                </span>
              </div>
            </div>
            <!-- Capcha -->
            <div class="input-group">
              <VueRecaptcha
                id="register-captcha"
                ref="recaptcha"
                :sitekey="GOOGLE_CAPTCHA_KEY"
                @verify="onVerifyCapcha"
                @expired="onExpiredCapcha"
              />
              <div class="invalid-feedback">
                <span v-show="failCapcha === true">
                  {{ $t('common.geetest.fail') }}
                </span>
                <span v-show="isCapchaExpired === true">
                  {{ $t('validation.auth.captcha_expired') }}
                </span>
              </div>
            </div>

            <div class="input-group last">
              <div class="checked-group">
                <div class="checkbox-input">
                  <input
                    :class="{
                      error: errors.has('error_terms'),
                    }"
                    type="checkbox"
                    id="checkboxFiveInput"
                    v-model="agree"
                  />
                  <label for="checkboxFiveInput"></label>
                </div>
                <label class="form-check-label">
                  {{ $t('register.terms_1') }}
                  <a target="_blank" href="/terms">{{ $t('register.terms_2') }}</a>
                </label>
              </div>
              <div class="invalid-feedback">
                <span v-if="errors.has('error_terms')">
                  {{ errors.first('error_terms') }}
                </span>
                <span v-if="errors.has('error')">
                  {{ errors.first('error') }}
                </span>
              </div>
            </div>
            <div class="form_actions">
              <button
                :class="{
                  chrome_only: isChrome && !errors.any(),
                  disabledBtn: isSubmitting,
                }"
                tabindex="0"
                :disabled="isSubmitting"
                type="button"
                class="btn-primary-loading"
                @click="register"
              >
                <img src="/images/loading.png" class="loading-img" />{{
                  getSubmitName($t('menu.signup'))
                }}
              </button>
            </div>
            <!-- Login Redirect -->
            <div class="to_login">
              {{ $t('register.has_account') }}
              <router-link to="/login">{{ $t('login.title') }}</router-link>
            </div>
            <div class="policy" v-html="$t('register.policy')"></div>
          </div>
        </div>
      </div>
      <div class="form-container" v-show="isConfirmPhone">
        <div class="home">
          <router-link to="/">
            <img src="/images/nvx/nvx-banner-2.png" alt="logo" />
          </router-link>
          <h2>{{ $t('otp.title') }}</h2>
          <p>{{ $t('otp.subtitle', { phone_no: maskPhoneNumberFunc() }) }} <br /></p>
        </div>
        <div class="form-otp-container">
          <div class="form-otp">
            <div
              class="register-otp otp-input-fields"
              :class="{ error: errorCodeOtp }"
              ref="otpCont"
            >
              <input
                type="text"
                autocomplete="one-time-code"
                inputmode="numeric"
                style="width: 100%"
                v-model="autoOtpSMS"
                maxlength="6"
                @keypress="isNumber($event)"
              />
            </div>
            <div class="otp-error" v-show="errorCodeOtp">
              <img src="@/assets/images/warning.svg" alt="" />
              <span class="invalid-feedback">{{ $t('verify_code.sms_invalid_or_expired') }}</span>
            </div>

            <button @click="verifySMSOtp" :class="[isCountDown ? 'count' : '']">
              {{ $t('otp.continue') }}
            </button>
            <div class="dont-receive">
              <div class="text-countdown-register" v-if="isCountDown">
                {{ formartSecondToMinute(timeCountDown) }}
              </div>
              <span
                style="margin-left: 6px; font-weight: 500; color: #108cdd"
                class="resend"
                :class="[isCountDown ? 'disable' : '']"
                @click="resendWAOtp"
                >{{ $t('funds.withdrawals.otp.resend.titleWa') }}</span
              >
            </div>
            <div style="margin-top: 16px" v-show="isShowResendSms">
              <ResendOtpVue :isDisable="isCountDown" @button-resend-otp="resendSMSOtp" />
            </div>
          </div>
        </div>
      </div>
      <div class="form-success" v-if="isConfirmSuccess">
        <div class="form-success-container">
          <div class="modal-header">
            <button type="button" class="close-popup" data-dismiss="modal" @click="closeSuccessOtp">
              <img src="/images/close.svg" alt="close" />
            </button>
          </div>
          <div class="modal-body">
            <div class="icon">
              <img src="@/assets/images/confirm-email-success.svg" alt="success" />
            </div>
            <h3 class="title">
              {{ $t('register.congratulation') }}
            </h3>
            <p class="modal-content-text">
              {{ $t('register.success_3') }}
            </p>
            <button class="btn-resend" @click="reSendEmail">
              {{ $t('register.title_2') }}
            </button>
          </div>
        </div>
      </div>

      <ConfirmationModal />
    </div>
  </base-layout>
</template>

<script>
import rf from '@/request/RequestFactory';
import RemoveErrorsMixin from '@/common/RemoveErrorsMixin';
import BaseLayout from './BaseLayout.vue';
import SucceedModal from './SucceedModal.vue';
import COMMON_CONST from '@/common/Const';
import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal';
import { VueRecaptcha } from 'vue-recaptcha';
import SelectBox from '@/components/shared_components/common/SelectBox';
import countryCode from '@/assets/country.json';
import { formartSecondToMinute } from '@/common/Utils';
import { maskPhoneNumber } from '@/utils/masking-phone';
import ResendOtpVue from '@/components/ResendOtp.vue';
const countDown = 60;
export default {
  data() {
    return {
      isShowResendSms: false,
      maskedPhoneNumber: '',
      email: '',
      number: '',
      password: '',
      passwordConfirmation: '',
      modalMessage: '',
      referralCode: '',
      isSubmiting: false,
      agree: false,
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      isShowForm: true,
      isConfirmMailShow: false,
      isConfirmPhone: false,
      isConfirmSuccess: false,
      isChrome: window.navigator.userAgent.toLowerCase().indexOf('chrome') > 0,
      recaptchaKey: process.env.MIX_RECAPTCHA_KEY,
      isEyeOpen: false,
      isEyeOpenConfirm: false,
      geetestData: '',
      GOOGLE_CAPTCHA_KEY: process.env.VUE_APP_GOOGLE_CAPTCHA_KEY,
      is_ggcaptcha: 1,
      failCapcha: false,
      isCapchaExpired: false,
      finalOtp: [],
      otpCont: null,
      code: '62',
      countryCode: countryCode,
      errorCodeOtp: false,
      isCountDown: false,
      timeCountDown: countDown,
      funcCountDown: null,
      formartSecondToMinute: formartSecondToMinute,
      autoOtpSMS: '',
    };
  },
  props: {
    phone: { type: String, default: '' },
    region: { type: String, default: '' },
  },
  mixins: [RemoveErrorsMixin],
  components: {
    BaseLayout,
    SucceedModal,
    ConfirmationModal,
    VueRecaptcha,
    SelectBox,
    ResendOtpVue,
  },
  computed: {
    phone_no() {
      if (this.number.startsWith('0')) {
        return `+${this.code}${this.number.slice(1)}`;
      }
      return `+${this.code}${this.number}`;
    },
    phoneNumberLength() {
      return this.code.length + this.number.length;
    },
  },
  watch: {
    timeCountDown() {
      if (this.timeCountDown === 0) {
        this.isCountDown = false;
        this.timeCountDown = countDown;
        this.isShowResendSms = true;
        clearInterval(this.funcCountDown);
      }
    },
    phone() {
      if (this.phone && this.region) {
        this.isConfirmPhone = true;
        this.isShowForm = false;
        this.number = this.phone;
        this.code = this.region;
      } else {
        this.isConfirmPhone = false;
        this.isShowForm = true;
        this.number = this.phone;
        this.code = '62';
      }
    },
    async autoOtpSMS(newVal, oldVal) {
      if (newVal !== '') {
        // this.$refs.otpInput.otp = newVal.split('');
        this.handleOnChange(newVal);
        if (newVal.length === 6) {
          this.verifySMSOtp();
        }
      }
    },
  },

  methods: {
    refreshRecaptcha() {
      this.$refs.recaptcha.reset();
    },
    maskPhoneNumberFunc() {
      return maskPhoneNumber(this.phone_no);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    resetError() {
      this.errors.clear();
      this.failCapcha = false;
    },
    onVerifyCapcha(response) {
      this.failCapcha = false;
      this.geetestData = response;
      this.is_ggcaptcha = 1;
    },
    onExpiredCapcha() {
      this.failCapcha = true;
      this.geetestData = null;
      this.is_ggcaptcha = 1;
    },
    async register() {
      this.isCapchaExpired = false;
      this.resetError();

      if (this.isSubmitting) {
        return;
      }
      if (_.isEmpty(this.email)) {
        this.errors.add({
          field: 'email',
          msg: this.$t('validation.auth.email_required'),
        });
      }
      if (this.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)) {
        this.errors.add({
          field: 'email',
          msg: this.$t('validation.auth.email_format'),
        });
      }
      if (_.isEmpty(this.number)) {
        this.errors.add({
          field: 'phone_no',
          msg: this.$t('validation.auth.number_required'),
        });
      }
      if (this.number) {
        if (
          this.number.includes('+') ||
          this.number.includes('-') ||
          this.number.includes('(') ||
          this.number.includes(')') ||
          !/^([0-9\s\-\+\(\)]*)$/.test(this.number)
        ) {
          this.errors.add({
            field: 'phone_no',
            msg: this.$t('register.invalid_phone_no_1', { phone: this.number }),
          });
        }
        if (this.phoneNumberLength < 9 || this.phoneNumberLength > 29) {
          this.errors.add({
            field: 'phone_no',
            msg: this.$t('register.invalid_phone_no_2'),
          });
        }
      }
      if (_.isEmpty(this.password)) {
        this.errors.add({
          field: 'password',
          msg: this.$t('validation.auth.password_required'),
        });
      }
      if (this.password && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(this.password)) {
        this.errors.add({
          field: 'password',
          msg: this.$t('validation.auth.password_format'),
        });
      }
      if (_.isEmpty(this.passwordConfirmation)) {
        this.errors.add({
          field: 'confirm_password',
          msg: this.$t('validation.auth.password_confirm_required'),
        });
      }
      if (this.password.length > 72) {
        this.errors.add({
          field: 'password',
          msg: this.$t('validation.auth.password_max'),
        });
      }
      if (!this.errors.has('password') && this.password !== this.passwordConfirmation) {
        this.errors.add({
          field: 'confirm_password',
          msg: this.$t('validation.auth.password_confirmed_not_match'),
        });
      }

      if (!this.geetestData) {
        this.failCapcha = true;
      }

      if (!this.agree) {
        this.errors.add({
          field: 'error_terms',
          msg: this.$t('register.error_terms'),
        });
      }

      if (this.failCapcha || this.errors.any()) {
        return;
      }

      const params = {
        email: this.email,
        phone_no: this.phone_no,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        referralCode: this.referralCode,
      };

      this.startSubmit();
      try {
        let result = await rf
          .getRequest('UserRequest')
          .register(params, this.geetestData, this.is_ggcaptcha);

        this.endSubmit();
        this.isShowForm = false;
        this.isConfirmPhone = true;
        this.$router.push({
          path: '/register',
          query: { phone: this.number, region: this.code },
        });
        // this.showRegisteredModal()
        this.isCountDown = true;
        this.funcCountDown = setInterval(() => {
          this.timeCountDown -= 1;
        }, 1000);
        this.agree = false;
      } catch (e) {
        this.endSubmit();
        if (e.response?.data?.message === 'server.logs') {
          this.errors.add('error', this.$t('server.errors'));
        } else if (e.response?.data?.message === 'validation.auth.captcha_expired') {
          this.isCapchaExpired = true;
          this.refreshRecaptcha();
        } else if (e.response && e.response.data.errors) {
          Object.keys(e.response.data.errors).forEach((key) => {
            this.errors.add({
              field: key,
              msg: this.$t(e.response.data.errors[key][0]),
            });
          });
        } else {
          this.errors.add('error', this.$t(error.response.data.message));
        }
      }
    },
    onGeetestUpdated(data) {
      this.geetestData = data;
    },
    showRegisteredModal() {
      this.isConfirmMailShow = false;
    },
    reSendEmail() {
      this.startSubmit();
      rf.getRequest('UserRequest')
        .resendConfirm({ email: this.email })
        .then((response) => {
          this.endSubmit();
          this.showModalConfirm(true, this.email);
        })
        .catch((error) => {
          this.endSubmit();
          this.convertRemoteErrors(error);
        });
    },
    onModalClose() {
      this.$router.push({
        path: '/',
      });
    },
    showModalConfirm(result, email) {
      this.modalMessage = result
        ? this.$t('login.resend_confirm_mail_success', { email })
        : this.$t('login.active_device_failed');
      this.showModal('confirm-succeed-resent-email-modal', this.modalMessage);
    },
    showModal(type, message) {
      window.ConfirmationModal.show({
        type: type,
        title: message,
        content: '',
        btnCancelLabel: null,
        btnConfirmLabel: null,
        onConfirm: () => {},
        onCancel: () => {},
        widthModal: '381px',
      });
    },
    handleKeyDown(event, index) {
      if (event.key !== 'Tab' && event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') {
        event.preventDefault();
      }

      if (
        event.key === 'Backspace' ||
        event.key === 'Delete' ||
        event.which === 8 ||
        event.which === 46 ||
        !event.key ||
        !event.which
      ) {
        this.$set(this.finalOtp, index, null);

        if (index != 0) {
          this.$refs.otpCont?.children[index - 1].focus();
        }
        return;
      }
      if (new RegExp('^([0-9])$').test(event.key)) {
        this.$set(this.finalOtp, index, event.key);

        if (index != 5) {
          this.$refs.otpCont?.children[index + 1].focus();
        }
      }
    },

    clearInputOtp(event) {
      event.target.value = event.target.value.split('')[0];
    },
    verifySMSOtp() {
      const isEmptyOtp = this.finalOtp.some((item) => {
        if (_.isEmpty(item) || _.isNull(item)) return true;
        return false;
      });

      if (this.finalOtp.length !== 6 || isEmptyOtp) {
        return (this.errorCodeOtp = true);
      }
      this.startSubmit();
      rf.getRequest('UserRequest')
        .confirmSMS(this.finalOtp.join(''), this.phone_no)
        .then((response) => {
          this.isConfirmSuccess = true;
          this.isShowForm = true;
          this.isConfirmPhone = false;
        })
        .catch((error) => {
          this.errorCodeOtp = true;
        })
        .finally(() => {
          this.endSubmit();
        });
    },
    resendSMSOtp() {
      this.isCountDown = true;
      this.funcCountDown = setInterval(() => {
        this.timeCountDown -= 1;
      }, 1000);
      this.startSubmit();
      rf.getRequest('UserRequest')
        .resendConfirmSMS({
          phone_no: this.phone_no,
        })
        .then((response) => {
          Message.success(this.$i18n.t('register.send_otp_success'), {}, { position: 'top_right' });
        })
        .catch((error) => {
          Message.error(this.$i18n.t('register.send_otp_failed'), {}, { position: 'bottom_left' });
        })
        .finally(() => {
          this.endSubmit();
        });
    },
    resendWAOtp() {
      this.isCountDown = true;
      this.funcCountDown = setInterval(() => {
        this.timeCountDown -= 1;
      }, 1000);
      this.startSubmit();
      rf.getRequest('UserRequest')
        .resendConfirmWA({
          phone_no: this.phone_no,
        })
        .then((response) => {
          Message.success(this.$i18n.t('register.send_otp_success'), {}, { position: 'top_right' });
        })
        .catch((error) => {
          Message.error(this.$i18n.t('register.send_otp_failed'), {}, { position: 'bottom_left' });
        })
        .finally(() => {
          this.endSubmit();
        });
    },
    closeSuccessOtp() {
      this.isConfirmSuccess = false;
      this.isShowForm = true;
      this.email = '';
      this.number = '';
      this.password = '';
      this.passwordConfirmation = '';
      this.referralCode = '';
      this.finalOtp = [];
    },
    async handleOnComplete(value) {
      this.finalOtp = await value.split('');
      this.verifySMSOtp();
    },
    handleOnChange(value) {
      this.errorCodeOtp = false;
      this.finalOtp = value.split('');
    },
    handleOnChange(value) {
      this.errorCodeOtp = false;
      this.finalOtp = value.split('');
      this.autoOtpSMS = value;
    },
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
  },
  created() {
    const referralCd = this.$route?.query?.ref;
    if (referralCd) {
      this.referralCode = referralCd;
    }
  },
  mounted() {
    this.maskPhoneNumberFunc();
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Enter' && this.$route.name === 'Register') {
        this.register();
      }
    });
    if (this.phone && this.region) {
      this.number = this.phone;
      this.code = this.region;
      this.isConfirmPhone = true;
      this.isShowForm = false;

      this.isCountDown = true;
      this.timeCountDown = countDown;
      this.funcCountDown = setInterval(() => {
        this.timeCountDown -= 1;
      }, 1000);
    }
  },
};
</script>
<style lang="scss" scoped>
.text-countdown-register {
  font-weight: 700;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  color: #23212c;
}
.form-container {
  .logo {
    padding-left: 23px;
    padding-top: 16px;

    i {
      display: none;
      font-size: 20px;
      cursor: pointer;
    }

    @include mobile {
      padding-top: 70px;

      a {
        display: none;
      }

      i {
        display: block;
      }
    }
  }

  .form-otp-container {
    width: 420px;
    max-width: 104%;
    display: flex;
    align-items: center;
    justify-content: center;

    .form-otp {
      width: 100%;

      .container-title {
        .title {
          font-size: 24px;
          line-height: 30px;
          color: #000000;
          text-align: center;
          margin-bottom: 19px;
        }

        .sub {
          font-size: 18px;
          line-height: 26px;
          color: #000000;
          text-align: center;
          margin-bottom: 8px;
        }
      }

      .otp-error {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 5px;

        .invalid-feedback {
          width: auto;
          padding: 0;
          margin-top: 0;
        }
      }

      .otp-input-fields {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        @include mobile {
          margin-bottom: 16px;
        }

        input {
          width: 52px;
          height: 52px;
          border-radius: 10px;
          border: 1px solid $bg-main2;
          background: $text-main1;
          font-size: 18px;
          font-weight: 400;
          line-height: 32px;
          color: rgba($text-main2, 0.87);
          text-align: center;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &:focus {
            border: 1px solid $bg-main1;
          }
        }

        &.error {
          input {
            border: 1px solid $color-red;
            background: rgba($color-red, 0.1);
          }
        }
      }

      .dont-receive {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        flex-direction: row;
        display: flex;
        justify-content: center;

        .resend {
          font-weight: 700;
          color: rgba($text-main2, 0.87);
          cursor: pointer;

          &.disable {
            pointer-events: none;
          }
        }
      }

      .count-down {
        margin: 15px 0;
        text-align: center;
      }

      button {
        @include btn-common(contained, lg);
        margin-bottom: 16px;
        margin-top: 20px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
#register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 50px 0;

  .home {
    margin-bottom: 32px;

    a {
      display: flex;
      justify-content: center;

      img {
        width: 98px;
      }
    }

    h2 {
      margin-top: 32px;
      margin-bottom: 8px;
      text-align: center;
      color: $text-main2;
      font-size: 36px;
      font-weight: 700;
      line-height: 56px;

      @include mobile {
        font-size: 24px;
        line-height: 40px;
      }
    }

    p {
      margin: 0;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: rgba($text-main2, 0.6);
    }
  }

  .input-group {
    margin-bottom: 24px;

    @include mobile {
      margin-bottom: 16px;
    }

    .custom-input {
      .form-input {
        &.phone-number {
          display: flex;
          align-items: center;

          ::v-deep {
            .search-country {
              width: 307px;
              max-width: calc(100vw - 32px);
            }

            .button_drop_search {
              padding: 0;
              gap: 10px;
              background: transparent;
            }

            .box_list_search_select {
              top: calc(100% + 12px);
              left: -45px;
              width: 307px;
              max-width: calc(100vw - 32px);
            }
          }

          input {
            padding-left: 8px;
            margin-left: 8px;
            border-left: 1px solid $bg-main2;
            border-radius: 0;
          }
        }

        &.error {
          border: 1px solid $color-red !important;
          background: rgba(255, 91, 91, 0.1) !important;
        }

        input {
          background: transparent;
        }
      }
    }
  }

  .form-container {
    width: 400px;
    max-width: 100vw;
    padding: 0 16px;
  }

  .form-check-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $bg-main1;
    margin: 0;

    a {
      color: inherit;
    }
  }

  .form_actions {
    margin-top: 16px;
    margin-bottom: 16px;

    button {
      @include btn-common(contained, lg);
    }

    @include tablet-min {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .policy {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: $text-main5;
    padding: 0 25px;

    :deep(a) {
      color: $color-blue2;
    }
  }
}

:deep {
  #register-captcha {
    display: flex;
    justify-content: center;

    > div {
      @include mobile {
        width: auto !important;
        height: auto !important;
        overflow: auto;
      }
    }
  }
}

.input-group.last {
  margin-bottom: 30px;
}

.checked-group {
  margin-bottom: 0;
  display: flex;
  gap: 16px;
  align-items: center;
}

.form-container {
  .btn-primary {
    margin-top: 0;
  }
}

.term-error {
  margin-top: -12px;
  margin-bottom: 12px;
}

.re-sendmail a {
  font-size: 13px;
}

.re-sendmail i {
  font-size: 11px;
}

.form-success {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);

  .form-success-container {
    width: 344px;
    padding: 24px;
    background: #ffffff;
    border-radius: 16px;
    position: relative;

    .modal-header {
      border: 0;
      padding: 0;
      position: absolute;
      top: 12px;
      right: 12px;

      .close-popup {
        background: none;
      }
    }

    .modal-body {
      padding: 0;

      .icon {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;

        @include mobile {
          img {
            width: 100px;
          }
        }
      }

      .title {
        color: $text-main;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 8px;
        text-align: center;
      }

      .modal-content-text {
        color: $text-secondary;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 24px;
        padding: 0 10px;
        text-align: center;
      }

      .btn-resend {
        @include btn-common(contained, md);
      }
    }
  }
}

.input-group-prepend {
  span {
    line-height: 50px;
  }
}

ul {
  list-style-type: disc;
}

.header-account {
  display: flex;
  text-align: center;
  margin-bottom: 28px;
}

.title-l {
  margin: 13px 0px 15px 0px;
  height: 1px;
  width: 35%;
  border-radius: 5px;
  background-color: $color-light-grey;
}

.title-l-2 {
  margin: 35px 0px 15px 0px;
  height: 1px;
  width: 15%;
  border-radius: 5px;
  background-color: #f2f2f2;
}

.title-account {
  margin: 0;
}

.guest-page-massge {
  // font-weight: bold;
  font-family: $font-family-roboto-medium;
}

.guest-page-guide {
  background: $color-bright-gray;
  border-radius: 3px;
}

.guest-page-guide-content {
  padding: 22px 0px 15px 24px;
  font-family: $font-family-roboto-medium;

  h5 {
    font-size: $font-small;
    font-weight: bold;
  }

  ul {
    font-size: $font-small;
    line-height: 25px;
    color: $color-grey-dusty;
    font-family: $font-family-roboto;

    li {
      list-style: none;
    }

    li:before {
      content: '. ';
      padding-right: 13px;
      font-size: 24px;
      vertical-align: middle;
      line-height: 20px;
    }
  }
}

.to_login {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: $text-main6;
  text-align: center;
  margin-bottom: 16px;

  a {
    font-weight: 700;
    color: $text-main2;
  }
}

.recaptcha-form {
  margin-bottom: 12px;
}
</style>
<style lang="scss">
.register-otp.otp-input-fields.error .otp-input-new {
  border: 1px solid $color-red !important;
  background: rgba(255, 91, 91, 0.1) !important;
}
.otp-input-new {
  margin: 0 !important;
  width: 52px;
  height: 52px;
  border: 1px solid #dddddd;
  border-radius: 10px !important;
}
</style>
