export default {
  window_title: '{APP_NAME}',
  message: {
    'response.request_invalid': 'Permintaan Anda tidak valid!',
    not_auth_to_trade:
      'Anda tidak berwenang memperdagangkan pasangan ini. Silakan hubungi admin untuk informasi lebih lanjut.',
  },
  terms: {
    title: 'Ketentuan Penggunaan',
    last_revised: '[Terakhir direvisi: 09 Jan 2024 ] ',
    introduce_1:
      "NVX adalah platform untuk komunitas perdagangan sirkulasi aset digital berorientasi masa depan, yang situs webnya <a href='{APP_URL}'>{APP_URL}</a> (selanjutnya disebut 'Situs Web Ini' atau 'Situs Web') adalah platform yang didedikasikan untuk transaksi aset digital dan penyediaan layanan terkait (selanjutnya disebut 'Layanan'). Untuk kenyamanan kata-kata dalam Perjanjian ini, NVX dan Situs Web disebut sebagai 'NVX' atau bentuk kata ganti orang pertama lainnya yang berlaku dalam Perjanjian ini. Semua orang perseorangan atau subjek lain yang masuk ke Situs Web ini akan menjadi pengguna Situs Web ini. Untuk kenyamanan kata-kata dalam Perjanjian ini, pengguna dan NVX secara kolektif disebut sebagai “kedua belah pihak”, dan secara individual sebagai “satu pihak”. Dan semua layanan yang disediakan oleh NVX disebut sebagai “layanan”.",
    introduce_2:
      'Perdagangan aset digital sangat berisiko dan karenanya tidak cocok untuk sebagian besar orang. Pengguna mengakui dan memahami bahwa investasi dalam aset digital dapat mengakibatkan kerugian sebagian atau seluruh investasi pengguna dan oleh karena itu pengguna disarankan untuk memutuskan jumlah investasi pengguna berdasarkan kapasitas menanggung kerugian pengguna. Selanjutnya, selain risiko yang disebutkan di atas, mungkin juga ada risiko yang tidak dapat diprediksi. Oleh karena itu, pengguna disarankan untuk mempertimbangkan dengan hati-hati dan menggunakan penilaian yang jelas untuk menilai posisi keuangan pengguna dan risiko yang disebutkan di atas sebelum membuat keputusan tentang pembelian dan penjualan aset digital; setiap dan semua kerugian yang timbul daripadanya akan ditanggung oleh pengguna dan NVX tidak akan bertanggung jawab dengan cara apa pun.',
    term_1: {
      title: '1. Ketentuan Umum',
      content_1_1:
        '1.1. Sebelum menggunakan layanan apa pun yang ditawarkan oleh Situs Web ini, pengguna harus membaca Perjanjian ini dengan seksama, dan berkonsultasi dengan pengacara profesional jika pengguna memiliki keraguan atau sebagaimana diperlukan. Jika pengguna tidak setuju dengan Syarat dan Ketentuan Perjanjian ini dan/atau perubahan apa pun yang dibuat dari waktu ke waktu dan kapan saja, harap segera hentikan penggunaan layanan yang disediakan oleh Situs Web ini atau hentikan login ke Situs Web ini. Setelah pengguna masuk ke Situs Web ini atau menggunakan layanan apa pun yang ditawarkan oleh Situs Web ini atau terlibat dalam aktivitas serupa lainnya, itu akan dianggap sebagai pengguna telah memahami dan sepenuhnya menyetujui semua syarat dan ketentuan Perjanjian ini, termasuk semua dan perubahan apa pun.',
      content_1_2:
        '1.2. Setelah mengisi informasi yang relevan sesuai dengan persyaratan Situs Web ini, dan melalui prosedur lain yang relevan, pengguna berhasil mendaftarkan dirinya sebagai anggota Situs Web ini (selanjutnya disebut "Anggota"); dalam proses pendaftaran, jika pengguna mengklik tombol "Saya Setuju", itu akan dianggap bahwa pengguna telah mencapai kesepakatan dengan NVX melalui tanda tangan elektronik; atau ketika pengguna menggunakan Situs Web ini, pengguna mengklik tombol "Saya Setuju" atau tombol serupa, atau jika pengguna menggunakan layanan yang ditawarkan oleh Situs Web ini dengan cara apa pun yang diizinkan oleh Situs Web ini, itu akan dianggap bahwa pengguna sepenuhnya memahami, setuju, dan menerima semua Syarat dan Ketentuan di bawah Perjanjian ini, dan dalam hal ini, tidak adanya tanda tangan tulisan tangan pengguna tidak akan mempengaruhi kekuatan pengikat hukum yang mungkin dimiliki Perjanjian ini terhadap pengguna.',
      content_1_3:
        '1.3. Setelah pengguna menjadi anggota Situs Web ini, pengguna akan menerima akun anggota dan kata sandi yang sesuai, yang harus disimpan dengan baik oleh pengguna sebagai anggota Situs Web ini; Anggota akan bertanggung jawab atas semua aktivitas dan peristiwa yang dilakukan melalui akun mereka yang NVX tidak bertanggung jawab atasnya.',
      content_1_3_1:
        'Pengguna tidak dapat terlibat dalam perdagangan aset digital melalui Situs Web ini dan mendapatkan akses ke layanan yang secara eksklusif tersedia untuk anggota sesuai dengan aturan dan ketentuan Situs Web ini, kecuali dan sampai pengguna menjadi anggota Situs Web ini; jika pengguna bukan anggota Situs Web ini, pengguna hanya dapat masuk dan menjelajahi Situs Web dan memiliki akses ke layanan lain yang diizinkan oleh aturan dan ketentuan Situs Web ini.',
      content_1_4:
        '1.4. Setelah pengguna mendaftar sebagai anggota Situs Web ini dan menggunakan salah satu layanan dan fungsi yang ditawarkan oleh Situs Web ini, itu akan dianggap bahwa pengguna telah membaca, memahami Perjanjian ini, dan ketentuan di bawah ini.',
      content_1_4_1:
        '1.4.1. Disetujui untuk terikat oleh semua Syarat dan Ketentuan Perjanjian ini',
      content_1_4_2:
        '1.4.2. Pengguna mengonfirmasi bahwa pengguna telah mencapai persyaratan yang diwajibkan oleh hukum yang berlaku, dan memiliki kapasitas yang cukup untuk menerima syarat dan ketentuan di sini, untuk melakukan transaksi, dan untuk menggunakan Situs Web ini untuk transaksi aset digital.',
      content_1_4_3:
        '1.4.3. Pengguna berjanji bahwa semua aset digital pengguna yang terlibat dalam transaksi di bawah ini diperoleh secara legal dan dimiliki oleh pengguna.',
      content_1_4_4:
        '1.4.4. Pengguna setuju untuk menanggung setiap dan semua kewajiban atas transaksi dan aktivitas non-transaksi pengguna sendiri serta setiap dan semua keuntungan dan kerugian daripadanya dan NVX tidak bertanggung jawab atas hal ini.',
      content_1_4_5:
        '1.4.5. Pengguna mengonfirmasi bahwa informasi yang diberikan pada saat pendaftaran adalah benar dan akurat.',
      content_1_4_6:
        '1.4.6. Pengguna setuju untuk mematuhi semua dan semua hukum yang relevan, termasuk melaporkan keuntungan transaksi apa pun untuk tujuan pajak.',
      content_1_4_7:
        '1.4.7. Perjanjian ini hanya mengikat hak dan kewajiban antara pengguna dan NVX, dan tidak melibatkan hubungan hukum dan sengketa hukum yang timbul dari dan berhubungan dengan transaksi aset digital antara pengguna Situs Web ini, dan antara situs web lain dan pengguna.',
    },
    term_2: {
      title: '2. Perubahan Perjanjian ini',
      content_2_1:
        'NVX berhak untuk mengubah Perjanjian ini dari waktu ke waktu, dan mengungkapkan perubahan tersebut melalui pengumuman di Situs Web tanpa mengirimkan pemberitahuan terpisah kepada pengguna tentang hak-hak pengguna. Tanggal perubahan akan dicantumkan pada halaman pertama perjanjian yang diubah. Perjanjian yang diubah akan berlaku segera setelah diumumkan di Situs Web. Pengguna harus menelusuri Situs Web ini dari waktu ke waktu dan mengikuti informasi tentang waktu dan konten perubahan, jika ada, yang dibuat pada Perjanjian ini. Jika pengguna tidak setuju dengan perubahan tersebut, pengguna harus segera berhenti menggunakan layanan yang ditawarkan oleh Situs Web ini; jika pengguna terus menggunakan layanan yang ditawarkan oleh Situs Web ini, maka dianggap bahwa pengguna menerima dan setuju untuk terikat oleh perjanjian yang diubah.',
    },
    term_3: {
      title: '3. Pendaftaran',
      title_1: '3.1. Kelayakan untuk Pendaftaran',
      content_3_1_1:
        'Pengguna harus mengonfirmasi bahwa pengguna adalah orang perseorangan, korporasi atau organisasi lain yang memiliki kemampuan untuk menandatangani perjanjian ini berdasarkan hukum yang berlaku saat menyelesaikan proses pendaftaran atau menggunakan Layanan di Situs Web dengan cara lain yang diizinkan. Jika pengguna mengklik tombol "Setuju" untuk pendaftaran, pengguna setuju dengan ketentuan perjanjian ini dan masuk, atau agen yang ditunjuk oleh pengguna setuju dengan ketentuan perjanjian ini atas nama pengguna, diasumsikan bahwa pengguna telah terdaftar di situs web ini dan menggunakan layanan tersebut.',
      content_3_1_2:
        'Jika pengguna bukan orang perseorangan, badan hukum atau organisasi dengan kemampuan tersebut di atas, pengguna dan agen resmi pengguna akan menanggung semua konsekuensinya, dan NVX berhak untuk membatalkan atau membekukan akun pengguna secara permanen dan untuk meminta pertanggungjawaban pengguna dan agen resmi pengguna.',
      title_2: '3.2. Tujuan Pendaftaran',
      content_3_2_1:
        'Pengguna menegaskan dan berjanji bahwa pengguna tidak mendaftar ke Situs Web ini untuk tujuan melanggar hukum atau peraturan yang berlaku atau merusak tatanan transaksi aset digital di Situs Web ini.',
      title_3: '3.3. Proses Pendaftaran',
      content_3_3_1:
        '3.3.1. Pengguna setuju untuk memberikan alamat email yang valid, nomor ponsel, dan informasi lain sesuai dengan persyaratan pada halaman pendaftaran pengguna Situs Web ini. Pengguna dapat menggunakan alamat email, nomor ponsel atau cara lain yang diizinkan oleh Situs Web ini untuk masuk ke Situs Web ini. Jika diperlukan dan sesuai dengan persyaratan hukum dan peraturan yang berlaku dari yurisdiksi terkait, pengguna harus memberikan nama asli, kartu identitas, dan informasi lain yang diperlukan oleh hukum, peraturan, Ketentuan Privasi, dan ketentuan anti pencucian uang, dan terus memperbarui data pendaftaran pengguna sehingga akan tepat waktu, rinci dan akurat seperti yang dipersyaratkan. Semua data yang diketik asli akan dijadikan referensi sebagai informasi pendaftaran. Pengguna bertanggung jawab atas keaslian, integritas, dan keakuratan informasi tersebut dan menanggung segala kerugian langsung atau tidak langsung dan konsekuensi yang merugikan yang timbul darinya.',
      content_3_3_2:
        '3.3.2. Jika ada hukum, peraturan, aturan, perintah, dan dokumen peraturan lain yang berlaku dari negara atau wilayah berdaulat tempat pengguna berada mengharuskan akun ponsel harus berdasarkan nama asli, pengguna dengan ini mengonfirmasi bahwa nomor ponsel yang diberikan pengguna untuk tujuan pendaftaran telah melalui prosedur pendaftaran nama asli. Jika pengguna tidak dapat memberikan nomor ponsel seperti yang dipersyaratkan, setiap kerugian langsung atau tidak langsung dan konsekuensi yang merugikan yang timbul darinya dan mempengaruhi pengguna akan ditanggung oleh pengguna.',
      content_3_3_3:
        '3.3.3. Setelah pengguna memberikan informasi pendaftaran yang diperlukan secara sah, lengkap dan sah dan informasi tersebut lolos verifikasi terkait, pengguna berhak untuk mendapatkan akun dan kata sandi Situs Web ini. Setelah memperoleh akun dan kata sandi tersebut, pendaftaran pengguna dianggap berhasil dan pengguna dapat masuk ke Situs Web ini sebagai anggotanya.',
      content_3_3_4:
        '3.3.4. Pengguna setuju untuk menerima email dan/atau pesan singkat yang dikirim oleh Situs Web ini terkait dengan pengelolaan dan pengoperasiannya.',
    },
    term_4: {
      title: '4. Layanan',

      content_4_0:
        'Situs Web ini hanya menyediakan layanan platform transaksi online bagi pengguna untuk terlibat dalam aktivitas perdagangan aset digital melalui Situs Web ini (termasuk tetapi tidak terbatas pada transaksi aset digital, dll.). Situs Web ini tidak berpartisipasi dalam transaksi aset digital sebagai pembeli atau penjual.',
      title_1: '4.1. Isi Layanan',
      content_4_1_1:
        '4.1.1. Pengguna berhak untuk menelusuri kutipan real-time dan informasi transaksi produk aset digital di Situs Web ini, untuk mengirimkan instruksi transaksi aset digital dan untuk menyelesaikan transaksi aset digital melalui Situs Web ini.',
      content_4_1_2:
        '4.1.2. Pengguna berhak untuk berpartisipasi dalam kegiatan situs web yang diselenggarakan oleh Situs Web ini sesuai dengan aturan kegiatan yang diposting di Situs Web ini.',
      content_4_1_3:
        '4.1.3. Layanan lain yang dijanjikan Situs Web ini untuk ditawarkan kepada pengguna.',
      title_2: '4.2. Aturan Layanan',
      content_4_2_0: 'Pengguna berjanji untuk mematuhi aturan layanan Situs Web ini berikut ini:',
      content_4_2_1:
        '4.2.1. Pengguna harus mematuhi ketentuan hukum, peraturan, aturan, dan persyaratan kebijakan yang berlaku, dan memastikan legalitas sumber semua aset digital di akun pengguna, dan harus menahan diri dari terlibat dalam aktivitas ilegal atau aktivitas lain yang merusak hak dan kepentingan Situs Web ini atau pihak ketiga mana pun, seperti mengirim atau menerima informasi yang melanggar hukum, melanggar hukum atau melanggar hak dan kepentingan orang lain, mengirim atau menerima informasi skema piramida atau informasi atau komentar yang menyebabkan kerugian lainnya, penggunaan yang tidak sah atau pemalsuan informasi header email Situs Web ini, antara lain.',
      content_4_2_2:
        '4.2.2. Pengguna harus mematuhi hukum dan peraturan yang berlaku dan benar menggunakan dan menyimpan akunnya di Situs Web ini dan kata sandi masuk, kata sandi transaksi keuangan pengguna, dan nomor ponsel yang terikat dengan akun pengguna yang pengguna berikan pada saat pendaftaran akun pengguna, serta keamanan kode verifikasi yang diterima melalui ponsel pengguna. Pengguna bertanggung jawab penuh atas semua operasi pengguna yang dilakukan menggunakan akunnya dengan Situs Web ini dan kata sandi masuk, kata sandi transaksi keuangan, kode verifikasi yang dikirim ke ponsel pengguna, serta semua konsekuensi dari operasi tersebut. Ketika pengguna menemukan bahwa akun pengguna dengan Situs Web ini, kata sandi masuk pengguna, kata sandi transaksi keuangan, atau kode verifikasi ponsel digunakan oleh pihak ketiga yang tidak berwenang, mengungkap masalah lain yang berkaitan dengan keamanan akun pengguna, pengguna harus memberi tahu Situs Web ini secara tepat waktu dan efektif, dan meminta Situs Web ini untuk sementara menangguhkan layanan ke akun pengguna dengan Situs Web ini. Situs Web ini berhak untuk mengambil tindakan atas permintaan pengguna dalam waktu yang wajar; meskipun demikian, Situs Web ini tidak bertanggung jawab atas konsekuensi yang timbul sebelum tindakan tersebut diambil, termasuk tetapi tidak terbatas pada kerugian apa pun yang mungkin dialami pengguna. Pengguna tidak boleh mengalihkan akunnya dengan Situs Web ini kepada orang lain dengan cara donasi, pinjaman, sewa, transfer, atau lainnya tanpa persetujuan Situs Web ini.',
      content_4_2_3:
        '4.2.3. Pengguna setuju untuk bertanggung jawab atas semua aktivitas (termasuk tetapi tidak terbatas pada pengungkapan informasi, rilis informasi, klik online untuk menyetujui atau mengirimkan berbagai perjanjian tentang aturan, pembaruan perjanjian online atau layanan pembelian) menggunakan akun dan kata sandi pengguna dengan Situs Web ini.',
      content_4_2_4:
        '4.2.4. Dalam transaksi aset digital pengguna di Situs Web ini, pengguna tidak boleh dengan sengaja mengganggu kelancaran transaksi aset digital atau mengganggu ketertiban transaksi; pengguna tidak boleh menggunakan cara teknis atau cara lain untuk mengganggu operasi normal Situs Web ini atau mengganggu pengguna lain menggunakan layanan; pengguna tidak boleh dengan sengaja mencemarkan nama baik bisnis Situs Web ini dengan alasan fakta yang dipalsukan.',
      content_4_2_5:
        '4.2.5. Jika timbul perselisihan antara pengguna dan pengguna lain sehubungan dengan transaksi online, pengguna tidak boleh menggunakan cara lain selain cara peradilan atau pemerintah untuk meminta Situs Web ini memberikan informasi yang relevan.',
      content_4_2_6:
        '4.2.6. Semua pajak yang harus dibayar serta semua biaya yang berkaitan dengan perangkat keras, perangkat lunak, dan layanan yang dikeluarkan oleh pengguna selama menggunakan layanan yang disediakan oleh Situs Web ini akan ditanggung sepenuhnya oleh pengguna.',
      content_4_2_7:
        '4.2.7. Pengguna harus mematuhi Perjanjian ini dan syarat layanan dan aturan operasi lain yang dapat dirilis oleh Situs Web ini dari waktu ke waktu, dan pengguna berhak untuk mengakhiri penggunaan layanan yang disediakan oleh Situs Web ini kapan saja.',
      title_3: '4.3. Aturan produk',
      content_4_3_1: '4.3.1. Aturan untuk produk perdagangan aset digital',
      content_4_3_1_0:
        'Pengguna berjanji bahwa dalam proses masuk ke Situs Web ini dan melakukan transaksi mata uang-ke-mata uang melalui Situs Web ini, pengguna akan mematuhi aturan transaksi berikut dengan benar:',
      content_4_3_1_1: '4.3.1.1. Melihat informasi transaksi',
      content_4_3_1_2: '4.3.1.2. Pengajuan Komisi',
      content_4_3_1_3: '4.3.1.3. Mengakses detail transaksi',
      content_4_3_1_4:
        '4.3.1.4. Menarik/memodifikasi komisi transaksi. Pengguna berhak untuk menarik atau mengubah komisi transaksinya kapan saja sebelum transaksi selesai.',
    },
    term_5: {
      title: '5. Hak dan Kewajiban Situs Web ini',
      content_5_1:
        '5.1. Jika pengguna tidak memiliki kualifikasi pendaftaran yang disepakati dalam Perjanjian ini, Situs Web ini berhak untuk menolak pendaftaran pengguna; jika pengguna telah terdaftar, Situs Web ini berhak untuk mencabut akun anggota pengguna, dan Situs Web ini berhak untuk meminta pertanggungjawaban kepada pengguna atau agen resmi pengguna. Selanjutnya, Situs Web ini berhak untuk memutuskan apakah akan menerima aplikasi pengguna untuk pendaftaran dalam keadaan lain.',
      content_5_2:
        '5.2. Ketika Situs Web ini menemukan bahwa pengguna suatu Akun bukanlah pendaftar awal Akun tersebut, maka Situs Web ini berhak untuk menangguhkan atau menghentikan akses pengguna ke Akun tersebut.',
      content_5_3:
        '5.3. Di mana dengan cara pengujian teknis atau pengambilan sampel manual, antara lain, Situs Web ini secara wajar mencurigai bahwa informasi yang diberikan pengguna salah, tidak benar, tidak valid atau tidak lengkap, Situs Web ini berhak untuk memberi tahu pengguna untuk memperbaiki atau memperbarui informasi tersebut , atau menangguhkan atau menghentikan penyediaan layanannya kepada pengguna.',
      content_5_4:
        '5.4. Situs Web ini berhak untuk mengoreksi informasi apa pun yang ditampilkan di Situs Web ini ketika menemukan kesalahan yang jelas dalam informasi tersebut.',
      content_5_5:
        '5.5. Situs Web ini berhak untuk mengubah, menangguhkan, atau menghentikan Layanan yang ditawarkan oleh Situs Web ini, kapan saja, dan hak untuk mengubah atau menangguhkan Layanan tanpa pemberitahuan sebelumnya kepada pengguna; jika Situs Web ini menghentikan satu atau lebih Layanan yang ditawarkan oleh Situs Web ini, penghentian oleh Situs Web ini akan berlaku pada tanggal pengumuman penghentian tersebut di Situs Web.',
      content_5_6:
        '5.6. Situs Web ini akan mengambil sarana teknis yang diperlukan dan tindakan manajemen untuk memastikan operasi normal Situs Web ini, dan akan menyediakan lingkungan perdagangan dan layanan transaksi yang diperlukan dan andal, dan akan menjaga ketertiban perdagangan aset digital.',
      content_5_7:
        '5.7. Situs Web ini akan memastikan keamanan aset digital pengguna dengan memperkuat masukan teknis dan meningkatkan tindakan pencegahan keamanan, dan berkewajiban untuk memberi tahu pengguna sebelumnya tentang risiko keamanan yang dapat diperkirakan dalam akun pengguna.',
      content_5_8:
        '5.8. Situs Web ini berhak, sesuai dengan hukum yang berlaku, peraturan administrasi, aturan, perintah, dan dokumen peraturan lainnya dari negara atau wilayah berdaulat tempat pengguna berada, meminta lebih banyak informasi atau data kepada pengguna, dan mengambil tindakan yang wajar untuk memenuhi persyaratan standar lokal, dan pengguna berkewajiban untuk memberikan bantuan yang tepat untuk tindakan tersebut; Situs Web ini berhak untuk menangguhkan atau menghentikan secara permanen akses pengguna ke Situs Web ini serta sebagian atau seluruh layanan yang ditawarkan oleh Situs Web ini.',
    },
    term_6: {
      title: '6. Ganti Rugi',
      content_6_1:
        '6.1. Dalam keadaan apa pun, tanggung jawab kami atas kerusakan langsung pengguna tidak akan melebihi total biaya yang dikeluarkan oleh pengguna selama tiga (3) bulan penggunaan layanan yang ditawarkan oleh Situs Web ini.',
      content_6_2:
        '6.2. Jika pengguna melanggar Perjanjian ini atau hukum atau peraturan administrasi yang berlaku, pengguna harus membayar kepada kami setidaknya US$ Dua Juta sebagai kompensasi dan menanggung semua biaya yang terkait dengan pelanggaran tersebut (termasuk biaya pengacara, antara lain). Jika kompensasi tersebut tidak dapat menutupi kerugian yang sebenarnya, pengguna harus menanggung selisihnya.',
    },
    term_7: {
      title: '7. Hak Atas Tindakan Penghentian',
      content_7_0:
        'Baik pengguna maupun kami mengakui bahwa pemulihan hukum umum untuk pelanggaran perjanjian atau kemungkinan pelanggaran kontrak mungkin tidak cukup untuk menutupi semua kerugian yang kami tanggung; oleh karena itu, dalam hal terjadi pelanggaran kontrak atau kemungkinan pelanggaran kontrak, pihak yang tidak melanggar hak tersebut memiliki hak untuk mencari tindakan penghentian serta semua pemulihan lain yang diizinkan berdasarkan hukum umum atau ekuitas.',
    },
    term_8: {
      title: '8. Pembatasan dan Pengecualian Tanggung Gugat',
      content_8_1:
        '8.1. Pengguna memahami dan setuju bahwa dalam keadaan apa pun NVX tidak akan bertanggung jawab atas peristiwa berikut:',
      content_8_1_1: '8.1.1. Kehilangan pendapatan',
      content_8_1_2: '8.1.2. Kehilangan keuntungan transaksi atau kerugian kontraktual',
      content_8_1_3: '8.1.3. Gangguan bisnis',
      content_8_1_4: '8.1.4. Kerugian kerugian mata uang yang diharapkan',
      content_8_1_5: '8.1.5. Kehilangan informasi',
      content_8_1_6: '8.1.6. Hilangnya peluang, kerusakan reputasi atau reputasi',
      content_8_1_7: '8.1.7. Kerusakan atau kehilangan data',
      content_8_1_8: '8.1.8. Biaya pembelian produk atau layanan alternatif',
      content_8_1_9:
        '8.1.9. Kerugian atau kerusakan tidak langsung, khusus, atau insidental yang timbul dari pelanggaran apa pun (termasuk kelalaian), pelanggaran kontrak, atau penyebab lainnya, terlepas dari apakah kerugian atau kerusakan tersebut dapat diperkirakan secara wajar oleh kami, dan terlepas dari apakah kami diberitahu sebelumnya tentang kemungkinan kerugian atau kerusakan tersebut.',
      content_8_1_10: '8.1.10. Item 8.1.1 hingga 8.1.9 bersifat independen satu sama lain.',
      content_8_2:
        '8.2. Pengguna memahami dan setuju bahwa NVX tidak akan bertanggung jawab atas segala kerusakan yang disebabkan oleh salah satu peristiwa berikut:',
      content_8_2_1:
        '8.2.1. Di mana kami memiliki pembenaran yang tepat untuk meyakini bahwa transaksi spesifik pengguna mungkin melibatkan pelanggaran serius atau pelanggaran hukum atau perjanjian',
      content_8_2_2:
        '8.2.2. Di mana kami memiliki pembenaran yang tepat untuk meyakini bahwa perilaku pengguna di Situs Web ini dicurigai ilegal atau tidak pantas',
      content_8_2_3:
        '8.2.3. Pengeluaran dan kerugian yang timbul dari pembelian atau perolehan data, informasi atau transaksi, dll. melalui layanan yang ditawarkan oleh Situs Web ini',
      content_8_2_4:
        '8.2.4. Kesalahpahaman pengguna tentang Layanan yang ditawarkan oleh Situs Web ini',
      content_8_2_5:
        '8.2.5. Kerugian lain terkait layanan yang disediakan oleh Situs Web ini, yang tidak dapat dikaitkan dengan kami.',
      content_8_3:
        '8.3. Di mana NVX gagal menyediakan Layanan atau penundaan dalam menyediakan Layanan tersebut karena pemeliharaan peralatan jaringan informasi, kegagalan konektivitas jaringan informasi, kesalahan pada komputer, komunikasi, atau sistem lain, pemadaman listrik, kondisi cuaca, kecelakaan tak terduga, tindakan industrial, sengketa tenaga kerja, pemberontakan, kerusuhan, kerusuhan, kurangnya produktivitas atau bahan produksi, kebakaran, banjir, badai, ledakan, perang, kegagalan bank atau mitra lain, runtuhnya pasar aset digital, tindakan pemerintah, kehakiman atau otoritas administratif, tindakan lain yang berada di luar kendali NVX atau di luar ketidakmampuan kami untuk mengontrol, atau karena penyebab dari pihak ketiga, NVX tidak akan menanggung tanggung jawab apa pun atas kegagalan untuk menyediakan layanan atau penundaan dalam menyediakan layanan, atau untuk kerugian yang ditimbulkan sebagai akibat dari kegagalan atau penundaan tersebut.',
      content_8_4:
        '8.4. NVX tidak dapat menjamin bahwa semua informasi, program, teks, dll. yang terkandung dalam Situs Web ini sepenuhnya aman, bebas dari gangguan dan kerusakan oleh program jahat seperti virus, Trojan, dll., oleh karena itu, pengguna masuk ke Situs Web ini atau penggunaan layanan apa pun yang ditawarkan oleh Situs Web ini, pengunduhan program, informasi, dan data apa pun dari Situs Web ini dan penggunaannya oleh pengguna adalah keputusan pribadi pengguna dan oleh karena itu pengguna akan menanggung semua risiko dan kerugian yang mungkin timbul.',
      content_8_5:
        '8.5. NVX tidak membuat jaminan dan komitmen sehubungan dengan informasi, produk, dan bisnis situs web pihak ketiga mana pun yang ditautkan ke Situs Web ini, serta konten bentuk lain yang bukan milik NVX; pengguna menggunakan salah satu layanan, informasi, dan produk yang disediakan oleh situs web pihak ketiga adalah keputusan pribadi pengguna dan oleh karena itu pengguna akan menanggung semua tanggung jawab yang timbul daripadanya.',
      content_8_6:
        '8.6. NVX tidak memberikan jaminan eksplisit atau implisit terkait penggunaan Layanan yang ditawarkan oleh Situs Web ini oleh pengguna, termasuk namun tidak terbatas pada penerapan, bebas dari kesalahan atau kelalaian, konsistensi, akurasi, keandalan, dan penerapan untuk tujuan tertentu, dari layanan yang disediakan oleh Situs Web ini. Selain itu, NVX tidak membuat komitmen atau jaminan sehubungan dengan validitas, akurasi, kebenaran, keandalan, kualitas, stabilitas, integritas, dan ketepatan waktu dari teknologi dan informasi yang dicakup oleh layanan yang ditawarkan oleh Situs Web ini. Apakah akan masuk ke Situs Web ini atau menggunakan layanan yang disediakan oleh Situs Web ini adalah keputusan pribadi pengguna dan oleh karena itu pengguna akan menanggung semua risiko dan kemungkinan kerugian yang timbul dari keputusan tersebut. NVX tidak memberikan jaminan eksplisit atau implisit sehubungan dengan pasar, nilai, dan harga aset digital; pengguna memahami dan mengakui bahwa pasar aset digital tidak stabil, bahwa harga dan nilai aset dapat berfluktuasi atau runtuh kapan saja, dan bahwa transaksi aset digital didasarkan pada keinginan dan keputusan bebas pribadi pengguna dan oleh karena itu pengguna akan menanggung semua risiko dan kerugian yang mungkin timbul darinya.',
      content_8_7:
        '8.7. Jaminan dan komitmen yang ditentukan dalam Perjanjian ini akan menjadi satu-satunya jaminan dan pernyataan yang dibuat NVX sehubungan dengan Layanan yang kami sediakan berdasarkan Perjanjian ini dan melalui Situs Web ini, dan akan menggantikan semua jaminan dan komitmen yang timbul dengan cara dan cara lain apa pun, baik secara tertulis atau lisan, tersurat maupun tersirat. Semua jaminan dan pernyataan ini hanya mewakili komitmen dan komitmen NVX sendiri dan tidak menjamin kepatuhan pihak ketiga mana pun terhadap jaminan dan komitmen yang terkandung dalam Perjanjian ini.',
      content_8_8:
        '8.8. NVX tidak melepaskan hak apa pun yang tidak disebutkan dalam Perjanjian ini dan sejauh yang diizinkan oleh hukum yang berlaku, untuk membatasi, mengecualikan, atau mengimbangi tanggung jawab kami atas kerusakan.',
      content_8_9:
        '8.9. Setelah pengguna mendaftarkan akunnya di Situs Web ini, maka dianggap bahwa pengguna menyetujui semua operasi yang dilakukan oleh NVX sesuai dengan aturan yang ditetapkan dalam Perjanjian ini, dan semua risiko yang timbul dari operasi tersebut akan ditanggung oleh pengguna.',
    },
    term_9: {
      title: '9. Pengakhiran Perjanjian ini',
      content_9_1:
        '9.1. Situs Web ini berhak untuk membatalkan akun pengguna di Situs Web ini sesuai dengan Perjanjian ini, dan Perjanjian ini akan diakhiri pada tanggal pembatalan akun pengguna.',
      content_9_2:
        '9.2. Situs Web ini berhak untuk menghentikan semua Layanan yang ditawarkan oleh Situs Web ini kepada pengguna sesuai dengan Perjanjian ini, dan Perjanjian ini akan berakhir pada tanggal penghentian semua layanan yang ditawarkan oleh Situs Web ini kepada pengguna.',
      content_9_3:
        '9.3. Setelah berakhirnya Perjanjian ini, pengguna tidak memiliki hak untuk meminta Situs Web ini untuk terus memberikan layanan apa pun kepada pengguna atau melakukan kewajiban lain apa pun, termasuk, namun tidak terbatas pada, meminta Situs Web ini untuk menyimpan atau mengungkapkan kepada pengguna informasi apa pun di akun asli pengguna sebelumnya, atau untuk meneruskan kepada pengguna atau pihak ketiga informasi apa pun di dalamnya yang belum dibaca atau dikirim.',
      content_9_4:
        '9.4. Pengakhiran Perjanjian ini tidak akan menghalangi pihak yang patuh untuk menuntut pihak yang melanggar untuk memikul kewajiban lain.',
    },
    term_10: {
      title: '10. Kekayaan Intelektual',
      content_10_1:
        '10.1. Semua pencapaian intelektual yang termasuk dalam Situs Web ini, termasuk, namun tidak terbatas pada, logo situs web, database, desain situs web, teks dan grafik, perangkat lunak, foto, video, musik, suara, dan kombinasi apa pun dari file yang disebutkan di atas, dan hak kekayaan intelektual dari kompilasi perangkat lunak, kode sumber terkait, dan perangkat lunak (termasuk aplikasi kecil dan skrip) akan dimiliki oleh Situs Web ini. Anda tidak boleh menyalin, memodifikasi, menyalin, mengirimkan, atau menggunakan bahan atau konten di atas untuk tujuan komersial.',
      content_10_2:
        '10.2. Semua hak yang terkandung dalam nama Situs Web ini (termasuk namun tidak terbatas pada goodwill bisnis dan merek dagang, logo) akan dimiliki oleh NVX.',
      content_10_3:
        '10.3. Dengan menerima Perjanjian ini, maka dianggap bahwa pengguna, atas dasar keinginan bebas pengguna sendiri, telah mengalihkan dan mengalihkan secara eksklusif dan gratis kepada Situs Web ini semua hak cipta dari segala bentuk informasi yang pengguna publikasikan di Situs Web ini, termasuk, namun tidak terbatas pada hak cipta, hak distribusi, hak sewa, hak pameran, hak pertunjukan, hak proyeksi, hak penyiaran, hak penyebaran jaringan informasi, hak pengambilan gambar, hak adaptasi, hak terjemahan, hak kompilasi, dan hak-hak lain yang dapat dialihkan yang dimiliki oleh pemilik hak cipta, dan Situs Web ini akan memiliki hak untuk menuntut atas pelanggaran hak cipta tersebut dan mendapatkan kompensasi penuh atas pelanggaran tersebut. Perjanjian ini akan berlaku untuk konten apa pun yang diterbitkan oleh pengguna di Situs Web ini dan dilindungi oleh undang-undang hak cipta, terlepas dari apakah konten tersebut dibuat sebelum atau setelah penandatanganan Perjanjian ini.',
      content_10_4:
        '10.4. Pengguna tidak boleh menggunakan atau membuang hak kekayaan intelektual Situs Web ini atau orang lain secara ilegal selama pengguna menggunakan layanan yang ditawarkan oleh Situs Web ini. Untuk informasi apa pun yang pengguna publikasikan di Situs Web ini, pengguna tidak boleh mempublikasikan atau mengizinkan situs web lain (atau media) untuk menggunakan informasi tersebut dengan cara apa pun.',
      content_10_5:
        '10.5. Masuknya pengguna ke Situs Web ini atau menggunakan salah satu layanan yang ditawarkan oleh Situs Web ini tidak akan dianggap sebagai transfer kekayaan intelektual apa pun kepada pengguna.',
    },
    term_11: {
      title: '11. Lainnya',
      content_11_1: '11.1. Keterpisahan',
      content_11_1_1:
        'Jika ada ketentuan dalam Perjanjian ini yang ditemukan tidak dapat dilaksanakan, tidak sah, atau ilegal oleh pengadilan yang berwenang, keabsahan ketentuan lain dalam Perjanjian ini tidak akan terpengaruh.',
      content_11_2: '11.2. Tidak Ada Agensi',
      content_11_2_1:
        'Tidak ada dalam Perjanjian ini yang dianggap telah menciptakan, menyiratkan, atau memperlakukan NVX sebagai agen, wali amanat, atau perwakilan pengguna lainnya, kecuali jika ditentukan lain dalam Perjanjian ini.',
      content_11_3: '11.3. Pengabaian',
      content_11_3_1:
        'NVX atau pengabaian pengguna atas hak untuk meminta pertanggungjawaban pihak lain atas pelanggaran perjanjian atau kewajiban lain sebagaimana disepakati dalam Perjanjian ini tidak akan ditafsirkan atau dianggap sebagai pengabaian hak untuk menuntut pihak lain atas pelanggaran kontrak lainnya; kegagalan untuk menggunakan hak atau upaya hukum apa pun tidak akan ditafsirkan dengan cara apa pun sebagai pengabaian hak atau upaya hukum tersebut.',
      content_11_4: '11.4. Judul',
      content_11_4_1:
        'Semua judul dalam Perjanjian ini hanya untuk kenyamanan kata-kata dan tidak dimaksudkan untuk memperluas atau membatasi konten atau ruang lingkup syarat dan ketentuan Perjanjian ini.',
    },
    term_12: {
      title: '12. Kegiatan terlarang',
      content_12_1:
        '12.1. Pengguna terdaftar tidak boleh mencoba melakukan item berikut saat menggunakan layanan ini:',
      content_12_1_1:
        '1. Tindakan yang melanggar hak kekayaan intelektual dan hak potret perusahaan atau pengguna lain dari layanan ini atau pihak ketiga lainnya, termasuk tindakan yang menyebabkan pelanggaran tersebut secara langsung atau tidak langsung.',
      content_12_1_2:
        '2. Menggunakan hak kekayaan intelektual perusahaan dan hak potret termasuk hak cipta tanpa persetujuan kami sebelumnya untuk penggunaan komersial atau mencetak ulang ke pihak ketiga.',
      content_12_1_3:
        '3. Tindakan yang melanggar, termasuk tindakan yang secara langsung atau tidak langsung menyebabkan pelanggaran, hak privasi, penghargaan, hak atau kepentingan lain dari perusahaan kami, perusahaan afiliasi kami dan orang lain yang berafiliasi dengan perusahaan kami, pengguna lain dari layanan perusahaan kami dan ketiga lainnya Para Pihak.',
      content_12_1_4:
        '4. Kisah Para Rasul Penipuan, Membuka dan Memaksa Piramida, Membeli Barang dan Jasa Ilegal, Mentransfer Penghasilan dengan Kejahatan atau Penggunaan Layanan Berdasarkan IT.',
      content_12_1_5:
        '5. Tindakan yang terkait dengan kejahatan atau bertentangan dengan kebijakan publik.',
      content_12_1_6: '6. Tindakan mengirim informasi tentang kencan.',
      content_12_1_7:
        '7. Undang-Undang Permohonan terhadap pengguna terdaftar lainnya seperti distribusi iklan, kecuali perusahaan memungkinkan.',
      content_12_1_8:
        '8. Tindakan yang melanggar undang-undang dan aturan internal kelompok industri yang menjadi milik perusahaan atau pengguna terdaftar.',
      content_12_1_9:
        '9. Tindakan yang jatuh di bawah atau berupaya menggandakan mata uang virtual.',
      content_12_1_10:
        '10. Tindakan yang mengirimkan informasi termasuk virus komputer dan program komputer berbahaya lainnya, atau yang menghancurkan atau mengganggu fungsi, sistem, server, jaringan, dan fungsi lain yang dikelola oleh perusahaan, atau yang secara tidak perlu menempatkan beban berlebihan.',
      content_12_1_11:
        '11. Tindakan menggunakan kesalahan, bug, lubang keamanan, dan cacat lain dari sistem, server, jaringan dll. Yang terkait atau dikelola oleh perusahaan.',
      content_12_1_12: '12. Tindakan informasi pemalsuan yang tersedia untuk layanan ini.',
      content_12_1_13:
        '13. Tindakan transmisi data yang melebihi sejumlah data yang ditentukan oleh perusahaan.',
      content_12_1_14: '14. Tindakan yang dapat mengganggu pengoperasian Layanan oleh Perusahaan.',
      content_12_1_15:
        '15. Tindakan dengan sengaja mencuri aset pengguna terdaftar, perusahaan atau perusahaan yang berafiliasi melalui serangan replay, dll.',
      content_12_1_16:
        '16. Penyebaran hal-hal yang berbeda dari teori dan fakta, dan tindakan yang merusak kredibilitas kita dengan menggunakan pemalsuan atau kekuatan, dll.',
      content_12_1_17: '17. Tindakan orang yang sama membuat banyak akun.',
      content_12_1_18:
        '18. Tindakan beberapa orang menggunakan satu akun atau mengizinkan pihak ketiga selain pengguna terdaftar untuk menggunakan akun.',
      content_12_1_19:
        '19. Tindakan membuka atau mencoba untuk membuka akun atas nama orang lain, termasuk orang yang hipotetis, atau tindakan memberikan informasi palsu kepada perusahaan untuk semua atau sebagian dari informasi pendaftaran yang terkait dengan akun tersebut.',
      content_12_1_20:
        '20. Tindakan menggunakan informasi, dll., Diperoleh melalui penggunaan layanan dan memberikan layanan yang sama dengan layanan untuk pihak ketiga, termasuk, tetapi tidak terbatas pada, transaksi mata uang virtual, mentransfer atau penyelesaian mata uang virtual, dan distribusi Informasi harga, dll., atau menyediakan layanan serupa sendiri, atau menggunakannya untuk tujuan komersial seperti menyediakan layanan atau barang kepada pihak ketiga, dll., Atau memiliki anak perusahaan atau pihak ketiga lainnya di bawah kendali mereka untuk bertindak sama, kecuali kecuali untuk kasus di mana persetujuan tertulis sebelumnya telah diperoleh dari perusahaan.',
      content_12_1_21:
        '21. Tindakan yang memanipulasi harga pasar pada pertukaran atau tindakan yang menghambat pembentukan harga wajar.',
      content_12_1_22:
        '22. Tindakan menerima uang ke akun pengguna terdaftar dari pihak ketiga atau membayar uang kepada pihak ketiga selain pengguna terdaftar, kecuali jika diizinkan secara eksplisit sebelumnya oleh perusahaan. Namun, kecuali diizinkan secara eksplisit oleh perusahaan sebelumnya.',
      content_12_1_23: '23. Tindakan lain yang dianggap perusahaan tidak pantas.',
      content_12_2:
        '12.2. Jika perusahaan menentukan bahwa pengguna terdaftar dalam Layanan berada di bawah salah satu item dalam paragraf sebelumnya atau kemungkinan akan berada di bawah Layanan ini, Perusahaan dapat mengambil langkah-langkah, tanpa memberi tahu Pengguna Terdaftar di muka, atas kebijakan Perusahaan seperti penghapusan semua atau sebagian dari informasi yang dikirim oleh pengguna terdaftar, pembatalan pendaftaran pengguna terdaftar atau penangguhan penggunaan, atau penyitaan mata uang virtual dll. dihitung berdasarkan nilai tukar yang ditetapkan oleh perusahaan akan disita. Pada saat itu, Perusahaan tidak wajib mengembalikan dokumen yang diterima dari pengguna terdaftar dan tidak akan bertanggung jawab atas kerusakan yang disebabkan oleh pengguna terdaftar dengan langkah-langkah yang diambil oleh Perusahaan berdasarkan bagian ini.',
      content_12_3:
        '12.3. Jika pengguna melakukan salah satu item dalam paragraf 1 di atas, perusahaan dapat membebankan penalti kepada pengguna terdaftar yang ditetapkan oleh perusahaan.',
    },
    term_13: {
      title: '13. Berlakunya dan Penafsiran Perjanjian',
      content_13_1:
        '13.1. Perjanjian ini akan mulai berlaku ketika pengguna mengklik halaman pendaftaran Situs Web ini, menyelesaikan prosedur pendaftaran, memperoleh nomor akun dan kata sandi pengguna Situs Web ini, dan akan mengikat pengguna dan Situs Web ini.',
      content_13_2:
        '13.2. Kuasa tertinggi untuk menafsirkan Perjanjian ini akan berada di tangan Situs Web ini.',
      content_13_2_0_1: 'Pengingat penting:',
      content_13_2_0_2: 'NVX dengan ini mengingatkan pengguna bahwa:',
      content_13_2_1:
        '1. Aset digital itu sendiri tidak ditawarkan oleh lembaga keuangan, korporasi atau Situs Web ini. Pasar aset digital masih baru dan belum dikonfirmasi, dan tidak akan selalu berkembang',
      content_13_2_2:
        '2. Transaksi aset digital sangat berisiko, karena diperdagangkan sepanjang 24 jam sehari tanpa batasan kenaikan atau penurunan harga',
      content_13_2_3:
        '3. Transaksi aset digital dapat ditangguhkan atau dilarang kapan saja karena berlakunya atau perubahan undang-undang, peraturan, dan dokumen peraturan nasional.',
      content_13_2_4:
        '4. NVX tidak melayani pelanggan di negara/wilayah berikut: AS, Kuba, Iran, Korea Utara, Crimea, Sudan, Suriah, Bangladesh, Bolivia, Ekuador, dan Kirgistan.',
    },
    term_14: {
      title: '14. Sertifikat Elektronik dan Tanda Tangan Elektronik DIGISIGN',
      content_14_1:
        'Anda akan menggunakan Sertifikat Elektronik dan Tanda Tangan Elektronik untuk menandatangani dokumen elektronik dengan PT Aset Kripto Internasional. PT Aset Kripto Internasional bekerja sama dengan PT Solusi Net Internusa (Digisign.id) selaku Penyelenggara Tanda Tangan Elektronik dan Penyelenggara Sertifikasi Elektronik Indonesia yang telah berinduk pada Kementerian Komunikasi dan Informatika Republik Indonesia dengan merk Digisign.id.',
      content_14_2:
        'Dengan ini, Anda menyatakan setuju untuk mendaftar sebagai pengguna platform Digisign.id dan diterbitkan sertifikat elektronik oleh PT Solusi Net Internusa (Digisign.id) atas nama Anda dalam rangka penggunaan layanan Tanda Tangan Elektronik di platform Digisign.id untuk menandatangani dokumen elektronik.',
      content_14_3:
        'Apabila data pribadi Anda terverifikasi kesesuaiannya, maka PT Solusi Net Internusa (Digisign.id) sebagai Penyelenggara Sertifikasi Elektronik berinduk oleh Kementerian Komunikasi Informatika Republik Indonesia, akan menerbitkan sertifikat elektronik sebagai bukti bahwa data pribadi Anda telah diverifikasi dan sesuai dengan data yang tercatat pada sistem instansi yang berhak mengeluarkan identitas tersebut.',
      content_14_4:
        'Oleh karenanya, Anda menjamin keakuratan data pribadi yang Anda sediakan dan setuju atas pemrosesan data pribadi Anda untuk tujuan penerbitan sertifikat elektronik serta layanan lain dari PT Solusi Net Internusa (Digisign.id) yang melekat pada sertifikat elektronik tersebut.',
      content_14_5:
        'Anda setuju untuk membebaskan PT Solusi Net Internusa (Digisign.id) dari tanggung jawab ganti kerugian, klaim atau gugatan atas hasil sertifikat elektronik yang diterbitkan apabila data yang diberikan yang tidak valid dan tidak benar.',
      content_14_6:
        'Dengan ini, Anda menyatakan secara jelas dan tanpa adanya paksaan, akan memberikan kuasa kepada PT Aset Kripto Internasional untuk meneruskan data KTP, swafoto, nomor ponsel, dan alamat surel Anda sebagai data pendaftaran kepada PT Solusi Net Internusa (Digisign.id) guna memenuhi ketentuan Peraturan Perundang-undangan, yaitu Peraturan Pemerintah Nomor 71 Tahun 2019 tentang Penyelenggara Sistem dan Transaksi Elektronik, dan Peraturan Kementerian Informasi dan Komunikasi Nomor 11 Tahun 2018.',
      content_14_7:
        'Dengan ini Anda telah membaca, memahami, dan setuju untuk terikat pada syarat dan ketentuan layanan Penyelenggara Sertifikat Elektronik yang terdapat pada Perjanjian Kepemilikan Sertifikat Elektronik (Subscriber Agreement), Kebijakan Privasi PSrE (CA Privacy Policy), serta Pernyataan Penyelenggaraan Sertifikat Elektronik (Certification Practice Statement) Digisign.id yang dapat diakses melalui <a href="https://repository.digisign.id/">https://repository.digisign.id/</a>.',
    },
  },
  'terms.term_4.content_4_3_1_1.introduce':
    'Saat pengguna melihat informasi transaksi mata uang-ke-mata uang di Situs Web ini, pengguna harus membaca semua konten dalam informasi transaksi, termasuk namun tidak terbatas pada harga, konsinyasi, biaya penanganan, arah beli atau jual, dan pengguna harus menerima semua konten yang terdapat dalam informasi transaksi sebelum pengguna dapat mengklik tombol untuk melanjutkan transaksi.',
  'terms.term_4.content_4_3_1_2.introduce':
    'Setelah melihat dan memverifikasi informasi transaksi, pengguna dapat mengirimkan komisi transaksinya. Setelah pengguna mengirimkan komisi transaksi, maka dianggap bahwa pengguna mengizinkan Situs Web ini untuk menjadi perantara pengguna untuk transaksi yang sesuai, dan Situs Web ini akan secara otomatis menyelesaikan operasi perjodohan ketika ada proposal transaksi yang memenuhi harga pengguna. kutipan, tanpa pemberitahuan sebelumnya kepada pengguna.',
  'terms.term_4.content_4_3_1_3.introduce':
    'Pengguna dapat memeriksa catatan transaksi yang sesuai dalam laporan transaksi melalui Pusat Manajemen, dan mengkonfirmasi catatan transaksi terperincinya sendiri.',
  btc_campaign_tit: 'BTC TRADING BONUS <br /> Kampanye & Diri <br /> Perdagangan GO',
  btc_campaign_time: '24 Maret ~ 26 Maret',
  btc_campaign_btctxt: 'Total 10 BTC',
  invite_recommend_id: 'ID rekomendasi',
  invite_recommend_scale: 'Rasio komisi',
  invite_recommend_link: 'Tautan rujukan',
  invite_recommend_share: 'Share',
  invite_recommend_person: 'Teman yang direkomendasikan',
  invite_recommend_money: 'Pendapatan komisi perkiraan',
  invite_recommend_record: 'Pendapatan komisi perkiraan',
  invite_recommend_email: 'Mailbox',
  invite_recommend_time: 'Waktu',
  invite_recommend_none: 'Anda tidak memiliki catatan rabat',
  invite_recommend_history: 'Rabat terbaru',
  invite_rule: 'Detail Kegiatan',
  entry: {
    'msg.create_team.success': 'Tim entri yang dibuat berhasil!',
    'msg.cancel.success': 'Permintaan Anda telah dibatalkan!',
    number_of_users: 'Jumlah Pengguna yang Masuk',
  },
  register: {
    entry: 'Daftar Masuk',
    error_terms: 'Bidang persyaratan setuju diperlukan.',
    has_account: 'Sudah punya akun?',
    sub_title: 'Buat akun Anda',
    success:
      '<span>Selamat! </span><strong>{email}</strong><p>Silakan periksa kotak surat Anda untuk memverifikasi akun Anda </p>',
    success_2:
      "<div><p style='line-height: 28px;color: #333;font-size: 14px;margin: 0;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;'>Kami mengirim email konfirmasi ke <span title={email}>{email}</span>.</div><div>Silakan ikuti instruksi untuk menyelesaikan pendaftaran Anda.</p></div>",
    success_3:
      'Anda telah berhasil terdaftar. Untuk mengaktifkan akun Anda, periksa email Anda dan konfirmasikan pendaftaran Anda.',
    terms_1: 'Saya setuju dengan',
    terms_2: 'ketentuan',
    title: 'Daftar',
    title_2: 'Mengirim ulang',
    title_3: 'Jika Anda belum menerima email, lakukan hal berikut:',
    paragraph_1: 'Pastikan alamat email yang disediakan benar.',
    paragraph_2: 'Periksa spam atau folder lain.',
    paragraph_3: 'Tetapkan daftar putih alamat email.',
    paragraph_4: 'Periksa klien email bekerja secara normal.',
    sendConfirmEmail: 'Kirim email konfirmasi',
    congratulation: 'Selamat!',
    policy:
      "Dengan mengklik Daftar, Anda menyetujui <a href='/terms'>Persyaratan Kami</a> dan <a href='/privacy-policy'>Kebijakan Privasi</a>. Anda dapat menerima pemberitahuan SMS dari kami dan dapat keluar kapan saja.",
    send_otp_success: 'Sukses mengirim ulang OTP',
    send_otp_failed: 'Gagal mengirim ulang OTP',
    invalid_phone_no_1: '{phone} bukan nomor telepon',
    invalid_phone_no_2: 'Panjang nomor telepon harus antara 10 dan 30 digit',
  },
  participate: {
    entry: 'Apakah Anda ingin berpartisipasi dalam Entri?',
  },
  i18n: {
    lang: 'id',
  },
  kyc: {
    id_number_unique: 'Nomor Identitas Kependudukan Anda telah digunakan',
    list: 'Daftar KYC',
    input_search: 'Pencarian',
    created_at: 'Waktu',
    email: 'Email',
    name: 'Nama lengkap',
    country: 'Negara',
    id_number: 'NIK/ID Nasional',
    id_number_wna: 'KITAS/KITAP ID (Niora)',
    status: 'Status',
    gender: 'Jenis Kelamin',
    id_front: 'Sisi Depan Kartu Identitas',
    id_front_wna: 'sisi Depan Kartu KITAS/KITAP',
    id_back: 'Sisi Belakang Kartu Identitas',
    id_back: 'Sisi Belakang Kartu KITAS/KITAP',
    id_selfie: 'Selfie Dengan ID Foto Dan Catatan',
    btn_detail: 'Detail',
    btn_verify: 'Verifikasi',
    btn_reject: 'Menolak',
    notice_verify_confirm: 'Apakah Anda ingin mengkonfirmasi KYC ini?',
    notice_reject_confirm: 'Apakah Anda ingin menolak KYC ini?',
    detail_kyc: 'Detail KYC',
    msg_verified: 'KYC disetujui oleh administrator. Anda tidak dapat mengubah informasi.',
    notice: 'Pemberitahuan',
    notice_under17: 'Pengguna harus berusia minimal 17 tahun untuk menyelesaikan proses KYC',
    description:
      "Anda harus menggunakan identitas asli Anda menggunakan proses verifikasi ini. Semua informasi pribadi akan dilindungi dengan aman. <a href='#'> ID nasional harus digunakan. </a>",
    notice_indonesian:
      '<strong>Pemberitahuan:</strong><ul><li>Dokumen Anda akan diserahkan ke Digisign untuk verifikasi otomatis</li><li>Nomor telepon Anda harus nomor Indonesia. Jika Anda menggunakan nomor telepon negara lain, silakan hubungi kami untuk dukungan lebih lanjut</li></ul>',
    note_rejected:
      'Dokumen Anda telah ditolak oleh Digisign karena beberapa informasi yang salah. Harap perbarui dan kirim lagi',
    note_pending:
      'Kami telah mengirimi Anda email untuk langkah aktivasi akun Digisign. Harap periksa email Anda dan ikuti instruksi',
  },
  system: {
    title: 'Perbaikan sedang berlangsung',
    des: 'Server dalam Maintenance. Untuk sementara tidak dapat melayani permintaan Anda. Kami akan segera kembali.',
  },
  window: {
    suffix_title: 'Exchange',
  },
  about: {
    about_us: 'Tentang Kami',
    advantage: 'Keuntungan Kami',
    coin: 'Dukungan Multi-Koin',
    coin_reason: 'BTC, ETH, LTC, ...',
    device: 'Semua Perangkat Tercakup',
    device_reason: 'Dukungan dan FAQ tersedia dalam berbagai bahasa',
    envi: 'Dukungan Banyak Bahasa',
    envi_reason: 'Web, Android, iOS, Mobile Web',
    liqui: 'Likuiditas Tinggi',
    liqui_reason: 'Sumber daya dan mitra yang melimpah',
    perform: 'Kinerja tinggi',
    perform_reason: 'Mampu memproses 1.400.000 pesanan per detik',
    product: 'Produk Terbukti',
    product_reason:
      'Platform {APP_NAME} telah digunakan lebih dari 30 exchange. Ini mendukung semua perangkat dan berbagai bahasa, menawarkan pengalaman pengguna yang mulus.',
    resources: 'Sumberdaya Industri',
    resources_reason:
      'Kami memiliki hubungan yang kuat dengan para pemimpin industri, cukup lihat daftar investor dan penasihat yang bersedia berdiri di belakang platform kami.',
    safe: 'Stabilitas Keamanan',
    safe_reason: 'Arsitektur sistem multi-tier & multi-cluster',
    team_strong: 'Tim kami',
    team_strong_reason:
      'Tim kami memiliki pengalaman dalam keuangan wall-street dan crypto. Tim kami juga memiliki rekam jejak startup yang sukses di bawah kami.',
    tech: 'Teknologi Unggul',
    tech_reason:
      'Mesin pencocokan bersertifikat kami mampu memproses 1.400.000 pesanan per detik, menjadikan {APP_NAME} salah satu pertukaran tercepat di pasar saat ini.',
    tit: 'Diluncurkan pada tahun 2018 {APP_NAME} adalah Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Selain itu, Anda juga dapat melakukan latihan ullamco tenaga kerja ex ea komodo konsekuen. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
  },
  account: {
    address_manager: 'Manajemen Alamat',
    create_api: 'Buat API',
    address_manager_content:
      'Manajemen Alamat memungkinkan Anda untuk menyimpan dan menulis memo untuk setiap alamat penarikan Anda. Fungsi Whitelist opsional membantu melindungi dana Anda dengan hanya mengizinkan penarikan ke alamat yang diputihkan.',
    wallet_fee: 'Wallet Biaya',
    api_description:
      'Membuat kunci pribadi API menyediakan akses ke pasar dan layanan perdagangan real-time di NVX Exchange melalui situs atau aplikasi pihak ketiga.',
    wallet_fee_description:
      'Anda dapat memilih wallet 4IRE mana yang membayar biaya dalam setiap transaksi.',
    api_view_documentation: 'Lihat dokumentasi API',
    api_create: 'Buat API',
    bank: '{0}',
    btn_general: 'Umum',
    btn_verifying: 'Memverifikasi',
    btn_pending: 'Tertunda',
    btn_rejected: 'Ditolak',
    btn_unverified: 'Tidak diverifikasi',
    btn_uver_bank: 'Tidak diverifikasi',
    btn_verified: 'Diverifikasi',
    btn_verify_bank: 'Memverifikasi',
    button_show: 'Lihat lebih banyak',
    change: 'Perubahan',
    daily_higher_limit: 'Batas Lebih Tinggi',
    daily_limit_withdraw: 'Batas Penarikan 24 jam',
    date: 'Tanggal',
    email_desc:
      'Jika Anda menonaktifkan, Anda akan kehilangan pemberitahuan untuk Login, Penarikan, Setoran.',
    device: 'Perangkat',
    device_management: 'Manajemen Perangkat',
    devices_allowed: 'Perangkat ini saat ini diizinkan untuk mengakses akun Anda.',
    disable: 'Nonaktifkan',
    email_disable_confirm: 'Apakah Anda ingin menonaktifkan pemberitahuan terima?',
    discount: 'Menggunakan 4IRE untuk membayar biaya',
    discount_percent: '({percent} diskon)',
    enable: 'Enable',
    error_bank: '* Digunakan untuk penarikan dan modifikasi keamanan',
    google_auth: 'Otentikasi Google',
    lost_google_authenticator: 'Kehilangan Google Authenticator Anda?',
    google_auth_content: 'Digunakan untuk penarikan dan modifikasi keamanan',
    verify_email: 'Verifikasi email',
    google_authenticator: 'Otentikasi Google',
    kyc: 'KYC',
    add_info: 'Informasi tambahan',
    remove: 'Hapus',
    identity: {
      choose_file: 'Pilih File',
      country: 'Negara / wilayah',
      errors: {
        character_limit: 'Bidang ini hanya berisi {min} hingga {max} termasuk angka atau karakter.',
        exist: 'kartu identitas sudah ada',
        format_number: 'Bidang ini tidak mengandung karakter khusus.',
        maxsize: 'Ukuran maksimal file 4MB. Pilih yang lain',
        required: 'Bidang ini tidak boleh kosong',
        under_17: 'Usia minimum 17 tahun',
        fullName: {
          required: 'Bidang ini tidak boleh kosong',
        },
        passportID: {
          required: 'Bidang ini tidak boleh kosong',
        },
        id_front: {
          required: 'Bidang ini tidak boleh kosong',
        },
        id_back: {
          required: 'Bidang ini tidak boleh kosong',
        },
        id_selfie: {
          required: 'Bidang ini tidak boleh kosong',
        },
      },
      example: 'Contoh',
      female: 'Perempuan',
      fullname: 'Nama lengkap',
      header_identity: 'Pribadi',
      header_verification: 'Dokumen Verifikasi',
      identityID: 'Kartu Identitas yang Valid',
      identity_back: 'Sisi Belakang Kartu Identitas',
      identity_back_wna: 'Sisi Belakang Kartu KITAS/KITAP',
      identity_cover: 'Sisi Depan Kartu Identitas',
      identity_cover_wna: 'Sisi Depan Kartu KITAS/KITAP',
      identity_front: 'Sisi Depan Kartu Identitas',
      identity_image_back:
        'Pastikan foto anda lengkap dan terlihat jelas, dengan format .PNG, .JPG, .JPEG, .HEIC atau .HEIF. Kartu identitas nasional harus dalam periode yang valid.',
      identity_valid_card: 'Kartu Identitas yang Valid',
      image_identity:
        'Pastikan foto anda lengkap dan terlihat jelas, dengan format .PNG, .JPG atau .JPEG. Kartu identitas nasional harus dalam periode yang valid',
      image_passport:
        'Gambar harus diserahkan .PNG, .JPG atau .Format JPEG. Harap pastikan bahwa subjek foto lengkap dan terlihat jelas. Jika Anda tidak memiliki paspor, silakan unggah foto bagian depan SIM atau dokumen ID Nasional Anda.',
      image_passport_data:
        'Tulisan tangan harus mencakup nama, tanggal aplikasi, nama domain Web, dan tanggung jawab pribadi. Semua perilaku di situs web ini dioperasikan oleh saya sendiri, dan saya menyadari risiko yang relevan dan bersedia mengambil semua konsekuensi hukum.',
      male: 'Laki-laki',
      passport: 'NIK/ID Nasional',
      passport_wna: 'ID KITAS/KITAP',
      dob: 'Tanggal lahir',
      dob_placeholder: 'Pilih Tanggal',
      selfie: 'Selfie Dengan ID Foto Dan Catatan',
      sex: 'Jenis Kelamin',
      submit: 'Kirim',
      success: 'Dibuat dengan sukses!',
      update_success: 'Diperbarui dengan sukses!',
      sure_back:
        'Pastikan foto itu lengkap dan terlihat jelas, di .PNG, .JPG atau .Format JPEG. Kartu identitas nasional harus dalam periode yang valid.',
      sure_front:
        'Jika Anda tidak memiliki ID Nasional, silakan unggah foto bagian depan SIM atau dokumen ID Nasional Anda.',
      text_condition1:
        'Gambar yang diunggah harus dengan jelas menunjukkan informasi wajah dan teks.',
      text_condition2: 'Pastikan wajah terlihat dengan jelas dalam video yang diunggah..',
      text_date: 'Catatan dengan tanggal hari ini',
      text_face: 'Wajah terlihat jelas',
      text_image: 'ID foto terlihat jelas',
      text_selfie:
        'Harap berikan foto Anda memegang sisi depan Kartu Identitas Anda. Dalam gambar yang sama, buat referensi ke {APP_NAME} dan tanggal hari ini ditampilkan. Pastikan wajah Anda terlihat jelas dan semua detail ID Nasional dapat dibaca dengan jelas.',
      text_write: 'Catatan dengan kata "{APP_NAME}"',
      verification_other:
        'Opsi verifikasi ini berlaku untuk pengguna dengan ID dari semua negara dan wilayah di luar daratan Jepang. Anda harus menggunakan identitas asli Anda selama proses verifikasi ini. Informasi pribadi Anda akan dilindungi dengan aman. Bentuk ID yang dikeluarkan pemerintah berikut ini dapat digunakan:',
      verification_other1: '<span>1.</span>ID Nasional',
      verification_other2: '<span>2.</span>Lisensi pengemudi',
      verification_other3: '<span>3.</span>Kartu ID yang dikeluarkan pemerintah',
      user: 'User',
      remove: 'Hapus',
      address: 'Kontak Alamat',
      postal_code: 'Kode Pos',
      take_a_video_and_note: 'Ambil video dan perhatikan',
      address_placeholder: 'Nama jalan, nomor rumah, RT / RW',
      take_selfie_photo: 'Ambil foto selfie',
      retake_selfie_photo: 'Ambil ulang foto selfie',
      liveness_checking: 'Pemeriksaan Liveness',
      retake_video: 'Ambil ulang video',
      retake: 'Ambil ulang',
      save: 'Simpan',
      video_note:
        'Silakan kirim video dengan instruksi berikut; <ul> <li> Tengokkan kepala Anda ke arah kiri </li> <li> Tengokkan kepala Anda ke kanan </li> <li> Tengok ke atas </li> <li> Tengok ke bawah </li> </ul>',
    },
    invalid_device:
      'Anda telah masuk dari perangkat yang tidak kami kenal atau yang belum Anda gunakan untuk jangka waktu yang lama. Demi keamanan Anda, email konfirmasi telah dikirim ke alamat email Anda.',
    ip: 'IP',
    ip_address: 'Alamat IP',
    actions: 'Tindakan',
    last_login: 'Login terakhir',
    last_login_time: 'Waktu login terakhir ',
    line: {
      title: 'Baris',
      desc: 'Aktifkan akun Anda untuk menerima pemberitahuan untuk Login, Penarikan, Setoran.',
    },
    location: 'Lokasi',
    login: 'Kata Sandi masuk',
    lv: 'Lv',
    api: {
      api: 'API',
      title_page: 'Buat Kunci API ',
      des_title_page:
        'Anda harus menerapkan filter pembatasan akses IP untuk mengaktifkan penarikan.',
      key: 'API Key',
      input_placeholder: 'Masukkan Label API Key',
      secret_key: 'Secret Key',
      scan_address: 'Alamat pemindaian',
      option: 'Pilihan',
      permissions: {
        read: 'Baca info',
        withdrawal: 'Aktifkan penarikan',
        trading: 'Aktifkan perdagangan',
      },
      copy: 'Disalin dengan sukses!',
      edit: 'Edit',
      cancel: 'Membatalkan',
      save: 'Menyimpan',
      delete: 'Hapus',
      delete_api: 'Hapus API',
      delete_api_message_withname:
        'Apakah Anda pasti akan menghapus API key {name} secara permanen?',
      delete_api_message_noname: 'Apakah Anda pasti akan menghapus API ini secara permanen?',
      create_btn: 'Buat Key baru',
      title: 'Daftar API Key',
      delete_all: 'Hapus semua API',
      save_success: 'Berhasil menyimpan',
      save_fail: 'Gagal menyimpan API Key',
      delete_fail: 'Gagal menghapus API Key',
      delete_success: 'Berhasil dihapus',
      create_success: 'API baru berhasil dibuat',
      create_fail: 'Gagal membuat API Key',
      create_new_at_first: 'Untuk memulai dengan API, buat kunci baru.',
      ip_access_res: 'Pembatasan Akses IP:',
      unrestricted: 'Tidak dibatasi (kurang aman)',
      not_recommended:
        'Alamat IP apa pun dapat menggunakan API Key ini untuk mengakses akun Anda. Tindakan ini tidak disarankan.',
      restrict_access: 'Batasi akses hanya untuk IP tepercaya (disarankan)',
      current_ip: 'Jika memasukkan beberapa IP, terpisah menggunakan koma.',
      confirm: 'Mengonfirmasi',
      duplicate_value: 'Label baru harus berbeda dari label Anda sebelumnya.',
    },
    modal: {
      agreement_otp: {
        view_secret_key: 'Lihat kunci rahasia',
        policy_content_text1: 1,
        policy_content_text2: 'Subjek Email: Permintaan berhenti Google Authenticator',
        policy_content_text3: '2. Badan email',
        policy_content_text4: '- Nama asli.',
        policy_content_text5: '- Nomor ponsel Anda.',
        policy_content_text6: '- E-mail saat Anda bergabung {APP_NAME}.',
        policy_content_text7: '3. Lampiran',
        policy_content_text8: '- Sisi depan kartu identitas.',
        policy_content_text9: '- Sisi belakang kartu identitas.',
        policy_content_text10: '- Selfie dengan kartu identitas dan catatan.',
        policy_content_text11: 'Pastikan foto itu lengkap dan terlihat jelas.',
        policy_content_text12: 'Harap berikan foto Anda memegang sisi depan Kartu Identitas Anda.',
        policy_content_text13:
          'Dalam gambar yang sama, buat referensi ke {APP_NAME} Exchange dan tanggal hari ini ditampilkan.',
        policy_content_text14:
          'Pastikan wajah Anda terlihat jelas dan semua detail ID Nasional dapat dibaca dengan jelas.',
        policy_content:
          "Jika Anda juga kehilangan kode pemulihan dan tidak dapat berhenti menggunakan Google Authenticator, silakan kirim email permintaan Anda ke kelas rentang <span class='primary-link'>{contact_email}</span>.",
        title: 'Kehilangan Kode Pemulihan Anda juga? ',
      },
      cancel_2fa: {
        guide:
          '<p class="font-18">Untuk berhenti menggunakan otentikasi 2 faktor (OTP), Anda harus memasukkan nomor verifikasi 6 digit dari aplikasi Google Authenticator. </p><p class="font-18"> Jika Anda kehilangan ponsel atau menghapus instalasi aplikasi, Anda dapat memasukkan kode pemulihan 16 karakter dalam input di bawah ini untuk menonaktifkannya. </p>',
        input_blank: 'Anda harus memasukkan kode OTP Otentikasi Google',
        otp: 'OTP',
        otp_code: 'Nomor otorisasi',
        otp_policy: 'Kehilangan Kode Pemulihan Anda juga? ',
        otp_restore: 'Kode pemulihan',
        stop_use_otp: 'Berhenti Menggunakan OTP',
        success: 'Berhenti menggunakan kesuksesan OTP!',
        title: 'Nonaktifkan Otentikasi Google',
      },
      change_phone: {
        title: 'Ubah Nomor Telepon',
        success: 'Nomor telepon anda berhasil diubah.',
      },
      confirm: '<p class="font-18">Konfirmasikan </p>',
      confirm_trusted_device: 'Apakah Anda ingin menghapus perangkat tepercaya yang dipilih?',
      date_time_pick: {
        text1: 'Rentang Tanggal:',
        text2: '24 jam terakhir',
        text3: 'Kemarin',
        text4: '2 minggu terakhir',
        text5: 'Bulan hingga saat ini',
        text6: 'Bulan lalu',
        text7: '3 bulan terakhir',
        text8: 'Kustom (rentang maks 3 bulan)',
        text9: 'Ekspor',
        title: 'Riwayat Komisi Ekspor',
      },
      lost_otp: {
        guide:
          'Untuk berhenti menggunakan Google Authenticator, Anda harus memasukkan kode pemulihan Anda.',
        otp: 'OTP',
        otp_code: 'Nomor otorisasi',
        otp_policy: 'Kehilangan Kode Pemulihan Anda juga? ',
        otp_restore: 'Kode pemulihan',
        stop_use_otp: 'Berhenti Menggunakan OTP',
        title: 'Berhenti Menggunakan OTP',
      },
    },
    ni_bank: 'Rekening Bank NI',
    no_commission_history: 'Anda tidak memiliki data',
    no_referral_history: 'Anda tidak memiliki data',
    no_latest_commission: 'Anda tidak memiliki data',
    otp: {
      should_enable_first: 'Harap aktifkan otentikasi google',
      authentication_failed: 'Otentikasi gagal.',
      back: 'Langkah Kembali',
      backup_text1:
        'Mengatur ulang Otentikasi Google Anda memerlukan pembukaan tiket dukungan dan membutuhkan setidaknya 7 hari untuk diproses.',
      backup_text2:
        'Harap simpan Kunci ini. Kunci ini akan memungkinkan Anda untuk memulihkan Otentikasi Google Anda jika terjadi kehilangan telepon.',
      backup_title: 'Langkah 3. Simpan Kode Pemulihan Cadangan',
      enable_des: 'Aktifkan Otentikasi Google',
      enable_step1: '1. Unduh Aplikasi',
      enable_step2: '2. Pindai Kode QR',
      enable_step3: '3. Kode Pemulihan Cadangan',
      enable_step4: '4. Aktifkan Otentikasi Google',
      enable_text1: 'Saya telah menginstal aplikasi',
      enable_text2: 'Saya telah memindai kode QR',
      enable_text3: 'Saya sudah menuliskan Kunci 16-Digit',
      enable_title: 'Langkah 4. Aktifkan Otentikasi Google',
      install_text: 'Unduh dan instal aplikasi Otentikasi Google.',
      'sub-install_text': 'Dapatkan aplikasinya',
      install_title: 'Langkah 1. Unduh Aplikasi',
      next: 'Langkah selanjutnya',
      qr_text:
        'Jika Anda tidak dapat memindai kode QR, silakan masukkan kode ini secara manual ke dalam aplikasi.',
      qt_title: 'Langkah 2. Pindai Kode QR',
      success: 'Aktifkan OTP dengan sukses.',
      txt_pass: 'Kata Sandi Masuk',
      should_enable_first: 'Harap aktifkan otentikasi google',
    },
    phone_number_in_use: 'Nomor telepon ini sudah terhubung dengan akun {APP_NAME}.',
    recent_activity: 'Aktivitas Terbaru',
    referral_code: 'Kode referral Anda:',
    fee_tier_program: 'Program Tingkat Biaya',
    fee_tier_program_desc:
      'Dapatkan Biaya Perdagangan yang lebih rendah dengan melakukan lebih banyak perdagangan untuk meningkatkan Volume Harian Rata-rata Anda',
    fee_tier_program_disable1: 'Program Tingkat Biaya dimulai setelah: {time}',
    fee_tier_program_disable2: 'Program Tingkat Biaya tidak tersedia saat ini!',
    referral_notification:
      'Dapatkan hingga {percent}% komisi saat mengundang teman ke Bursa {APP_NAME}. Bagikan tautan ini:',
    referral_program: 'Program Referral',
    submit: 'Kirim Dokumen Verifikasi',
    view_doc: 'Lihat Dokumen Verifikasi',
    change_doc: 'Ubah Dokumen Verifikasi',
    change_verify_kyc: 'Ubah Dokumen Verifikasi',
    telegram: {
      title: 'Telegram',
      desc: 'Aktifkan akun Anda untuk menerima notifikasi untuk Masuk, Penarikan, Setoran.',
    },
    title_device: 'Perangkat ini saat ini diperbolehkan mengakses akun Anda',
    tooltip1:
      'Ketika fitur ini diaktifkan, akun Anda hanya dapat melakukan penarikan ke alamat penarikan yang ada dalam daftar putih.',
    tooltip2:
      'Ketika fitur ini dinonaktifkan, akun Anda dapat melakukan penarikan ke alamat penarikan apa pun.',
    turn_on_mode_whitelist:
      'Setelah fitur ini dinonaktifkan, Anda dapat melakukan penarikan ke alamat penarikan apa pun. Apakah Anda yakin ingin menonaktifkannya?',
    turn_off_mode_whitelist:
      'Setelah mematikan fungsi ini, Anda akan dapat menarik dana ke alamat penarikan apa pun. Apakah Anda yakin ingin mematikannya?',
    white_list: 'Daftar Putih',
    white_list_off: 'Matikan Daftar Putih',
    white_list_on: 'Aktifkan Daftar Putih',
    withdrawal_address: 'Pengelolaan Alamat Penarikan',
    inactive: 'Akun Anda dinonaktifkan.',
    user: 'Pengguna',
  },
  emails: {
    confirmation_reset_password: {
      subject: '【NVX Exchange】 Atur Ulang Kata Sandi',
    },
    account: 'Akun',
    account_info: 'bank: {bank}, nomor akun: {accountNumber}, pemegang akun: {name}',
    amount: 'jumlah:',
    cannot_click_link:
      'Jika Anda tidak dapat mengklik tautan, harap salin dan tempelkan alamatnya ke bilah alamat. Terima kasih',
    confirm_email:
      'Klik tautan di bawah ini untuk mengaktifkan akun Anda. Dengan memverifikasi surel Anda, kami berusaha memberikan layanan pertukaran kripto yang lebih nyaman dan aman kepada pelanggan.',
    confirm_kyc: 'KYC Anda',
    date: 'Waktu:',
    deposit_alerts: {
      text1: 'Kami menerima pesanan pemuatan',
      text2: 'dari Anda.',
      text3: 'Jumlah ini telah ditambahkan ke saldo Anda di {APP_NAME}.',
      title: 'Permintaan pengisian telah dibuat di 【{APP_NAME}】.',
    },
    deposit_approved_text1: '{email}, permintaan deposit Anda telah disetujui.',
    deposit_approved_title: '【{APP_NAME}】 Deposit Selesai',
    deposit_rejected_text1: '{email}, permintaan setoran Anda gagal.',
    deposit_rejected_text2:
      'Silakan periksa aturan setoran, informasi akun Anda, kode setoran dan terapkan lagi.',
    deposit_rejected_title: '【{APP_NAME}】 Setoran Gagal',
    deposit_usd_alerts: {
      account_name: 'Nama akun',
      account_no: 'Akun no',
      amount: 'Jumlah',
      bank_branch: 'Cabang bank',
      bank_name: 'Nama bank',
      code: 'Kode',
      text1:
        'Sistem baru saja menerima permintaan untuk memeriksa transaksi pengisian ulang pengguna',
      text2: 'Informasi transaksi',
      title: 'Permintaan pengisian ulang baru',
    },
    hello: 'Halo,',
    kyc_rejected: 'ditolak',
    kyc_tilte: '【 {APP_NAME} 】 Konfirmasikan KYC aktif',
    kyc_verified: 'diverifikasi',
    new_deposit_amount: 'Jumlah:',
    new_deposit_text1: 'Kami telah menerima permintaan setoran Anda.',
    new_deposit_title: 'Permintaan Setoran Baru',
    new_device: {
      text1:
        'Anda baru-baru ini mencoba masuk ke akun {APP_NAME} Anda dari perangkat baru. Sebagai langkah keamanan, kami memerlukan konfirmasi tambahan sebelum mengizinkan akses ke akun {APP_NAME} Anda',
      text2:
        'Jika ini adalah aktivitas yang sah, Anda dapat mengotorisasi perangkat baru Anda di bawah ini',
    },
    new_login: {
      text1: 'Sistem telah mendeteksi bahwa akun Anda masuk dari alamat IP yang tidak digunakan',
      text2:
        'Jika aktivitas ini bukan operasi Anda sendiri, harap nonaktifkan perangkat itu dan segera hubungi kami',
    },
    new_withdrawal_text1: 'Kami telah menerima permintaan penarikan Anda.',
    new_withdrawal_title: 'Permintaan Penarikan Baru',
    no_reply: 'Ini adalah pesan otomatis. Tolong jangan balas.',
    on: 'di',
    say_hello: 'Halo {email}?',
    team: 'Tim {APP_NAME}',
    team_address: '( CO ) {APP_NAME} - jalan xxx',
    team_inc: '© {APP_NAME} Inc. Seluruh hak cipta.',
    team_inform: 'akan memberi tahu Anda',
    team_service_center:
      'Pusat layanan / email. <a href="mailto:{email}" target="_blank" > {email} </a> / tel. {phone}',
    team_slogan: 'Perdagangan digital asset terbaik',
    thankyou: 'Terima kasih,',
    warning: 'Jika aktivitas ini bukan operasi Anda sendiri, silakan hubungi kami segera.',
    warning_title: '{APP_NAME} Peringatan Keamanan',
    welcome: 'Selamat datang di {APP_NAME}',
    withdraw_alerts: {
      text1: 'Minta penarikan',
      text2: 'dari akun {APP_NAME} telah berhasil diimplementasikan.',
      text3: 'Anda dapat melacak status konfirmasinya di sini',
      title: '【 {APP_NAME} 】 Permintaan penarikan berhasil!',
    },
    withdraw_errors_alerts: {
      text1: 'Penarikan permintaan transaksi Anda',
      text2:
        'baru saja gagal. Jangan khawatir, kasing Anda sedang diproses. Harap tunggu umpan balik dari kami.',
      title: '【 {APP_NAME} 】 Kesalahan penarikan',
    },
    withdraw_usd_alerts: {
      account_name: 'Nama akun',
      account_no: 'jumlah tidak',
      amount: 'Jumlah',
      bank_branch: 'Cabang bank',
      bank_name: 'Nama bank',
      text1: 'Sistem baru saja menerima permintaan penarikan pengguna',
      text2: 'Informasi transaksi',
      title: 'Minta penarikan baru',
    },
    withdrawal_approved_text1: '{email}, permintaan penarikan Anda telah diproses.',
    withdrawal_approved_title: '【 {APP_NAME} 】 Penarikan Selesai',
    kyc_notification: {
      subject: '【 {APP_NAME} 】 Verifikasi KYC - {date}',
      name: 'Bapak / Ibu yang terhormat. {name}',
      text1: 'Terima kasih telah menggunakan layanan kami.',
      text2: 'Ini adalah dukungan bitCastle.',
      text3: 'Terima kasih sudah menunggu.',
      text4:
        'Karena verifikasi konfirmasi identitas telah dilakukan, jadi silakan masuk ke halaman pengguna dari sini dan periksa hasil pemeriksaan.',
      text5:
        'Jika pesan yang menyiratkan bahwa Anda perlu mengirimkan dokumen identifikasi tidak tercantum di bagian atas halaman saya, verifikasi identitas Anda telah berhasil diselesaikan.',
      text6:
        'Jika pesan ada di bagian atas halaman saya, pesan itu tidak disetujui karena pengiriman yang tidak tepat atau kesalahan gambar, jadi silakan cek dokumen konfirmasi identitas sekali lagi dan kirimkan kembali.',
      text7:
        'Kami tidak dapat memberi tahu Anda mengapa dokumen yang dikirimkan tidak memadai, tetapi alasan untuk menjadi tidak memadai terutama akan di bawah ini, jadi harap konfirmasikan apakah foto yang Anda kirimkan sekali tidak berlaku untuk yang berikut ini.',
      text8: 'Dokumen identifikasi pribadi tidak ditentukan',
      text9: 'Wajah Anda pada dokumen identifikasi dan foto selfie ID berbeda',
      text10:
        'Dokumen yang dikirimkan ke ID SERPHY, Nama bursa, tanggal, orang itu sendiri tidak ditampilkan bersama',
      text11: 'Tidak menghadap ke depan',
      text12:
        'Seluruh wajah tidak ditampilkan pada foto selfie ( seluruh kepala tidak ditampilkan )',
      text13: 'Tempat tinggal Cina dan AS',
      text14: 'Gambarnya gelap dan wajahnya tidak jernih',
      text15: 'Pengunggahan gambar gagal',
      text16: 'Saya minta maaf karena mengganggu. Kami menghargai kerja sama Anda.',
    },
    approved_notification: {
      subject: '【 {APP_NAME} 】 Penarikan Disetujui - {date}',
      name: 'Halo {name}',
      text1: 'Permintaan penarikan berikut disetujui.',
      text2: 'Mata uang',
      text3: 'Jumlah',
      text4: 'Alamat tujuan',
      text5: 'Tanggal yang diminta',
      text6: 'Kami akan pindah ke pemrosesan penarikan dari ini.',
      text7: 'Terima kasih atas dukungan Anda yang berkelanjutan dari bitCastle.',
      text8: '-',
      text9: '-',
      text10: '-',
      text11: '-',
      text12: '-',
    },
  },
  referral: {
    next_program:
      'Jika jumlah referral langsung Anda lebih tinggi atau sama dengan {condition} orang, Anda akan menerima komisi MAKSIMUM {rate}% seperti di bawah ini:',
    level1: 'Level 1',
    level2: 'Level 2',
    level3: 'Level 3',
    level4: 'Level 4',
    level5: 'Level 5',
    rate: 'Nilai adalah ',
  },
  address: {
    add_address: 'Pengelolaan Alamat Penarikan',
    add_success: 'Berhasil dibuat!',
    add_whitelist: 'Tambahkan ke Daftar Putih',
    address: 'Alamat',
    all: 'Semua',
    cancel_success: 'Dibatalkan dengan sukses!',
    coin: 'Koin',
    coin_name: 'Nama Koin',
    confirm_remove_address: 'Anda ingin menghapusnya?',
    date: 'Tanggal',
    delete: 'Hapus',
    deleted_success: 'Berhasil dihapus!',
    errors: {
      address_required: 'Kolom alamat wajib diisi.',
      blockchain_address: 'Alamat penarikan tidak valid.',
      coin_required: 'Kolom koin wajib diisi.',
      exist_withdraw_address: 'Alamat ini sudah ada!',
      label_required: 'Kolom label wajib diisi.',
      max_length: '{field} tidak boleh melebihi {amount} karakter.',
      min_length: '{field} tidak boleh kurang dari {amount} karakter.',
      tag_required: 'Kolom tag tujuan wajib diisi.',
    },
    label: 'Label',
    label_hint: 'Label tidak boleh melebihi 20 karakter.',
    memo: 'Memo/Tag/...',
    none: 'Tidak Ada',
    not_whitelisted: 'Tidak Ada dalam Daftar Putih',
    only_dis: 'Hanya tampilkan alamat dalam daftar putih',
    remove_whitelist: 'Hapus dari Daftar Putih',
    select_coin: 'Pilih koin',
    submit: 'Kirim',
    tag: 'Tag',
    tooltip1:
      'Ketika fitur ini diaktifkan, akun Anda hanya dapat melakukan penarikan ke alamat penarikan yang ada dalam daftar putih.',
    tooltip2:
      'Ketika fitur ini dinonaktifkan, akun Anda dapat melakukan penarikan ke alamat penarikan apa pun.',
    turn_off_mode_whitelist:
      'Setelah mematikan fungsi ini, Anda akan dapat menarik dana ke alamat penarikan apa pun. Apakah Anda yakin ingin mematikannya?',
    turn_on_mode_whitelist:
      'Setelah mengaktifkan fungsi ini, Anda hanya akan dapat melakukan penarikan ke alamat penarikan yang ada dalam daftar putih. Apakah Anda yakin ingin mengaktifkannya?',
    turn_on_whitelist: 'Harap aktifkan terlebih dahulu fungsi daftar putih penarikan.',
    update_white_list: 'Harap aktifkan terlebih dahulu fungsi daftar putih penarikan.',
    updated_success: 'Diperbarui dengan sukses!',
    white_list_off: 'Daftar Putih Dimatikan',
    white_list_on: 'Daftar Putih Diaktifkan',
    whitelisted: 'Terdaftar dalam Daftar Putih',
    withdrawal_address: 'Pengelolaan Alamat Penarikan',
    table: {
      empty_msg: 'Anda tidak memiliki data',
    },
  },
  admin: {
    deposit_approved: '"Pemasukan berhasil {jumlahUSD}{waktu}{APP_NAME}"',
    deposit_rejected: '"Pemasukan gagal {jumlahUSD}{waktu}Informasi tidak sesuai{APP_NAME}"',
    new_deposit: '"Pemasukan baru {jumlahUSD}{waktu}{APP_NAME}"',
    new_withdrawal: '"Penarikan baru {jumlahUSD}{waktu}{APP_NAME}"',
    withdraw_approved:
      '"Penarikan berhasil {jumlahUSD} bank {bankName} {bankAccount}{waktu}{APP_NAME}"',
    withdraw_status_pending: 'Tertunda',
    withdraw_status_success: 'Sukses',
  },
  auth: {
    confirmation_email_subject: '【{APP_NAME}】Aktifkan akun Anda di {APP_NAME}',
    email_confirm_success: 'Email Anda telah dikonfirmasi.',
    email_confirmation_code_invalid: 'Kode aktivasi tidak valid.',
    failed: 'Kredensial ini tidak cocok dengan data kami.',
    failed_login: 'Kredensial pengguna salah.',
    forgot_password: 'Lupa kata sandi?',
    otp_code_invalid: 'Kode Autentikasi Google tidak valid.',
    otp_code_required: 'Kolom kode Autentikasi Google wajib diisi.',
    otp_not_used: 'Harap tunggu hingga kode verifikasi berikutnya dihasilkan.',
    send_mail_failed: 'Gagal mengirim email, harap coba lagi.',
    throttle: 'Terlalu banyak percobaan masuk. Harap coba lagi dalam {detik} detik.',
  },
  bank_setting: {
    bank_setting: 'Pengaturan Bank',
    bank_name: 'Nama Bank',
    bank_branch: 'Cabang Bank',
    account_name: 'Nama Akun',
    account_no: 'Nomor Akun',
    edit: 'EDIT',
    return: 'KEMBALI',
    save: 'SIMPAN',
    error: {
      lose_bank_branch: 'Kolom cabang bank wajib diisi.',
      lose_bank_name: 'Kolom nama bank wajib diisi.',
      lose_account_name: 'Kolom nama akun wajib diisi.',
      lose_account_no: 'Kolom nomor akun wajib diisi.',
      lose_amount: 'Kolom jumlah wajib diisi.',
    },
    update_success_msg: 'Berhasil disimpan!',
  },
  change_password_form: {
    confirm:
      'Untuk tujuan keamanan, tidak ada penarikan yang diizinkan selama 24 jam setelah mengubah metode keamanan.',
    confirm_new_password: 'Konfirmasi Kata Sandi',
    confirm_new_password_change: 'Konfirmasi kata sandi',
    error_confirm: 'Konfirmasi kata sandi tidak cocok.',
    error_duplicated_password: 'Kata sandi baru harus berbeda dari kata sandi sebelumnya.',
    error_message: 'Kata sandi Anda tidak berubah!!!',
    new_password: 'Kata Sandi Baru',
    old_password: 'Kata Sandi Lama',
    new_password_change: 'kata sandi baru',
    old_password_change: 'kata sandi lama',
    require_confirm: 'Kolom konfirmasi kata sandi wajib diisi.',
    require_new: 'Kolom kata sandi baru wajib diisi.',
    require_old: 'Kolom kata sandi lama wajib diisi.',
    submit_button: 'Kirim',
    submit_button_processing: 'Memproses...',
    successful_message: 'Kata sandi Anda telah berhasil diubah!',
    title: 'Ubah Kata Sandi',
    save: 'Simpan',
  },
  change_password_cancel: 'Batal',
  client_download: {
    desktop: 'Desktop',
    destop_content:
      'Aplikasi desktop kami dirancang khusus untuk memberikan pengalaman perdagangan yang lancar dan efisien.',
    more_way: 'Lebih banyak cara untuk mendapatkan {APP_NAME}',
    provide_tool:
      'Kami menyediakan beberapa alat yang Anda butuhkan untuk bertransaksi di mana pun Anda berada',
    sp_download: 'Aplikasi smartphone',
    track: 'Lacak dan kelola perdagangan di mana pun Anda berada!',
  },
  client_connection: {
    fail: 'Gagal terhubung!',
  },
  common: {
    fetching_deposit_address: 'Mengambil alamat deposit. Mohon tunggu.',
    creating_deposit_address: 'Membuat alamat deposit baru. Mohon tunggu.',
    select: 'Pilih',
    refresh: 'Segarkan',
    reset: 'Atur Ulang',
    geetest: {
      fail: 'Silakan masukkan captcha.',
      loading: 'Memuat Captcha.',
    },
    action: {
      cancel: 'Batal',
      limit_close_position: 'Batas Penutupan Posisi',
      market_close_position: 'Batas Penutupan Market',
      no: 'Tidak',
      ok: 'OK',
      yes: 'Ya',
      apply: 'Terapkan',
      confirm: 'Konfirmasikan',
      reject: 'menolak',
      approve: 'menyetujui',
      execute_im: 'Jalankan segera',
      invoice: 'Invoice',
      pay_now: 'Bayar Sekarang'
    },
    announcements: 'Pengumuman',
    begin: {
      trading: 'Mulai Berdagang Sekarang!',
    },
    confirm: 'Konfirmasi',
    create_account_amanpuri: 'Buat akun di Bursa',
    datatable: {
      data_empty: 'Anda tidak memiliki data',
      no_data: 'Anda tidak memiliki data',
      empty_data: 'Data kosong.',
    },
    no_data: 'Anda tidak memiliki data',
    exchange: {
      now: 'Bursa Sekarang',
    },
    error: {
      required: 'Bidang {field} diperlukan.',
    },
    trading_markets: 'Perdagangan Market',
    max: 'Maks',
    max_only: 'Maks',
    market_info: 'Informasi Market',
    mobile_app: 'Aplikasi seluler',
    no_select: 'Tidak ada',
    order_id: 'ID Pesanan',
    order_status: {
      canceled: 'Dibatalkan',
      new: 'Baru',
      pending: 'Tertunda',
      filled: 'Diisi',
      partial_filled: 'Terisi Sebagian',
    },
    placeholders: {
      email: 'Email',
      login_password: 'Kata Sandi Masuk',
      otp: 'Kode Autentikasi Google',
      password: 'Kata Sandi',
      repeat_password: 'Ulangi Kata Sandi',
      referral: 'ID Referral (opsional)',
      confirm_password: 'Konfirmasi Kata Sandi',
    },
    validate: {
      email: 'email',
      confirm_password: 'confirm password',
    },
    processing: 'Memproses...',
    sales_point: 'Titik Penjualan',
    no_file_chosen: 'Tidak ada file yang dipilih',
    slogan_create_account: 'Perdagangan Bitcoin aman dan terpercaya di NVX Exchange',
    spot_exchange: 'Bursa Spot',
    trade_type: {
      buy: 'Beli',
      sell: 'Jual',
    },
    transaction_status: {
      cancel: 'Dibatalkan',
      error: 'Error',
      pending: 'Tertunda',
      rejected: 'Ditolak',
      success: 'Sukses',
      submitted: 'Dikirimkan',
      failed: 'Gagal',
    },
    transaction_message: {
      not_enough_liquidity: 'Liquiditas tidak mencukupi. Silakan hubungi customer support.',
      otc_inactive: 'OTC tidak aktif',
    },
    date: {
      warning: 'Rentang tanggal tidak valid. Tanggal akhir lebih kecil dari tanggal mulai!',
    },
    message: {
      network_error: 'Tidak dapat terhubung ke server.',
    },
    status: {
      success: 'Sukses',
      pending: 'Tertunda',
      cancel: 'Dibatalkan',
      expired: 'Kedaluwarsa',
      new: 'Baru',
      error: 'Kesalahan',
      error_on_update: 'Kesalahan saat memperbarui data',
      untriggered: 'Tidak terpicu',
    },
    search: 'Pencarian',
    kyc: {
      pending: 'Tertunda',
      verified: 'Diverifikasi',
    },
    empty_data: 'Data kosong.',
    current_interval: 'Interval saat ini',
    commissions_history: 'Riwayat Komisi',
    commissions_setting: 'Pengaturan Komisi',
    withdraw_history: 'Riwayat Penarikan',
    high_watermark: 'Tanda Air Tinggi',
    the_base_currency_is_symbol: 'Mata uang dasar adalah {symbol}',
    the_quote_currency_is_symbol: 'Mata uang penawaran adalah {symbol}',
    perfomance_fee: 'Biaya Kinerja',
    management_fee: 'Biaya Manajemen',
    flat_fee: 'Biaya tetap',
    curious_formula: 'I = ( Q - B ) / T <br /> F = P + Penjepit ( I - P, -0,05%, 0,05% )',
    curious_result:
      'I = ( 0,06% - 0,03% ) / 3 <br /> F = 1,4240% + Penjepit ( 0,0100% - 1,4240%, -0, 0,',
    view_contract_series_guide: 'Lihat panduan seri {root_symbol}',
    ticker_root: 'Ticker Root',
    expiry: 'Kedaluwarsa',
    liquidation: 'Likuidasi',
    camera_not_readable:
      'Tidak dapat membuka kamera karena Anda menggunakannya untuk aplikasi lain',
    camera_not_permisson:
      'Tidak dapat membuka kamera karena Anda menolak izin. Harap setujui izin dan coba lagi',
  },
  'common.datatable.data_empty': 'Anda tidak memiliki data',
  'common.new': 'Baru',
  common_action: {
    cancel: 'Batalkan',
    confirm: 'Konfirmasi',
    ok: 'Oke',
  },
  contact: {
    contact_us: 'Hubungi Kami',
    got_question: 'Ada Pertanyaan?',
    non_account_related:
      'Untuk komunikasi yang tidak terkait dengan akun, bergabunglah dengan komunitas online kami:',
    please: 'Masih tidak menemukan yang Anda cari? Silakan',
    please_check:
      'Silakan cek FAQ resmi {APP_NAME} terlebih dahulu untuk melihat apakah pertanyaan Anda sudah dijawab.',
    submit_request: 'ajukan permintaan',
    view_suport: 'Lihat Dokumentasi Dukungan',
  },
  currency: {
    ada: {
      fullname: 'Cardano',
      shortname: 'ADA',
    },
    amal: {
      fullname: 'NVX Exchange',
      shortname: '4IRE',
    },
    mgc: {
      fullname: 'Mango Coin',
      shortname: 'MGC',
    },
    bch: {
      fullname: 'Bitcoin Cash',
      shortname: 'BCH',
    },
    btc: {
      fullname: 'Bitcoin',
      shortname: 'BTC',
    },
    eos: {
      fullname: 'EOS',
      shortname: 'EOS',
    },
    eth: {
      fullname: 'Ethereum',
      shortname: 'ETH',
    },
    ltc: {
      fullname: 'LiteCoin',
      shortname: 'LTC',
    },
    usdt: {
      fullname: 'USDT',
      shortname: 'USDT',
    },
    tomo: {
      fullname: 'TomoChain',
      shortname: 'TOMO',
    },
    tusd: {
      fullname: 'TrueUSD',
      shortname: 'TUSD',
    },
    usd: {
      fullname: 'Dolar Amerika Serikat',
      shortname: 'USD',
    },
    xlm: {
      fullname: 'Stellar',
      shortname: 'XLM',
    },
    xrp: {
      fullname: 'Ripple',
      shortname: 'XRP',
    },
  },
  amal: {
    amal_main_balance: 'Saldo Utama',
    lock_by_user: 'Saldo Dividen',
    amal_spot_balance: 'Saldo Spot',
    amal_margin_balance: 'Saldo Margin',
  },
  exchange: {
    basic: {
      chart: {
        btn_add_chart: 'Tambahkan Bagan',
        buy_order: 'Beli pesanan',
        candle: 'Lilin',
        depth: 'Kedalaman',
        msg_close_chart: 'Apakah Anda ingin menutup bagan ini ?',
        price: 'Harga',
        sell_order: 'Jual pesanan',
      },
      label_hour: 'Jam',
      label_minute: 'Menit',
      time: {
        hour: '{number} jam',
        hours: '{number} jam',
        minute: '{number} menit',
        minutes: '{number} menit',
        one_day: '1 hari',
        one_month: '1 bulan',
        one_week: '1 minggu',
        day: '1D',
        week: '1W',
        month: '1M',
      },
      trading_page: {
        change_24h: 'Perubahan 24 jam',
        confirm: 'Konfirmasikan',
        evaluation_amount: 'Evaluasi',
        high: 'Tinggi',
        high_24h: '24 jam Tinggi',
        holding_balance: 'Saldo',
        last_price: 'Harga Terakhir',
        low: 'Rendah',
        low_24h: '24 jam Rendah',
        total_purchase_amount: 'Dibeli',
        volume: 'Volume',
        volume_24h: 'Volume 24 jam',
        current_price: 'Harga sekarang',
      },
    },
  },
  favourite: {
    market_price: {
      coin: 'Nama',
      current_price: 'Harga Terakhir',
      favorites: 'Favorit',
      percent_day_before: '± %',
      transaction_amount: 'Volume',
      volume: 'Vol',
    },
  },
  fee_guide: {
    actual_purcharse: 'Jumlah yang Diterima Sebenarnya',
    actual_sell: 'Jumlah yang Dijual Sebenarnya',
    commission_rate: 'Tingkat komisi berdasarkan volume transaksi',
    commission_tip1:
      '{APP_NAME} menerapkan biaya yang berbeda sesuai dengan volume transaksi seperti di atas. Peringkat biaya dapat berubah.',
    commission_tip2: 'Peringkat volume dilakukan sekali sehari pada {time} setiap hari.',
    commission_tip3: 'Biaya Pembuat / Pengambil didasarkan pada Tingkat Program Biaya',
    buyer_example: 'Contoh Pembeli:',
    seller_example: 'Contoh Penjual:',
    fee: 'Biaya',
    fomula_actual_pucharse: '#ERROR!',
    for_example: 'Sebagai contoh:',
    formula_actual_sell: '#ERROR!',
    formula_fee: '#ERROR!',
    formula_sell_fee: '#ERROR!',
    formula_subtotal: '#ERROR!',
    formula_transaction_value: '#ERROR!',
    last_30_days: '30 hari terakhir',
    less_than: 'Kurang dari',
    level_name: 'Tingkat:',
    level_title: 'Level Anda saat ini adalah',
    rating: 'Tingkat',
    subtotal: 'Total Pembayaran USD ( Nilai Transaksi )',
    text13: 'Biaya tukang / tukang roti didasarkan pada',
    text14:
      'Dalam hal pesanan pembelian, jumlah yang diperoleh dengan mengurangi jumlah yang sesuai dengan tarif komisi dari jumlah yang diisi akan menjadi jumlah aktual.',
    text15:
      'Dalam hal pesanan penjualan, jumlah harga jual dikurangi tarif komisi adalah harga jual aktual.',
    text16:
      'Jika pesanan pembelian BTC diisi, tarif komisi adalah 0,15%, harganya USD 5.000.000 dan jumlahnya 1 BTC',
    text20:
      'Jika pesanan jual BTC sepenuhnya diisi dengan pesanan Contoh Pembeli dengan komisi yang sama',
    text24: 'Anda dapat memeriksa biaya aktual serta jumlah yang diisi dalam Riwayat Perdagangan',
    title: 'Instruksi Biaya',
    transaction_fee: 'Metode perhitungan biaya transaksi',
    transaction_value: 'Total yang Diterima USD ( Nilai Transaksi )',
  },
  Over_daily_limit: 'Anda telah menarik batas hari itu.',
  funds: {
    action: {
      deposit: 'menyetor',
      placeholder_input_coin: 'Silakan masukkan kata kunci koin',
      withdraw: 'menarik',
    },
    balances: {
      approx: 'Perkiraan. Nilai 4IRE',
      amount_is_positive: 'Jumlah minimum harus lebih besar dari 0.',
      amount_over_0: 'Jumlah minimum harus lebih besar dari 0.',
      amount_can_not_greater_than_available_balance: 'Saldo pertukaran tidak cukup.',
      amount_can_not_greater_than_available_dividend_balance:
        'Saldo dividen yang tersedia tidak cukup.',
      amount_can_not_greater_than_available_main_balance: 'Keseimbangan dompet tidak cukup.',
      amount_can_not_greater_than_exchange_available_balance: 'Saldo utama tidak cukup.',
      amount_can_not_greater_than_exchange_available_balance_without_orderbook:
        'Keseimbangan dompet tidak cukup.',
      assign: 'Menetapkan',
      avaliable_balance: 'Saldo Wallet ',
      available_airdrop_balance: 'Saldo Dividen Tersedia',
      balance: 'Saldo',
      convert_small_balance_success: 'Konversi Kesuksesan Saldo',
      curency: 'Mata uang',
      exchange_available_balance: 'Dapat diperdagangkan',
      tradable_balane: 'Saldo Dapat diperdangkan',
      airdrop_balance: 'Saldo dividen',
      estimated_airdrop_value: 'Rasio Holding:',
      unlock_date: 'Buka tanggal',
      lock_balance: 'Kunci saldo',
      no_data: 'Anda tidak memiliki data',
      btc_value: 'Nilai USDT',
      convert_to_amal: 'CONVERT KE 4IRE',
      convert_sm_balance: 'Ubah Saldo Kecil menjadi 4IRE',
      coin: 'Koin',
      deposit: 'Setoran',
      default_balance: 0,
      estimated_value: 'Nilai Perkiraan',
      from: 'Dari',
      hide: 'Menyembunyikan',
      hide_small_assets: 'Sembunyikan Saldo 0',
      interval: 'Interval',
      in_order: 'Dalam urutan',
      in_use: 'Sedang digunakan',
      infomation: 'Informasi',
      main_account: 'Akun Utama',
      min_amount_is_invalid: 'Jumlah setoran minimum adalah 10,000',
      name: 'Nama',
      show_all: 'Tampilkan semua',
      small_balance: 'Saldo Kecil',
      small_balance_notice: 'Saldo dinilai kurang dari {amount} BTC',
      hide_small_balance: 'Sembunyikan Saldo 0',
      P_L: 'P / L',
      revoke: 'Mencabut',
      available_amount: 'Jumlah yang Tersedia',
      my_investment: 'Investasi Saya',
      revokeable_amount: 'Jumlah yang Dapat Dicabut',
      to: 'Untuk',
      tranfer: 'Transfer',
      transfer: 'Transfer',
      transfer_balance_fails: 'Saldo transfer gagal!',
      airdrop_has_disable:
        'Program dividen berakhir. Anda tidak dapat mentransfer uang ke wallet dividen.',
      transfer_balance_success: 'Transfer saldo berhasil!',
      total_balance: 'Total Saldo',
      total_airdrop_balance: 'Total Saldo Dividen',
      withdrawal: 'Penarikan',
      suspend: 'Tangguhkan',
      trade: 'Perdagangan',
      you_have_selected_coin:
        "Anda telah memilih kelas rentang < = 'jumlah koin' > {coin}</span>koin",
      withdrawal_limit_24: '24 Batas Penarikan',
      locked_balance: 'Saldo Tertahan',
      locked_balance_desc:
        'Saldo tertahan adalah jumlah aset Anda yang tertahan karena posisi Anda saat ini seperti: penarikan tertunda dan saldo pertukaran.',
      in_transaction: 'Dalam Transaksi',
      in_transaction_desc:
        "Dana mungkin sementara tidak tersedia dalam 'Dalam Transaksi' karena masih ada perdagangan yang berlangsung dalam buku pesanan Anda. Dana akan tersedia kembali setelah perdagangan diselesaikan.",
      pending_withdrawal: 'Penarikan Tertunda',
      pending_withdrawal_desc:
        "Dana mungkin sementara tidak tersedia dalam 'Penarikan Tertunda' sementara sistem kami memproses permintaan Anda dengan aman. Dana akan tersedia kembali setelah proses selesai.",
      exchange_balance: 'Saldo Bursa',
      total_locked_balance: 'Total Saldo Tertahan',
    },
    convert_to_amal: 'Konversikan ke 4IRE',
    deposit: {
      available_balance: 'Saldo Wallet ',
      choice: 'Pilihan',
      coppy_address: 'Salin Alamat',
      copy_error: 'Salin kesalahan',
      copy_success: 'Disalin dengan sukses!',
      copy_tag: 'Salin Tag',
      deposit_address: '{coin} Alamat Setoran',
      deposit_tag: '{coin} Tag Deposit',
      history: 'Riwayat',
      important: {
        text1:
          'Ini adalah ERC20 {name} alamat deposit, JANGAN setorkan OMNI {name} token ke alamat ini.',
        text2:
          'Kirim hanya {name} ke alamat setoran ini. Mengirim mata uang lain ke alamat ini dapat mengakibatkan hilangnya deposit Anda.',
      },
      in_order: 'Dalam urutan',
      not_arrive: 'Setoran Tidak Tiba',
      no_data: 'Anda tidak memiliki data',
      show_qr: 'Tampilkan Kode QR',
      tips: {
        text1: 'Harap perhatikan',
        text2: '{name} akan segera dikirim setelah {count} konfirmasi jaringan.',
        text4: '{name} akan segera dikirim setelah {count} konfirmasi jaringan.',
        text3:
          "Setelah melakukan setoran, Anda dapat melacak kemajuannya di <a class='link-history' href='/funds/history?type=deposit' target='_blank' style=\"font-size: inherit;\">history</a> halaman.",
        text5: 'Jaringan: {coinNetwork}',
      },
      title_page: 'Setoran',
      tooltip:
        'Jika setoran Anda tidak tiba untuk waktu yang lama, itu mungkin disebabkan oleh blockchain yang macet atau transaksi yang tidak lengkap. < kelas = \'link-primer\' href = "#" > Referensi Lebih Banyak </a>',
      total_balance: 'Total Saldo',
      view_all: 'Lihat semua',
      what: 'Apa nama {name}?',
    },
    depositUSD: {
      no_data: 'Anda tidak memiliki data',
    },
    deposit_fiat: {
      cancel_deposit_usd: 'Membatalkan',
      deposit_amount: 'Jumlah Setoran',
      deposit_note:
        'Silakan melakukan transfer uang sesuai dengan informasi di bawah ini. Kami akan memverifikasi dan mentransfer jumlah ini ke akun Anda. Terima kasih!',
      errors: {
        min_amount: 'Jumlah setoran minimum adalah 10,000',
        max_amount: 'Jumlahnya harus kurang dari atau sama dengan {max_amount}.',
        required: 'Bidang jumlah setoran diperlukan.',
      },
      guide: {
        text1: '1. Silakan kirim jumlah yang sama dengan jumlah setoran.',
        text2:
          'Jika jumlah setoran berbeda dari jumlah setoran aktual, setoran tidak akan diproses.',
        text3: "2. Pastikan untuk memasukkan 'Kode setoran'.",
        text4:
          'Saat menyetor uang, silakan masukkan kode setoran sebagai konten transfer. Pastikan Anda hanya menggunakan kode setoran sebagai konten transfer dan tidak ada yang lain. Proses setoran dapat ditunda ketika Anda tidak memasukkan kode setoran.',
        text7: '2. Setoran akan diproses dalam waktu maksimum 10 menit.',
        text8:
          'Dalam keadaan normal, waktu rata-rata membutuhkan waktu sekitar 3 hingga 5 menit untuk memproses deposit.',
        text9: 'Kami akan memberi tahu Anda setelah setoran diproses.',
        text10: '3. Silakan hubungi dukungan pelanggan kami jika terjadi keterlambatan pembayaran.',
        text11:
          'Jika jumlah setoran yang diperlukan dan jumlah yang ditransfer berbeda, atau Anda lupa memasukkan kode setoran, silakan kirim permintaan di pusat dukungan kami.',
        text12: '* Penarikan akan dibatasi selama waktu check-in sistem perbankan.',
      },
      notes: 'Catatan',
      other_deposit: 'Lakukan transaksi lain',
      pending_transaction: {
        account_name: 'Nama akun',
        account_no: 'Akun no',
        amount: 'Jumlah',
        bank_branch: 'Cabang bank',
        bank_name: 'Nama bank',
        code: 'Kode',
      },
      submit_deposit_usd: 'Kirim',
      success_message: 'Permintaan setoran berhasil dibuat!',
      card_expired_message: 'Kartu kredit telah kedaluwarsa',
      copy_success_message: 'Nomor VA yang disalin',
      transaction_history_table: {
        account: 'Akun',
        amount: 'Jumlah',
        bank: 'Bank',
        code: 'Kode',
        status: 'Status',
        time: 'Waktu',
        text1: 'Apakah Anda pasti akan membatalkan setoran ini?',
      },
      copy_account_number: 'Klik nomor akun untuk menyalin',
      card_not_support_message: 'Kartu kredit tidak didukung untuk pembayaran online',
      fail_message: 'Transaksi gagal!',
      something_wrong: 'Ada yang salah!',
    },
    history: {
      address: 'Alamat',
      to_address: 'Alamat Tujuan',
      from_address: 'Alamat Penerima',
      amount: 'Jumlah',
      coin: 'Koin',
      date: 'Tanggal',
      deposit: 'Setoran',
      export_complete_deposit_history: 'Ekspor Riwayat Setoran Lengkap',
      export_complete_withdraw_history: 'Ekspor Riwayat Penarikan Lengkap',
      from: 'Dari',
      history: 'Riwayat',
      infomation: 'Informasi',
      status: 'Status',
      tag: 'Tag',
      to: 'Untuk',
      transaction_history: 'Riwayat Transaksi',
      txid: 'TxID',
      txhash: 'TxHash',
      withdrawal: 'Penarikan',
      fee: 'Biaya',
      tax: 'Pajak',
      recevied_amount: 'Jumlah yang Diterima',
      time: 'Waktu',
      date: 'Tanggal',
      unique_code: 'Kode Unik',
      number_of_confirmations: 'Jumlah Konfirmasi',
      confirmed: '(Terkonfirmasi)',
    },
    select_coin: 'Pilih koin / token ke {action}',
    inputted_amount: 'Jumlah yang dimasukkan',
    fee: 'Biaya',
    to_address: 'Ke Alamat',
    you_will_get: 'Anda akan menerima',
    withdraw_fiat: {
      account_info: 'Informasi Akun',
      account_name: 'Nama akun',
      account_no: 'Akun no',
      amount: 'Jumlah IDR',
      available: 'Tersedia',
      bank_branch: 'Cabang bank',
      bank_info: 'Informasi Bank',
      bank_name: 'Nama bank',
      amount: 'Jumlah IDR',
      fee: 'Biaya',
      tax: 'Pajak',
    },
    withdrawals: {
      select_coin: 'Pilih koin',
      add_new_address: 'Silakan masukkan alamat penarikan baru Anda di bawah ini',
      add_whitelist_address:
        'Anda tidak memiliki alamat daftar putih. Silakan kelola alamat Anda di situs web PC {APP_NAME}.',
      amount: 'jumlah',
      available_balance: 'Saldo Wallet',
      available_balance_to_withdraw: 'Saldo Tersedia untuk Ditarik',
      bank_accounts: 'Akun bank',
      choose_bank_placeholder: 'Pilih rekening bank',
      minimum_withdrawal: '- Penarikan Minimum:',
      choice: 'Pilihan',
      no_data: 'Anda tidak memiliki data',
      no_usd_data: 'Anda tidak memiliki data',
      confirm_otp: 'Konfirmasikan OTP',
      confirm_withdraw: 'Konfirmasikan penarikan',
      did_not_receive_the_mail: 'Tidak Menerima Email',
      empty_whitelist: {
        address_management: 'Manajemen Alamat',
        text: 'Anda tidak memiliki alamat daftar putih.',
      },
      error_message: {
        address_not_exist_in_whitelist: 'Alamat ini tidak ada di alamat daftar putih.',
        amount_withdraw_is_positive: 'Jumlah penarikan harus berupa angka negatif.',
        minimum_withdraw: 'Penarikan gagal. Silakan coba lagi!',
        not_enough_balance: 'Uang dalam akun tidak cukup untuk melakukan transaksi.',
        over_daily_limit: 'Anda telah menarik batas hari itu.',
        over_limit: 'Anda telah menarik batasnya.',
        over_one_time_limit: 'Anda telah ditarik sekali',
        withdraw_is_blocking: 'Penarikan sedang diblokir',
      },
      errors: {
        account_no_length: 'Akun no tidak valid.',
        blockchain_address: 'Alamat penarikan tidak valid.',
        blockchain_address_exists: 'Alamat sudah ada.',
        blockchain_address_sub_address_exists: 'Alamat penarikan atau tag / memo / ... sudah ada.',
        exist_withdraw_address: 'Alamat ini (dan memo/tag...) sudah ada!',
        max_balance: 'Saldo akun Anda tidak cukup!',
        otp: 'OTP tidak diverifikasi. Harap aktifkan Otentikasi Google!',
        kyc: 'Harap selesaikan proses KYC untuk mengaktifkan fungsi ini',
        max_once_amount: 'Jumlah yang anda tarik melebihi [batas / batas harian]',
        min_amount: 'Jumlah penarikan minimum adalah {min_amount}.',
        not_allowed_withdraw: 'Tingkat keamanan Anda tidak diizinkan untuk menarik.',
        otp_length_digits: 'Panjang Kode Otentikasi Google harus 6 digit.',
        otp_length_6_digits: 'Kode Otentikasi Google harus 6 digit.',
        otp_required: 'Kolom kode Autentikasi Google wajib diisi',
        required: 'Bidang ini tidak boleh kosong',
        min_amount: 'Jumlah penarikan harus lebih besar dari {min_amount}.',
      },
      history: 'Riwayat',
      important: {
        text1: 'Penting',
        text2: 'Penarikan Minimum',
        text3:
          'Jangan menarik langsung ke crowfund atau ICO. Kami tidak akan mengkredit akun Anda dengan token dari penjualan itu.',
      },
      in_order: 'Sedang digunakan',
      input_address_error: 'Alamat tidak valid',
      loading: 'Memproses...',
      submit: 'Kirim',
      success_message: 'Transaksi yang dibuat berhasil!',
      'resend-sms-success_message': 'OTP telah dikirim kembali. Silakan periksa ponsel Anda',
      confirm_withdrawal: 'Anda telah mengkonfirmasi transaksi penarikan.',
      tips: {
        text1: 'Tolong dicatat',
        text2:
          'Setelah Anda mengirimkan permintaan penarikan Anda, kami akan mengirim email konfirmasi. Silakan klik tautan konfirmasi di email Anda.',
        text3:
          'Setelah melakukan penarikan, Anda dapat melacak statusnya di halaman <a class="link-history" href=\'/funds/history?type=withdraw\' target=\'_blank\' style="font-size: inherit;">riwayat</a>.',
      },
      title_page: 'Penarikan',
      unique_code: 'Kode Unik',
      tooltip: 'Jika Anda tidak dapat menemukan email, silakan periksa spam atau folder lain.',
      total_balance: 'Total Saldo',
      transaction_fee: 'Biaya Transaksi',
      use_a_new_address: 'Gunakan alamat baru',
      view_all: 'Lihat semua',
      what: 'Apa nama {name}?',
      withdrawal_address: 'Alamat Penarikan',
      withdrawal_limit_24: 'Batas penarikan 24 jam',
      withdrawal_placeholder_address: 'Alamat',
      withdrawal_placeholder_available: 'Tersedia',
      withdrawal_placeholder_loading: 'Memuat...',
      withdrawal_please_wait: 'Mohon Tunggu...',
      withdrawal_placeholder_label: 'Label',
      xrp_tag: 'Tag Penarikan',
      no_memo: 'NoMemo',
      you_will_get: 'Anda akan menerima',
      no_fiat_data: 'Anda tidak memiliki data',
      otp: {
        notify: {
          title:
            'Kami telah mengirim OTP ke nomor {phone_no}. Ini akan kedaluwarsa dalam 1 menit. <br /> Mohon masukan kode untuk menyelesaikan penarikan {token} Anda.',
        },
        error: 'Bidang ini tidak bisa kosong',
        verify: {
          title: 'Verifikasi OTP',
          submit: 'Memverifikasi',
        },
        resend: {
          title: 'Kirim ulang OTP',
          titleWa: 'Mengirim ulang OTP melalui WhatsApp',
          titleSms: 'Tidak mendapatkan OTP? Anda dapat mencoba',
          buttonSms: 'mengirim ulang OTP melalui SMS',
        },
      },
      form_add_new_address: 'Tambah Alamat Baru',
      select_address: 'Pilih Alamat',
      no_address_selected: 'Tidak ada alamat dipilih',
      change_address: 'Ganti Alamat',
      add_address: 'Tambah Alamat',
      error_address: 'Mohon pilih alamat penarikan',
      title_discard_new_address: 'Apakah anda yakin ingin membatalkannya?',
      text_discard_new_address: 'Label dan alamat yang dimasukkan tidak akan disimpan',
      added_new_address: 'Alamat baru berhasil ditambahkan',
      back_discard_new_address: 'Tidak, kembali',
      yes_discard_new_address: 'Ya, batalkan',
      select_saved_address: 'Pilih alamat tersimpan',
    },
    disable_coin_msg: 'Penarikan/Penyetoran saat ini dinonaktifkan untuk koin ini',
    transaction_history: {
      no_deposit_data: 'Anda tidak memiliki data',
      no_withdraw_data: 'Anda tidak memiliki data',
    },
  },
  landing: {
    banner: {
      desc: 'Leverage hingga 100x. Berdagang tanpa tanggal kedaluwarsa. Keamanan industri terkemuka. Selamat datang di platform perdagangan Bitcoin yang paling canggih.',
      title: {
        span: 'Smart Bitcoin -',
        text: 'Crypto Penukaran Mata Uang',
      },
    },
    swiper: {
      amal: {
        title: '4IRE NET HOLDING COMPETITION!',
        time: '21:00 （ GMT + 9 ） 30 Des 2019 〜 21:00 （ GMT + 9 ） 31 Jan 2020',
      },
    },
    change: 'Perubahan 24 jam',
    chart: {
      change: 'Perubahan',
      last_price: 'Harga Terakhir',
    },
    clients_download: 'Unduh',
    clients_text1: 'Lebih banyak cara untuk mendapatkan {APP_NAME}',
    clients_text2:
      'Kami telah menyediakan beberapa alat yang Anda butuhkan untuk berdagang saat bepergian',
    coin: 'Koin',
    coin_markets: '{coin} Market',
    favorites: 'Favorit',
    high: '24 jam Tinggi',
    last_price: 'Harga Terakhir',
    low: '24 jam Rendah',
    pair: 'Pasangan',
    price: 'Harga',
    register: 'Daftar sekarang',
    gotrade: 'Pergi ke Perdagangan',
    slogan: 'Tukarkan Dunia',
    volume: 'Volume 24 jam',
    current_rate: 'Tingkat <br /> saat ini',
  },
  login: {
    active_device_failed: 'Tautan otorisasi Anda tidak valid atau kedaluwarsa!',
    active_device_success:
      'Anda telah berhasil mengotorisasi perangkat baru. <br /> Silakan coba masuk lagi!<p>Perangkat: {platform} {operating_system}<br /> Alamat IP: {latest_ip_address}</p>',
    confirm_failed: 'Tautan konfirmasi Anda tidak valid atau kedaluwarsa!',
    confirm_success: 'Email Anda telah dikonfirmasi!',
    confirm_email_failed: 'Kami tidak dapat menemukan pengguna dengan alamat email itu.',
    dont_has_account: 'Tidak punya akun?',
    forgot: 'Lupa kata sandi Anda?',
    sub_title: 'Silakan periksa Anda sedang berkunjung',
    title: 'Masuk',
    title_otp: 'Otentikasi Google',
    resend_confirm_mail_success:
      "Mengonfirmasi Email Ke <strong class='email-user' title='{email}'>{email}</strong>",
    admin: 'Login Admin',
    email: 'Alamat E-Mail',
    email2: 'Email',
  },
  messages: {
    user_cannot_withdraw_fiat: 'Harap selesaikan proses KYC untuk mengaktifkan fungsi ini',
    error: {
      error: 'Kesalahan',
      balance_insufficient: 'Saldo tidak mencukupi. ',
      balance_insufficient1: 'Saldo tidak mencukupi. Silakan transfer uang ke saldo Exchange. ',
      minimum_base_price: 'Harga berhenti harus setidaknya {value}.',
      minimum_limit: 'Batas harga harus setidaknya {value}.',
      minimum_price: 'Harga harus setidaknya {value}.',
      minimum_quantity: 'Jumlah harus setidaknya {value}.',
      minimum_total: 'Jumlah minimum transaksi setidaknya {value}.',
      buy: {
        empty_base_price: 'Silakan masukkan harga berhenti beli.',
        empty_limit: 'Silakan masukkan harga batas pembelian.',
        empty_price: 'Silakan masukkan harga beli.',
        empty_quantity: 'Silakan masukkan jumlah pembelian.',
      },
      sell: {
        empty_base_price: 'Silakan masukkan harga berhenti jual.',
        empty_limit: 'Silakan masukkan harga batas penjualan.',
        empty_price: 'Silakan masukkan harga jual.',
        empty_quantity: 'Silakan masukkan jumlah penjualan.',
      },
    },
    insufficient_balance: 'Saldo tidak mencukupi.',
    primary: 'Utama',
    success: 'Sukses',
    info: 'Info',
    warning: 'Peringatan',
    insufficient_balance: 'Saldo tidak mencukupi.',
    send_contact_success: 'Kirim email berhasil',
    send_contact_wrong_data: 'Data salah',
    sesstion_terminated:
      'Sesi Anda saat ini dihentikan karena seseorang masuk ke akun ini dari perangkat atau browser lain.',
    successful_login: '{APP_NAME} login {email}',
    unauthorize: 'Unauthorized',
    your_otp_code: '{otp}',
  },
  menu: {
    about: 'Tentang',
    account: 'Akun',
    address: 'Manajemen Alamat',
    api: 'API',
    apply_to_list: 'Daftar',
    balances: 'Saldo',
    change_password: 'Ubah Kata Sandi',
    contact: 'Kontak',
    deposit_usd: 'Setor IDR',
    deposits: 'Setoran',
    estimated_value: 'Nilai Perkiraan',
    exchange: 'Bertukar',
    fees: 'Biaya pertukaran',
    funds: 'Dana',
    investments: 'Investasi',
    request: 'Permintaan',
    investors: 'Investor',
    history: 'Riwayat',
    history_title: 'Riwayat',
    info: 'Info',
    join_us: 'Bergabunglah dengan kami',
    homepage: 'Halaman Beranda',
    get_started: 'Mulai',
    lang: {
      en: 'Inggris',
      zh: 'Cina',
      ja: '日本人',
      acr: {
        en: 'EN',
        zh: 'ZH',
      },
      id: 'Bahasa Indonesia',
    },
    login: 'Login',
    logout: 'Keluar',
    news: 'Berita',
    finance: 'Keuangan',
    instant_convert: 'Konversi Instan',
    convert_history: 'Riwayat Konversi',
    slippery_note: 'Slippery Note',
    one_day: '1 hari',
    one_month: '1 bulan',
    one_week: '1 minggu',
    open_orders: 'Buka Pesanan',
    or: 'atau',
    order_history: 'Riwayat Pesanan',
    privacy: 'Kebijakan Privasi',
    register: 'Daftar',
    signup: 'Mendaftar',
    signin: 'Masuk',
    sales_point: 'Titik penjualan',
    support: 'Dukungan',
    support_withdrawal: 'Panduan Penarikan',
    spot_exchange: 'Pertukaran Spot',
    terms: 'Ketentuan Penggunaan',
    three_months: '3 Bulan',
    trade_history: 'Riwayat Perdagangan',
    transaction_history: 'Riwayat Transaksi',
    demo: 'Demo',
    trade: 'Perdagangan',
    wallet: 'Wallet',
    withdraw_usd: 'Penarikan IDR',
    withdrawals: 'Penarikan',
    select_languge: 'Pilih Bahasa',
    orders: {
      orders: 'Pesanan',
      open_order: 'Buka Pesanan',
      order_history: 'Riwayat Pesanan',
      trade_history: 'Riwayat Perdagangan',
    },
    notices: 'Pemberitahuan',
    email_marketing: 'Pemasaran Email',
    home: 'Rumah',
    leader_board: 'Leaderboard',
    commissions: 'Komisi',
    send: 'Kirim',
  },
  'order.open_order.no_data': 'Anda tidak memiliki data',
  'order.open_order.no_data.all': 'Tidak ada pesanan.',
  'order.open_order.no_data.limit': 'Anda tidak memiliki pesanan terbuka.',
  'order.open_order.no_data.stop_limit': 'Tidak ada pesanan.',
  'order.open_order.no_data.market': 'Tidak ada batas pesanan.',
  'order.open_order.no_data.stop_market': 'Tidak ada perintah batas berhenti.',
  'order.open_order.action': 'Tidak ada pesanan market.',
  'order.open_order.amount': 'Jumlah',
  'order.open_order.cancel': 'Tindakan',
  'order.open_order.cancel_all': 'Batalkan Semua',
  'order.open_order.cancel_all_message': 'Apakah Anda ingin membatalkan semua Order?',
  'order.open_order.cancel_market_orders': 'Batalkan Market Order',
  'order.open_order.cancel_limit_orders': 'Batalkan Limit Order',
  'order.open_order.cancel_stop_market_orders': 'Batalkan Stop Market',
  'order.open_order.cancel_stop_limit_orders': 'Batalkan Stop Limit',
  'order.open_order.cancel_limit_price_message': 'Apakah Anda ingin membatalkan semua Limit Order?',
  'order.open_order.cancel_market_price_message':
    'Apakah Anda ingin membatalkan semua Market Order?',
  'order.open_order.cancel_one_message': 'Apakah Anda ingin membatalkan semua Pesanan Batas?',
  'order.open_order.cancel_order_success': 'Order Anda telah dibatalkan!',
  'order.open_order.match_order_success': 'Apakah Anda ingin membatalkan pesanan ini?',
  'order.open_order.cancel_orders_success': 'Pesanan Anda telah dibatalkan!',
  'order.open_order.cancel_stop_limit_order': 'Pesanan Anda telah dicocokkan!',
  'order.open_order.cancel_stop_limit_price_message':
    'Apakah Anda ingin membatalkan semua Stop Limit Order?',
  'order.open_order.cancel_stop_market_order': 'Batalkan Batas Pesanan',
  'order.open_order.cancel_stop_market_price_message':
    'Apakah Anda ingin membatalkan semua Stop Market Order?',
  'order.open_order.date': 'Tanggal',
  'order.open_order.deposit': 'Apakah Anda ingin membatalkan semua Pesanan Market Berhenti?',
  'order.open_order.empty_open_order': 'Tanggal',
  'order.open_order.filled': 'Diisi',
  'order.open_order.hide_other_pairs': 'Sembunyikan Pasangan Lainnya',
  'order.open_order.hide_small_assets': 'Sembunyikan Saldo 0',
  'order.open_order.infomation': 'Informasi',
  'order.open_order.limit_order': 'Pesanan Limit',
  'order.open_order.market': 'Market',
  'order.open_order.open_order': 'Buka Pesanan',
  'order.open_order.pair': 'Pasangan',
  'order.open_order.price': 'Harga',
  'order.open_order.side': 'Sisi',
  'order.open_order.exec_type': 'Jenis Exec',
  'order.open_order.stop_limit_order': 'Stop_Limit Order',
  'order.open_order.total': 'Total',
  'order.open_order.trigger_conditions': 'Kondisi Pemicu',
  'order.open_order.type': 'Jenis',
  'order.open_order.withdrawal': 'Penarikan',
  'order.open_order.withdrawal_limit_24': '24 Batas Penarikan',
  'order.order_book.order_book': 'Order Book',
  'order.order_book.amount': 'Jumlah',
  'order.order_book.decimals': 'Desimal',
  'order.order_book.price': 'Harga',
  'order.order_book.total': 'Total',
  'order.order_form.amount': 'Jumlah',
  'order.order_form.balance': '{param} Saldo',
  'order.order_form.all': 'Semua',
  'order.order_form.buy': 'Beli',
  'order.order_form.limit': 'Limit',
  'order.order_form.market': 'Market',
  'order.order_form.stop_limit': 'Stop Limit',
  'order.order_form.stop_market': 'Stop Market',
  'order.order_form.limited_price': 'Limit',
  'order.order_form.login': 'Login',
  'order.order_form.or': 'atau',
  'order.order_form.price': 'Harga',
  'order.order_form.register': 'Daftar',
  'order.order_form.sell': 'Jual',
  'order.order_form.stop': 'Berhenti',
  'order.order_form.stop_limit_tooltip':
    'Pesanan Stop-Limit adalah pesanan untuk membeli atau menjual koin dengan harga tertentu begitu harga mencapai harga pemicu yang ditentukan.',
  'order.order_form.stop_market_tooltip':
    'Pesanan Stop-Market adalah pesanan untuk membeli atau menjual koin dengan harga market begitu harga mencapai harga pemicu yang ditentukan.',
  'order.order_form.success': '{trade_type} pesanan berhasil dibuat!',
  'order.order_form.total': 'Total',
  'order.order_form.trade': 'untuk berdagang.',
  'order.order_form.view_more': 'Lihat lebih banyak...',
  'order.order_history.action': 'Tindakan',
  'order.order_history.no_data': 'Anda tidak memiliki data',
  'order.order_history.all': 'Semua',
  'order.order_history.amount': 'Jumlah',
  'order.order_history.average': 'Rata-rata',
  'order.order_history.buy': 'Beli',
  'order.order_history.cancel': 'Membatalkan',
  'order.order_history.cancel_all': 'Batalkan Semua',
  'order.order_history.coin': 'Koin',
  'order.order_history.date': 'Tanggal',
  'order.order_history.from': 'Dari',
  'order.order_history.to': 'Untuk',
  'order.order_history.deposit': 'Setoran',
  'order.order_history.empty_order_history': 'Anda tidak memiliki data',
  'order.order_history.export_complete_order_history': 'Ekspor Riwayat Pesanan',
  'order.order_history.export_complete_order_history_tooltip':
    'Hanya Ekspor Riwayat Pesanan Lengkap pada 6 bulan',
  'order.order_history.export_complete_trade_history': 'Unduh Riwayat Perdagangan',
  'order.order_history.export_complete_trade_history_tooltip':
    'Hanya Ekspor Riwayat Perdagangan Lengkap pada 6 bulan',
  'order.order_history.fee': 'Biaya',
  'order.order_history.tax': 'Pajak',
  'order.order_history.filled': 'Diisi',
  'order.order_history.hide_all_canceled': 'Sembunyikan Semua Dibatalkan',
  'order.order_history.infomation': 'Informasi',
  'order.order_history.limit_order': 'Pesanan Limit',
  'order.order_history.market': 'Market',
  'order.order_history.order_history': 'Riwayat Pesanan',
  'order.order_history.pair': 'Pasangan',
  'order.order_history.price': 'Harga',
  'order.order_history.reset': 'Atur ulang',
  'order.order_history.search': 'Pencarian',
  'order.order_history.sell': 'Jual',
  'order.order_history.side': 'Sisi',
  'order.order_history.status': 'Status',
  'order.order_history.stop_limit_order': 'Stop_Limit Order',
  'order.order_history.total': 'Total',
  'order.order_history.trigger_conditions': 'Kondisi Pemicu',
  'order.order_history.type': 'Jenis',
  'order.order_history.withdrawal': 'Penarikan',
  'order.order_history.withdrawal_limit_24': '24 Batas Penarikan',
  'order.order_trade_form.order': 'Pesanan',
  'order.order_trade_form.trades': 'Perdagangan',
  'order.order_trade_form.price': 'Harga',
  'order.order_trade_form.size': 'Besaran',
  'order.order_trade_form.time': 'Waktu',
  'order.recent_trades.market': 'Market',
  'order.recent_trades.recent_trades': 'Perdagangan Terbaru',
  'order.recent_trades.yours': 'Milikmu',
  'order.trade_history.action': 'Tindakan',
  'order.trade_history.no_data': 'Anda tidak memiliki data',
  'order.trade_history.all': 'Semua',
  'order.trade_history.amount': 'Jumlah',
  'order.trade_history.average': 'Rata-rata',
  'order.trade_history.buy': 'Beli',
  'order.trade_history.cancel': 'Membatalkan',
  'order.trade_history.cancel_all': 'Batalkan Semua',
  'order.trade_history.coin': 'Koin',
  'order.trade_history.date': 'Tanggal',
  'order.trade_history.deposit': 'Setoran',
  'order.trade_history.export_complete_trade_history': 'Ekspor Riwayat Perdagangan Lengkap',
  'order.trade_history.fee': 'Biaya',
  'order.trade_history.filled': 'Diisi',
  'order.trade_history.hide_all_canceled': 'Sembunyikan Semua Dibatalkan',
  'order.trade_history.infomation': 'Informasi',
  'order.trade_history.limit_order': 'Pesanan Limit',
  'order.trade_history.market': 'Market',
  'order.trade_history.my_history': 'Riwayat pesanan saya',
  'order.trade_history.pair': 'Pasangan',
  'order.trade_history.price': 'Harga',
  'order.trade_history.reset': 'Atur ulang',
  'order.trade_history.search': 'Pencarian',
  'order.trade_history.sell': 'Jual',
  'order.trade_history.side': 'Sisi',
  'order.trade_history.status': 'Status',
  'order.trade_history.stop_limit_order': 'Stop_Limit Order',
  'order.trade_history.total': 'Total',
  'order.trade_history.trade_history': 'Riwayat Perdagangan',
  'order.trade_history.trigger_conditions': 'Kondisi Pemicu',
  'order.trade_history.type': 'Jenis',
  'order.trade_history.withdrawal': 'Penarikan',
  'order.trade_history.withdrawal_limit_24': '24 Batas Penarikan',
  trade: {
    exec_type_sell: 'Perdagangan',
    exec_type_trading: 'Perdagangan',
    exec_type_buy: 'Perdagangan',
  },
  circuit_breaker: {
    blocking: 'Pasangan koin perdagangan menghalangi !',
    unblocking: 'Pasangan koin perdagangan membuka blokir !',
    remain_second_text:
      'Perdagangan diblokir oleh Circuit Breaker. Buka kunci dalam {minutes} menit.',
  },
  beta_tester: {
    header: 'Peserta untuk tester versi beta',
    content:
      "Versi ini adalah versi beta. <br>Pengguna yang ingin menggunakan versi beta harus memeriksa hal berikut dan centang kotak centang untuk mendaftar ke administrator.<br>* Karena ini adalah versi beta, beberapa fungsi mungkin rusak. Jika Anda menemukan bug, harap laporkan segera ke administrator.<br><a href='mailto:kim@Demo.com'>kim@s0tatek.com</a>",
    dont_show_again: 'Jangan tampilkan lagi',
    ignore_tester: 'Abaikan Penguji',
    agree_term: 'Setuju dengan syarat & ketentuan kami!',
    ok: 'BAIK',
    or: 'Atau',
    cancel: 'Membatalkan',
    validate: {
      agree_term: 'Harap setujui syarat dan ketentuan',
    },
    register_success: 'Daftarkan Beta Tester Sukses',
    register_fail: 'Daftarkan Beta Tester Gagal',
    disable_by_beta_tester: 'Harap menjadi Beta Tester untuk berdagang',
    wait_admin_verify: {
      header: 'Tunggu Konfirmasi Admin',
      content: 'Harap tunggu admin mengonfirmasi Permintaan Penguji Beta Daftar Anda.',
    },
  },
  order_mobile: {
    charts: 'Bagan',
    group: 'Kelompok',
    max_amount: 'Jumlah Maks',
    open_order: 'Buka Pesanan',
    total: 'Total',
    trade: 'Perdagangan',
  },
  google_recaptcha: {
    errors: 'Bidang google recaptcha diperlukan.',
  },
  server: {
    errors: 'Daftar lagi setelah 60 detik',
    logs: 'Terlalu banyak permintaan dalam 60 detik',
  },
  passwords: {
    password: 'Kata sandi harus minimal enam karakter dan cocok dengan konfirmasi.',
    reset: 'Kata sandi Anda telah diatur ulang, sekarang Anda dapat masuk.',
    sent: 'Kami telah mengirim email tautan reset kata sandi Anda!',
    token: 'Token pengaturan ulang kata sandi ini tidak valid.',
    user: 'Kami tidak dapat menemukan pengguna dengan alamat email itu.',
  },
  refferal: {
    alert: 'Anda belum masuk, silakan masuk dan berbagi dengan teman-teman Anda.',
    alert_bank_account_content:
      'Silakan kirim < gaya bentang = "warna: # f29600;" > KYC < / bentang > sebelum memverifikasi akun NI Bank. Kami akan menggunakan informasi KYC Anda untuk memverifikasi rekening bank',
    confirm_bank_account_content:
      'Saya belum memiliki akun NI Bank, tolong bantu saya untuk membuatnya.',
    confirm_bank_account_title:
      'Apakah Anda ingin memverifikasi akun NI Bank untuk penarikan USD menggunakan informasi KYC?',
    get_rewards: 'Dapatkan Hadiah',
    login: 'Login',
    not_on_site: 'Belum di {APP_NAME}?',
    register: 'Daftar',
    copy_link: 'Salin Tautan',
    text1: 'Daftarkan dan hasilkan tautan rujukan dan kode QR',
    text10: 'Teman Rujukan',
    text11: 'Tanggal',
    text12: 'Detail Program',
    text13: 'Efektif pada {effectTime} ( UTC )',
    text14:
      'Komisi yang Anda terima dari program rujukan akan tergantung pada biaya perdagangan wasit. <br /> Saat ini, tingkat pembayaran Maksimum {rate}% biaya perdagangan wasit. <br /> Kami mendukung rujukan multi-level.',
    text15:
      'Komisi biaya akan dikirim langsung secara real-time ke akun {APP_NAME} Anda saat wasit menyelesaikan setiap perdagangan dan akan dibayarkan kepada Anda dalam token apa pun/digital asset biaya asli dibayarkan.',
    text16:
      'Tidak ada batasan jumlah teman yang dapat Anda rujuk, meskipun kami berhak untuk menyesuaikan atau mengubah aturan program rujukan kapan saja.',
    text18: 'Setiap wasit harus mendaftar melalui Tautan Rujukan, Kode QR, atau ID Rujukan Anda.',
    text19:
      '{APP_NAME} akan memeriksa duplikat atau akun palsu dan tidak akan membayar bonus rujukan pada akun ini. Duplikat atau keuangan bersama akan mengakibatkan diskualifikasi.',
    text2: 'Undang Teman',
    text20: '* Pemberitahuan Penting',
    text21:
      '{APP_NAME} berhak untuk mengubah ketentuan program rujukan kapan saja karena kondisi market yang berubah, risiko penipuan, atau faktor lain yang kami anggap relevan.',
    text22: 'Komisi',
    text23: 'Perkiraan Nilai Komisi <br /> Spot Exchange',
    text24: 'Riwayat Komisi Terbaru',
    text25: 'Disalin dengan sukses!',
    text26: 'Ekspor',
    text27: 'Tingkat Komisi',
    text3:
      'Undang teman Anda untuk mendaftar melalui tautan rujukan atau kode QR dan dapatkan hadiah begitu mereka menyelesaikan perdagangan',
    text4: 'Dibayar',
    text5: 'Terima komisi hingga {percent}% secara real-time',
    text6: 'ID Rujukan Saya:',
    text7: 'Tingkat Komisi Anda:',
    text8: 'Tautan Rujukan:',
    text9: 'Bagikan:',
    title: 'Program Rujukan',
    title1: 'Dapatkan Tautan Anda',
    top: 'TIDAK.',
  },
  fee_tier: {
    spot_ex: 'Pertukaran Spot',
    title: 'Program Tingkat Biaya',
    guide: {
      step_1_title: 'Perdagangan',
      step_1_description: 'Lakukan perdagangan spot',
      step_2_title: 'Tingkatkan Volume Harian Rata-Rata',
      step_2_description:
        'Setiap kali Pesanan Pengguna dicocokkan, tambah Volume Harian Rata-Rata ( ADV ) dengan nilai Pesanan itu',
      step_3_title: 'Tingkatkan Tingkat',
      step_3_description:
        'Pada awal setiap periode, sistem akan memperbarui Tingkat Pengguna baru untuk periode ini berdasarkan Volume Harian Rata-rata periode program sebelumnya',
      step_4_title: 'Terapkan Biaya Perdagangan Tier',
      step_4_description:
        'Terapkan Biaya Perdagangan baru untuk periode ini berdasarkan Tingkat Pengguna yang dihitung',
    },
    overview: {
      current_volume_trading:
        'Saat ini {type} Volume Harian Rata-Rata Untuk memperkirakan Tingkat Periode Berikutnya',
      current_tier: 'Saat ini {type} Tingkat ( Berdasarkan Periode Terakhir ADV )',
      estimate_tier: 'Diperkirakan {type} Tingkat Untuk Periode Berikutnya',
      amount_of_volume: 'ADV Untuk Mencapai Berikutnya {type} Tingkat Untuk Periode Berikutnya',
      period_time_left: 'Waktu Periode Program Kiri ( Pembaruan setiap hari )',
      special_bonus:
        'Diskon Biaya Bonus Daftar Khusus ( Diskon dari Biaya Perdagangan Anda saat ini )',
    },
    volume_history: {
      title: 'Riwayat Volume Harian',
      date: 'Tanggal ',
      period: 'Hari Periode ',
      volume: 'Volume harian ',
      estimates_tier: 'Estimasi Tier ',
      nodata: 'Anda tidak memiliki data',
    },
    trade_history: {
      title: 'Riwayat Perdagangan',
      date: 'Tanggal ',
      symbol: 'Simbol ',
      side: 'Sisi ',
      quantity: 'Jumlah ',
      fee: 'Biaya ',
      nodata: 'Anda tidak memiliki data',
    },
    export: 'Ekspor',
    last_period_program_detail: 'Rincian Program Periode Terakhir',
    current_period_program_detail: 'Rincian Program Periode Saat Ini',
    notice: '* Pemberitahuan Penting',
    detail: {
      tier: 'Tingkat',
      required_adv: 'Diperlukan ADV',
      taker_fee: 'Biaya Taker',
      maker_fee: 'Biaya Pembuat',
      nodata: 'Tidak ada data',
    },
  },
  'fee_tier.notice.text_1':
    'Exchange berhak untuk mengubah ketentuan Program Tingkat Biaya kapan saja karena kondisi pasar yang berubah, risiko penipuan, atau faktor lain yang kami anggap relevan.',
  'fee_tier.last_period_program_detail.text_1':
    'Biaya perdagangan Anda untuk Periode saat ini ( Sorot ) akan didasarkan pada Tingkat Volume Harian Rata-rata dari Periode Program terakhir sebagai informasi di bawah ini',
  'fee_tier.current_period_program_detail.text_1':
    'Biaya perdagangan Anda untuk Periode berikutnya akan didasarkan pada Rata-Rata Volume Harian Anda Tingkat Periode Program Saat Ini ( Estimasi tingkat untuk periode berikutnya dihitung setiap hari pada Riwayat Volume Harian di atas )',
  reset_passsword_complete: 'Reset Kata Sandi Lengkap',
  reset_password: {
    resetted_password: 'Kata sandi Anda telah diatur ulang, sekarang Anda dapat masuk.',
    send_mail_title: 'Kirim Kata Sandi Atur Ulang Email',
    send_mail_title_2: 'Email reset kata sandi telah dikirim',
    sent_mail:
      '< gaya kuat = "line-height: 25px; color: # 333; font-size: 16px; font-weight: 400" > Email reset kata sandi telah dikirim ke alamat email terdaftar Anda. </kuat > <br /> Harap ikuti instruksi di email dan setel ulang kata sandi Anda dalam waktu 24 jam setelah menerima email.',
    sent_mail_2:
      'Silakan akses URL dan setel ulang kata sandi Anda dalam waktu 24 jam setelah menerima email',
    sub_title_1: 'Silakan masukkan email Anda',
    sub_title_2: 'Masukkan kata sandi baru Anda',
    submit_btn: 'Kirim',
    title: 'Atur Ulang Kata Sandi',
    title_forgot: 'Lupa kata sandi',
    back_to: 'Kembali ke',
    forgot_content: 'Harap masukkan alamat email yang Anda ingin reset kata sandinya',
    reset_content: 'Masukkan kata sandi baru di bawah ini untuk mengubah kata sandi Anda',
  },
  policy: {
    last_revised: 'Terakhir direvisi: 21 August 2023',
    content_general:
      "Selamat datang di <a href='{APP_URL}'>{APP_HOST}</a>, Platform pertukaran crypto terkemuka yang beroperasi di Indonesia. Kami sangat menghargai kepercayaan Anda sebagai pengguna, dan kami berkomitmen untuk melindungi privasi dan informasi pribadi Anda. Kebijakan privasi ini menjelaskan bagaimana kami mengumpulkan, menggunakan, dan melindungi informasi yang Anda berikan kepada kami saat menggunakan Layanan kami.",
    title_1: '1. Pengumpulan Informasi Pribadi',
    content_1:
      '<p> Kami dapat mengumpulkan informasi pribadi Anda saat Anda mendaftar, mengakses, atau menggunakan Layanan kami. Informasi pribadi yang kami kumpulkan mungkin termasuk, tetapi tidak terbatas pada: </p> <br />\n<div> Nama lengkap </div>\n<div> Alamat Email </div>\n <div> Nomor telepon </div>\n <div> Alamat Fisik </div>\n<div> Informasi verifikasi identitas (seperti kartu ID atau paspor) </div>\n<div> Informasi Rekening Bank </div> <div> Riwayat Transaksi Kripto </div>',
    title_2: '2. Penggunaan informasi',
    content_2:
      '<p> Kami menggunakan informasi yang kami kumpulkan untuk: </p> <br />\n<p> Verifikasi identitas Anda sesuai dengan persyaratan hukum Indonesia. </p>\n<p> Memroses transaksi yang Anda mulai. </p>\n<p> Mengelola dan pertahankan akun Anda di nvx.co.id. </p>\n<p> Kirim pembaruan layanan, promosi, dan informasi penting lainnya. </p>\n<p> Berikan dukungan pelanggan yang efektif. </p>\n<p> Memenuhi kewajiban dan peraturan hukum yang berlaku. </p>',
    title_3: '3. Keamanan Informasi',
    content_3:
      'Kami mengambil langkah-langkah yang diperlukan untuk melindungi informasi pribadi Anda dari akses, penggunaan, atau pengungkapan yang tidak sah. Kami menggunakan langkah-langkah keamanan teknis dan organisasi yang tepat untuk menjaga integritas dan kerahasiaan informasi Anda.',
    title_4: '4. Penyimpanan Informasi',
    content_4:
      'Informasi pribadi Anda akan disimpan selama diperlukan untuk tujuan yang diuraikan dalam Kebijakan Privasi ini, atau sebagaimana diharuskan oleh hukum yang berlaku. Setelah informasi tidak lagi diperlukan, kami akan menghapusnya dengan aman sesuai dengan kebijakan retensi data kami.',
    title_5: '5. Penyedia Layanan Pihak Ketiga',
    content_5:
      'Kami dapat menggunakan penyedia layanan pihak ketiga untuk membantu kami dalam operasi dan layanan bisnis kami. Informasi Anda dapat dibagikan dengan penyedia layanan ini hanya sejauh diperlukan untuk tujuan operasional dan kepatuhan hukum.',
    title_6: '6. Perubahan Kebijakan Privasi',
    content_6:
      'Kami dapat memperbarui kebijakan privasi ini untuk mencerminkan perubahan dalam praktik kami atau peraturan yang berlaku. Versi terbaru dari kebijakan privasi akan selalu diposting di situs web kami, dan kami akan memberi tahu Anda tentang perubahan signifikan melalui email atau pemberitahuan di situs web.',
    title_7: '7. Hak Privasi Anda',
    content_7:
      'Anda memiliki hak untuk mengakses, memperbaiki, atau menghapus informasi pribadi Anda yang kami miliki. Anda juga memiliki hak untuk meminta kami berhenti menggunakan informasi pribadi Anda untuk tujuan pemasaran langsung. Untuk mengakses atau memperbarui informasi pribadi Anda, silakan hubungi kami menggunakan detail kontak yang disediakan di bawah ini.',
    title_8: '8. Hubungi kami',
    content_8:
      '<p> Jika Anda memiliki pertanyaan, komentar, atau permintaan yang terkait dengan kebijakan privasi ini, silakan hubungi kami di: </p> <br />\n<p> Alamat email: hello@nvx.co.id </p>\n<p> Alamat fisik: Equity Tower lantai 42 Unit G, SCBD JL. Jend. Sudirman Kav 52-53 Jakarta, Indonesia 12190 PT. Aset Kripto Internasional. </p>\n<p> Semua hak dilindungi undang-undang. </p>',
  },
  service_center: {
    send_contact_subject: 'Kirim kontak ke {APP_NAME}',
  },
  shared_components: {
    google_authentication_modal: {
      lost_code: 'Kehilangan Otentikasi Google Anda?',
      otp_code_invalid: 'Kode Otentikasi Google tidak valid.',
      submit_button: 'Kirim',
      title: 'Otentikasi Google',
    },
  },
  masterdata: {
    text: 'Masterdata diubah. < / br > Silakan muat ulang halaman lagi.',
  },
  unregistered_session: {
    login: 'Login',
    text: '<span>Sesi Anda saat ini dihentikan. <br />Silakan masuk lagi!</span>',
  },
  invalid_bank_account: 'Informasi rekening bank salah. Tolong coba lagi',
  validation_field_required: 'Bidang ini tidak boleh kosong',
  validation: {
    messages: {
      _default: 'Nilai {0} tidak valid.',
      after: '{0} harus setelah {1}.',
      after2: '{0} harus setelah atau sama dengan {1}.',
      alpha_dash:
        'Bidang {0} dapat berisi karakter alfa-numerik serta garis putus-putus dan garis bawah.',
      alpha_num: 'Bidang {0} hanya dapat berisi karakter alfa-numerik.',
      alpha_spaces: 'Bidang {0} hanya dapat berisi karakter alfabet serta spasi.',
      alpha: 'Bidang {0} hanya boleh berisi karakter alfabet.',
      before: '{0} harus sebelum {1}.',
      before2: '{0} harus sebelum atau sama dengan {1}.',
      between: 'Bidang {0} harus antara {1} dan {2}.',
      confirmed: 'Konfirmasi {0} tidak cocok.',
      credit_card: 'Bidang {0} tidak valid.',
      date_between: '{0} harus antara {1} dan {2}.',
      date_format: '{0} harus dalam format {1}.',
      decimal: 'Bidang {0} harus numerik dan mungkin mengandung titik desimal {1}.',
      digits: 'Bidang {0} harus numerik dan persis mengandung digit {1}.',
      dimensions: 'Kolom {0} harus berukuran {1} piksel kali {2} piksel.',
      email: 'Kolom {0} harus berupa surel yang valid.',
      ext: 'Kolom {0} harus berupa file yang valid.',
      image: 'Kolom {0} harus berupa gambar.',
      in: 'Kolom {0} harus memiliki nilai yang valid.',
      integer: 'Kolom {0} harus berupa bilangan bulat.',
      ip: 'Kolom {0} harus berupa alamat IP yang valid.',
      length: 'Panjang kolom {0} harus {1}.',
      length2: 'Panjang kolom {0} harus antara {1} dan {2}.',
      max: 'Kolom {0} tidak boleh lebih dari {1} karakter.',
      max_value: 'Kolom {0} harus {1} atau kurang.',
      mimes: 'Kolom {0} harus memiliki tipe file yang valid.',
      min: 'Kolom {0} harus minimal {1} karakter.',
      min_value: 'Kolom {0} harus {1} atau lebih.',
      not_in: 'Kolom {0} harus memiliki nilai yang valid.',
      numeric: 'Kolom {0} hanya boleh berisi karakter numerik.',
      regex: 'Format kolom {0} tidak valid.',
      required: 'Kolom {0} wajib diisi.',
      size: 'Ukuran kolom {0} harus lebih kecil dari {1}.',
      url: 'Kolom {0} bukan URL yang valid.',
    },
    accepted: '{attribute} harus diterima.',
    active_url: '{attribute} bukan URL yang valid.',
    after: '{attribute} harus tanggal setelah {date}.',
    after_or_equal: '{attribute} harus tanggal setelah atau sama dengan {date}.',
    alpha: '{attribute} hanya boleh mengandung huruf.',
    alpha_dash: '{attribute} hanya boleh mengandung huruf, angka, dan tanda hubung.',
    alpha_num: '{attribute} hanya boleh mengandung huruf dan angka.',
    array: '{attribute} harus berupa larik.',
    attributes: {
      account_name: 'nama akun',
      account_number: 'nomor akun',
      bank_id: 'bank',
      base_price: 'Hentikan Harga',
      date_of_birth: 'ulang tahun',
      new_password: 'kata sandi baru',
      otp_recovery_code: 'kode otentikasi',
      recovery_code: 'kode pemulihan',
      password: 'kata sandi',
      quantity: 'Jumlah',
      wallet_address: 'alamat wallet',
      withdrawal_address: 'alamat penarikan',
      authentication_code: 'kode otentikasi',
    },
    before: 'Atribut {attribute} harus tanggal sebelum {date}.',
    before_or_equal: 'Atribut {attribute} harus tanggal sebelum atau sama dengan {date}.',
    between: {
      array: 'Atribut {attribute} harus memiliki antara item {min} dan {max}.',
      file: 'Atribut {attribute} harus antara {min} dan {max} kilobyte.',
      numeric: 'Atribut {attribute} harus antara {min} dan {max}.',
      string: 'Atribut {attribute} harus antara karakter {min} dan {max}.',
    },
    boolean: 'Bidang {attribute} harus benar atau salah.',
    confirmed: 'Konfirmasi {attribute} tidak cocok.',
    correct_otp: 'Kode Otentikasi Google salah.',
    custom: {
      agree_term: {
        required: 'Anda harus membaca dan menyetujui Ketentuan Penggunaan.',
      },
      'attribute-name': {
        'rule-name': 'pesan khusus',
      },
      base_price: {
        min: 'Harga berhenti harus setidaknya {min}',
        precision: 'Stop price harus memiliki ketepatan presisi {precision}',
      },
      id_back: {
        image: 'File harus berupa gambar.',
        mimes: 'File harus berupa file jenis: {values}.',
      },
      id_front: {
        image: 'File harus berupa gambar.',
        mimes: 'File harus berupa file jenis: {values}.',
      },
      id_selfie: {
        image: 'File harus berupa gambar.',
        mimes: 'File harus berupa file jenis: {values}.',
      },
      image: 'File harus berupa gambar.',
      password: {
        confirmed: 'Kata sandi harus cocok dengan prompt kata sandi.',
        correct_password: 'Kata sandi salah',
        min: 'Kata sandi harus mengandung setidaknya 8 karakter, huruf kapital, huruf kecil, angka, tidak ada spasi dan harus cocok dengan kotak kata sandi duplikat..',
        regex:
          'Kata sandi harus mengandung setidaknya 8 karakter, huruf kapital, huruf kecil, angka, tidak ada spasi dan harus cocok dengan kotak kata sandi duplikat..',
      },
      new_password: {
        min: 'Kata sandi harus mengandung setidaknya 8 karakter, huruf kapital, huruf kecil, angka, tidak ada spasi dan harus cocok dengan kotak kata sandi duplikat..',
        regex:
          'Kata sandi harus mengandung setidaknya 8 karakter, huruf kapital, huruf kecil, angka, tidak ada spasi dan harus cocok dengan kotak kata sandi duplikat..',
        new_password_confirm:
          'Kata sandi harus mengandung setidaknya 8 karakter, huruf kapital, huruf kecil, angka, tidak ada spasi dan harus cocok dengan kotak kata sandi duplikat..',
      },
      old_password: {
        old_password_confirm: 'Kolom kata sandi lama diperlukan.',
      },
      referral_id: 'ID rujukan tidak ada',
      price: {
        min: 'Harga harus setidaknya {min}',
        precision: 'Harga harus memiliki ketepatan presisi {precision}',
      },
      quantity: {
        min: 'Jumlah harus setidaknya {min}',
        precision: 'Jumlah harus memiliki ketepatan presisi {precision}',
      },
      total: {
        min: 'Jumlah minimum transaksi setidaknya {min}',
        precision: 'Total harus memiliki presisi {precision}',
      },
    },
    date: 'Atribut {attribute} bukan tanggal yang valid.',
    date_format: 'Atribut {attribute} tidak cocok dengan format {attribute}.',
    different: 'Atribut {attribute} dan {other} harus berbeda.',
    digits: 'Atribut {attribute} harus {digit}.',
    digits_between: 'Atribut {attribute} harus antara {min} dan {max} digit.',
    dimensions: 'Atribut {attribute} memiliki dimensi gambar yang tidak valid.',
    distinct: 'Bidang {attribute} memiliki nilai duplikat.',
    email: 'Bidang {attribute} harus berupa email yang valid.',
    exists: 'Atribut {attribute} tidak valid.',
    file: 'Atribut {attribute} harus berupa file.',
    filled: 'Bidang {attribute} harus memiliki nilai.',
    image: 'Atribut {attribute} harus berupa gambar.',
    in: 'Atribut {attribute} tidak valid.',
    in_array: 'Bidang {attribute} tidak ada di {other}.',
    integer: 'Atribut {attribute} harus berupa bilangan bulat.',
    ip: 'Atribut {attribute} harus berupa alamat IP yang valid.',
    ipv4: 'Atribut {attribute} harus berupa alamat IPv4 yang valid.',
    ipv6: 'Atribut {attribute} harus berupa alamat IPv6 yang valid.',
    is_withdrawal_address: 'Atribut {attribute} tidak valid.',
    json: 'Atribut {attribute} harus berupa string JSON yang valid.',
    max: {
      array: 'Atribut {attribute} mungkin tidak memiliki lebih dari item {max}.',
      file: 'Atribut {attribute} mungkin tidak lebih besar dari {max} kilobyte.',
      numeric: 'Atribut {attribute} mungkin tidak lebih besar dari {max}.',
      string: 'Atribut {attribute} mungkin tidak lebih besar dari karakter {max}.',
    },
    mimes: 'Atribut {attribute} harus berupa file tipe: {values}.',
    mimetypes: 'Atribut {attribute} harus berupa file tipe: {values}.',
    min: {
      array: 'Atribut {attribute} harus memiliki setidaknya item {min}.',
      file: 'Atribut {attribute} harus setidaknya {min} kilobyte.',
      numeric: 'Atribut {attribute} harus setidaknya {min}.',
      string: 'Atribut {attribute} harus setidaknya {min} karakter.',
    },
    min_value: '{attribute} harus setidaknya {min}.',
    not_in: 'Atribut {attribute} tidak valid.',
    numeric: 'Atribut {attribute} harus berupa angka.',
    otp: 'Otentikasi gagal.',
    otp_not_used: 'Harap tunggu kode verifikasi berikutnya untuk menghasilkan.',
    otp_or_google_auth_required: 'Anda harus memasukkan Otentikasi Google Code',
    password_white_space:
      'Kata sandi harus mengandung setidaknya 8 karakter, huruf kapital, huruf kecil, angka, tidak ada spasi dan harus cocok dengan kotak kata sandi duplikat.',
    attribute_white_space:
      'Kata sandi harus mengandung setidaknya 8 karakter, huruf kapital, huruf kecil, angka, tidak ada spasi dan harus cocok dengan kotak kata sandi duplikat.',
    precision: '{attribute} harus memiliki presisi {precision}',
    present: 'Bidang {attribute} harus ada.',
    regex: 'Format {attribute} tidak valid.',
    required: 'Bidang {attribute} diperlukan.',
    required_if: 'Bidang {attribute} diperlukan ketika {other} adalah {values}.',
    required_unless: 'Bidang {attribute} diperlukan kecuali {other} dalam nilai {other}.',
    required_with: 'Bidang {attribute} diperlukan ketika nilai {values} hadir.',
    required_with_all: 'Bidang {attribute} diperlukan ketika nilai {values} hadir.',
    required_without: 'Bidang {attribute} diperlukan ketika nilai {values} tidak ada.',
    required_without_all: 'Bidang {attribute} diperlukan ketika tidak ada nilai {values} yang ada.',
    same: 'Atribut {attribute} dan {other} harus cocok.',
    size: {
      array: 'Atribut {attribute} harus mengandung item {size}.',
      file: 'Atribut {attribute} harus {size} kilobyte.',
      numeric: 'Atribut {attribute} harus {size}.',
      string: 'Atribut {attribute} harus {size} karakter.',
    },
    string: 'Atribut {attribute} harus berupa string.',
    timezone: 'Atribut {attribute} harus merupakan zona yang valid.',
    unique: 'Atribut {attribute} telah diambil.',
    unique_email: 'Email sudah diambil.',
    user_id_coin_wallet: 'Alamat sudah ada.',
    user_id_coin_wallet_2: 'Alamat sudah ada.',
    unique_referrer_code: 'Kode rujukan tidak valid.',
    unique_withdrawal_address_in_user: 'Alamat penarikan sudah ada.',
    uploaded: 'Atribut {attribute} gagal diunggah.',
    url: 'Format {attribute} tidak valid.',
    auth: {
      email_taken: 'Email sudah diambil.',
      phone_no_format: 'Nomor telepon tidak valid.',
      email_required: 'Bidang email harus diisi.',
      number_required: 'Nomor ponsel harus diisi.',
      password_required: 'Bidang kata sandi harus diisi.',
      captcha_required: 'Silakan masukkan captcha.',
      terms_required: 'Persetujuan syarat dan ketentuan harus diisi.',
      email_format: 'Bidang email harus berupa email yang valid.',
      email_inactive: 'Silakan verifikasi email Anda untuk mengaktifkan akun.',
      email_inactive_admin: 'Akun Anda tidak aktif. Harap hubungi admin.',
      email_existed: 'Email sudah digunakan.',
      password_format:
        'Kata sandi harus terdiri dari setidaknya 8 karakter, huruf kapital, huruf kecil, angka, tanpa spasi, dan harus sesuai dengan kotak kata sandi yang sama.',
      password_max: 'Bidang kata sandi tidak boleh lebih dari 72 karakter.',
      password_have_space:
        'Kata sandi harus terdiri dari setidaknya 8 karakter, huruf kapital, huruf kecil, angka, tanpa spasi, dan harus sesuai dengan kotak kata sandi yang sama.',
      password_confirmed_not_match: 'Konfirmasi kata sandi tidak cocok.',
      invalid_referral: 'ID referral tidak valid.',
      captcha_expired: 'Verifikasi kedaluwarsa. Periksa kotak centang lagi.',
      account_incorrect: 'Kredensial pengguna tidak benar.',
      login_new_device:
        'Tampaknya Anda masuk dari perangkat yang tidak kami kenal atau perangkat yang tidak Anda gunakan dalam waktu yang lama. Untuk keamanan Anda, email konfirmasi telah dikirim ke alamat email Anda.',
      ggauth_required: 'Kolom kode Autentikasi Google wajib diisi',
      ggauth_incorrect: 'Kode Otentikasi Google salah.',
      ggauth_length: 'Kode Otentikasi Google harus 6 digit.',
      fp_email_not_found: 'Kami tidak dapat menemukan pengguna dengan alamat email itu.',
      password_confirm_required: 'Kolom konfirmasi kata sandi wajib diisi.',
      new_password_required: 'Bidang kata sandi baru diperlukan.',
      password_new_max: 'Bidang kata sandi baru mungkin tidak lebih dari 72 karakter.',
      password_new_required: 'Bidang kata sandi baru diperlukan.',
      otp_required: 'Kolom kode Autentikasi Google wajib diisi',
      password_old_required: 'Kolom kata sandi lama diperlukan.',
      password_same: 'Kata sandi baru harus berbeda dari kata sandi Anda sebelumnya.',
      gg_auth_required: 'Kolom kode Autentikasi Google wajib diisi',
      ggauth_max: 'Kode Otentikasi Google harus 6 digit.',
      otp_not_used: 'Harap tunggu kode verifikasi berikutnya untuk menghasilkan.',
      password_incorrect: 'Kata sandi salah.',
      gg_code_required: 'Kolom kode Autentikasi Google wajib diisi',
      gg_code_incorrect: 'Kode Otentikasi Google salah.',
      gg_code_length: 'Kode Otentikasi Google harus 6 digit.',
      gg_code_not_used: 'Harap tunggu kode verifikasi berikutnya untuk menghasilkan.',
      phone_taken: 'Nomor telepon ini sudah diambil.',
      phone_existed: 'Nomor telepon ini sudah diambil.',
      phone_inactive: 'Harap aktifkan nomor telepon Anda',
    },
    otp: {
      gg_code_incorrect: 'Kode Otentikasi Google salah.',
      gg_code_not_used: 'Harap tunggu kode verifikasi berikutnya untuk menghasilkan.',
    },
    verified_email: 'Silakan verifikasi email Anda untuk mengaktifkan akun.',
    verify_otp_or_google_auth: 'Anda perlu memasukkan dengan benar kolom Kode Autentikasi Google!',
    verify_otp_recovery_code: '{attribute} salah.',
    verify_otp_recovery_code_with_auth: '{attribute} salah.',
    authen_code_required: 'Kolom kode Autentikasi Google wajib diisi',
    authen_code_length: 'Kode Autentikasi Google harus terdiri dari 6 digit.',
    otp_incorrect: 'Kode Otentikasi Google salah.',
    identity: {
      success: 'Pembaruan berhasil.',
      update_success: 'Pembaruan berhasil.',
      address: 'Kontak Alamat',
      postal_code: 'kode Pos',
      take_a_video_and_note: 'Ambil video dan perhatikan',
      address_placeholder: 'Nama jalan, nomor rumah, RT / RW',
      take_selfie_photo: 'Ambil foto selfie',
      retake_selfie_photo: 'Ambil ulang foto selfie',
      liveness_checking: 'Pemeriksaan Liveness',
      retake_video: 'Video kembali',
      retake: 'Merebut kembali',
      video_note:
        'Silakan kirim video dengan instruksi berikut;\n<ul>\n<li> Balikkan kepala Anda ke arah kiri </li>\n<li> Balikkan kepala Anda ke kanan </li>\n<li> Lihatlah </li> <li> Lihat ke bawah </li>\n</ul>',
      save: 'Simpan',
      errors: {
        under_17: 'Harus berusia setidaknya 17 tahun',
      },
    },
    is_contract_address: 'Alamat kontrak tidak valid.',
    withdrawal: {
      otp_incorrect: 'Kode Otentikasi Google salah.',
      otp_not_used: 'Harap tunggu kode verifikasi berikutnya untuk menghasilkan.',
      otp_required: 'Kolom kode Autentikasi Google wajib diisi',
      otp_length: 'Kode Otentikasi Google harus 6 digit.',
      otp_max: 'Kode Otentikasi Google harus 6 digit.',
    },
    address: {
      coin_required: 'Bidang koin diperlukan.',
      name_required: 'Kolom label diperlukan.',
      name_length: 'Label tidak boleh melebihi 20 karakter.',
      address_required: 'Bidang alamat diperlukan.',
      withdraw_address_invalid: 'Alamat penarikan tidak valid.',
      address_existed: 'Alamat sudah ada.',
      white_list_off: 'Harap aktifkan fungsi daftar putih penarikan terlebih dahulu',
    },
    kyc: {
      full_name_required: 'Bidang ini tidak boleh kosong',
      id_number_required: 'Bidang ini tidak boleh kosong',
      id_front_required: 'Bidang ini tidak boleh kosong',
      id_back_required: 'Bidang ini tidak boleh kosong',
      id_selfie_required: 'Bidang ini tidak boleh kosong',
      address_required: 'Bidang ini tidak boleh kosong',
      sub_district_required: 'Bidang ini tidak boleh kosong',
      ward_required: 'Bidang ini tidak boleh kosong',
      city_required: 'Bidang ini tidak boleh kosong',
      date_of_birth_required: 'Bidang ini tidak boleh kosong',
      province_required: 'Bidang ini tidak boleh kosong',
      postal_code_required: 'Bidang ini tidak boleh kosong',
      id_number_existed: 'Nomor Identitas Kependudukan Anda telah digunakan',
      id_number_format:
        'Bidang ini hanya berisi 6 hingga 20 angka atau karakter, tidak mengandung karakter khusus.',
      id_front_format: 'File harus berupa gambar.',
      id_back_format: 'File harus berupa gambar.',
      id_selfie_format: 'File harus berupa gambar.',
      id_front_mimes: 'File harus berupa file jenis: .jpg, .jpeg, .png, .heic, .heif.',
      id_back_mimes: 'File harus berupa file jenis: .jpg, .jpeg, .png, .heic, .heif.',
      id_selfie_mimes: 'File harus berupa file jenis: .jpg, .jpeg, .png, .heic, .heif.',
      id_front_max: 'Ukuran maksimal file 4MB. Pilih yang lain',
      id_back_max: 'Ukuran maksimal file 4MB. Pilih yang lain',
      id_selfie_max: 'Ukuran maksimal file 4MB. Pilih yang lain',
      status_pending: 'Tunggu administrator untuk mengonfirmasi.',
      id_front_max: 'Ukuran maksimal file 4MB. Pilih yang lain',
      id_selfie_max: 'Ukuran maksimal file 4MB. Pilih yang lain',
      video_selfie_mimetype: 'Jenis video tidak didukung.',
    },
    credit_card: {
      card_invalid: 'Informasi kartu kredit tidak valid. Silakan coba lagi',
      expired_item_invalid: 'Informasi kartu kredit tidak valid. Silakan coba lagi',
      card_cvc_invalid: 'Informasi kartu kredit tidak valid. Silakan coba lagi',
      max_card: 'Anda tidak dapat menambah kartu baru karena jumlah maksimum kartu kredit adalah 5',
      expiry_date_invalid: 'Tanggal kedaluwarsa tidak valid',
    },
  },
  withdraw_guide: {
    currency: 'Mata Uang',
    deposit_fee: 'Biaya Deposit',
    email: 'Verifikasi Surel',
    fee: 'Biaya',
    fee_withdrawal: 'Biaya Penarikan',
    free: 'Gratis',
    gotp: 'OTP Google',
    gauthen: 'Autentikasi Google',
    partner: 'Mitra (Hubungi kami)',
    contract_admin: 'Hubungi admin',
    level: 'Langkah Istirahat',
    level_name: 'Level',
    level_title: 'Level sertifikasi saat ini adalah',
    limit_tip1: 'Batas penarikan berbeda tergantung pada level otentikasi individu.',
    limit_tip2:
      'Berdasarkan situasi pasar dan regulasi bank yang terkait, batas penarikan di atas dapat berubah tanpa pemberitahuan sebelumnya.',
    limit_tip3: 'Jika kami mencurigai transaksi penipuan, penarikan mungkin dibatasi.',
    limit_tip4:
      'Biaya penarikan akan dikenakan dengan jumlah tetap, tidak peduli berapa jumlah mata uangnya.',
    limit_tip5:
      'Dalam kasus penarikan kripto, biaya sebenarnya dapat bervariasi atau lebih tinggi atau lebih rendah dari biaya yang ditampilkan tergantung pada status jaringan blockchain.',
    limit_title1: '1. Batasan penarikan berdasarkan langkah otentikasi',
    limit_title2: '2. Biaya deposit dan penarikan serta jumlah penarikan minimum',
    minimum_withdrawal: 'Jumlah penarikan minimum',
    one_day: '1 hari',
    one_time: '1 kali',
    kyc: 'Verifikasi KYC',
    verf_doc: 'Dokumen yang diverifikasi',
    security_method: 'Metode otentikasi',
    support_withdrawal: 'Instruksi setoran dan penarikan',
    usd: 'usd',
  },
  trade_type: {
    buy: 'Beli',
    sell: 'Jual',
  },
  usd_transaction: {
    time: 'Waktu',
    user: 'Email',
    account: 'Akun',
    bank_name: 'Bank',
    bank_branch: 'Cabang',
    status: 'Status',
    amount: 'Jumlah',
  },
  user: {
    id: 'ID',
    user: 'Pengguna',
    user_information: 'Informasi pengguna',
    date: 'Tanggal',
    email: 'Email',
    fee_level: 'Tingkat Biaya',
    security_level: 'Tingkat Keamanan',
    max_security_level: 'Tingkat Keamanan Maks',
    referrer_code: 'Kode Rujukan',
    referrer_mail: 'Surat Rujukan',
    type: 'Jenis',
    status: 'Status',
    normal: 'normal',
    bot: 'bot',
    inactive: 'tidak aktif',
    active: 'aktif',
    save: 'Simpan',
    edit: 'Edit',
    device: 'Perangkat',
    recent_activity: 'Aktivitas Terbaru',
    succes: 'Diperbarui dengan sukses!',
    user_setting: 'Pengaturan Pengguna',
    referrer: 'pengarah',
    otp: 'OTP',
    login_history: 'Riwayat',
  },
  device_management: {
    title_device_management: 'Manajemen Perangkat',
  },
  notice: {
    edit_title_page: 'Perbarui Pemberitahuan',
    new_title_page: 'Buat Pemberitahuan',
    title: 'Judul',
    date: 'Tanggal',
    search: 'Pencarian',
    reset: 'Atur ulang',
    new: 'Baru',
    support: 'Dukungan Tautan',
    start: 'Dimulai',
    end: 'Berakhir',
    edit: 'Edit',
    delete: 'Menghapus',
    start_at: 'Mulai dari',
    end_at: 'Berakhir pada',
    banner: 'Spanduk',
    submit: 'Kirim',
    cancel: 'Membatalkan',
    confirm: 'Konfirmasikan',
    search_by_title: 'Cari berdasarkan judul.....',
    choose_file: 'Pilih File',
    delete_data: 'Apakah Anda ingin menghapus pemberitahuan ini?',
    submit_data: 'Apakah Anda ingin mengirimkan pemberitahuan ini?',
    noticeEdit: 'Edit pemberitahuan',
  },
  'notice.errors.required': 'Bidang ini wajib diisi.',
  'notice.errors.maxsize': 'Ukuran maksimal file 4MB. Pilih yang lain',
  'notice.errors.date': 'Tanggal akhir harus lebih dari tanggal mulai',
  orders: {
    open_order: {
      open_order: 'Buka Pesanan',
      date: 'Tanggal',
      pair: 'Pasangan',
      search_by_email: 'Cari melalui email',
      search: 'Pencarian',
      reset: 'Atur ulang',
      time: 'Waktu',
      trade_id: 'Trade Id',
      order_id: 'Memesan Id',
      email: 'Email',
      price: 'Harga',
      filled: 'Diisi',
      fee: 'Biaya',
      total: 'Total',
      type: 'Jenis',
      exec_type: 'Jenis Exec',
      action: 'Tindakan',
      amount: 'Jumlah',
      trigger_condition: 'Kondisi pemicu',
      status: 'Status',
      average: 'Rata-rata',
      side: 'Sisi',
      buy: 'Beli',
      sell: 'Jual',
      all: 'Semua',
      trade_history: 'Anda tidak memiliki data',
      index: 'Tidak.',
      view_detail: 'Lihat Detail',
      market: 'Market',
    },
    order_history: {
      export_complete_order_history: 'Ekspor Riwayat Pesanan',
      order_history: 'Riwayat Pesanan',
      date: 'Tanggal',
      pair: 'Pasangan',
      search_by_email: 'Cari melalui email',
      search: 'Pencarian',
      reset: 'Atur ulang',
      time: 'Waktu',
      trade_id: 'Trade Id',
      order_id: 'Memesan Id',
      email: 'Email',
      price: 'Harga',
      filled: 'Diisi',
      fee: 'Biaya',
      total: 'Total',
      type: 'Jenis',
      action: 'Tindakan',
      amount: 'Jumlah',
      trigger_condition: 'Kondisi pemicu',
      status: 'Status',
      average: 'Rata-rata',
      side: 'Sisi',
      buy: 'Beli',
      sell: 'Jual',
      all: 'Semua',
      hide_all_canceled: 'Sembunyikan Semua Dibatalkan',
      trade_history: 'Riwayat Perdagangan',
      index: 'Tidak.',
      view_detail: 'Detail',
      market: 'Market',
      tax: 'Pajak',
    },
    trade_history: {
      trade_history: 'Riwayat Perdagangan',
      date: 'Tanggal',
      pair: 'Pasangan',
      search_by_email: 'Cari melalui email',
      search: 'Pencarian',
      reset: 'Atur ulang',
      time: 'Waktu',
      trade_id: 'Trade Id',
      original_buy_order_id: 'Id Pesanan Beli Asli',
      buyer_email: 'Email Pembeli',
      original_sell_order_id: 'Jual Pesanan Asli',
      seller_email: 'Email penjual',
      price: 'Harga',
      filled: 'Diisi',
      buy_fee: 'Beli Biaya',
      sell_fee: 'Jual Biaya',
      total: 'Total',
      market: 'Market',
      all: 'Semua',
      tax: 'Pajak',
    },
  },
  email: {
    new_title_page: 'Pemasaran Email Buat',
    new: 'Buat Pemasaran Email',
    title: 'Judul',
    content: 'Konten',
    submit_data: 'Apakah kamu benar-benar mau ?',
    delete_data: 'Apakah Anda ingin menghapus ?',
    send_all: 'Kirim semua Pengguna',
    list_user: 'Kirim daftar Pengguna',
    errors: {
      required: 'Anda perlu mengirim semua Pengguna atau daftar pengguna',
    },
    email_title: 'Ini adalah judul',
    email_content: 'Ini adalah konten',
    fail: 'Gagal',
  },
  withdraw_fee_setting: {
    title: 'Tarik pengaturan biaya',
    daily_limit: 'Batas harian',
    disable_confirm: 'Apakah Anda ingin menonaktifkan penarikan?',
    disable_all_confirm: 'Apakah Anda ingin menonaktifkan semua penarikan?',
    enable_confirm: 'Apakah Anda ingin mengaktifkan penarikan?',
    enable_all_confirm: 'Apakah Anda ingin mengaktifkan penarikan?',
    disable_deposit_confirm: 'Apakah Anda ingin menonaktifkan setoran?',
    disable_all_deposit_confirm: 'Apakah Anda ingin menonaktifkan semua setoran?',
    enable_deposit_confirm: 'Apakah Anda ingin mengaktifkan deposit?',
    enable_all_deposit_confirm: 'Apakah Anda ingin mengaktifkan semua setoran?',
    update_success_msg: 'Diperbarui dengan sukses!',
    disable_success_msg: 'Dinonaktifkan dengan sukses!',
    disable_all_success_msg: 'Dinonaktifkan semua berhasil!',
    enable_success_msg: 'Diaktifkan dengan sukses!',
    enable_all_success_msg: 'Diaktifkan semuanya dengan sukses!',
    withdraw_setting: 'Pengaturan Penarikan',
    id: 'ID',
    currency: 'Mata uang',
    limit: 'Batasi',
    fee: 'Biaya',
    fee_open_close: 'Biaya Terbuka / Tutup',
    is_visible: 'Terlihat',
    minium_withdrawal: 'Penarikan Minium',
  },
  withdraw_setting: {
    change_confirm: 'Apakah Anda ingin mengubah biaya penarikan?',
    disable_all: 'Nonaktifkan semua',
    enable_all: 'Aktifkan semua',
    enable: 'Memungkinkan',
    disable: 'Nonaktifkan',
    id: 'ID',
    enable_confirm: 'Apakah Anda ingin mengaktifkan penarikan?',
    enable_all_confirm: 'Apakah Anda ingin mengaktifkan penarikan?',
    disable_deposit_confirm: 'Apakah Anda ingin menonaktifkan setoran?',
    disable_all_deposit_confirm: 'Apakah Anda ingin menonaktifkan semua setoran?',
    enable_deposit_confirm: 'Apakah Anda ingin mengaktifkan deposit?',
    enable_all_deposit_confirm: 'Apakah Anda ingin mengaktifkan semua setoran?',
    name: 'Nama',
    deposit: 'Setoran',
    withdraw: 'Menarik',
    update_success_msg: 'Diperbarui dengan sukses!',
    disable_success_msg: 'Dinonaktifkan dengan sukses!',
    disable_all_success_msg: 'Dinonaktifkan semua berhasil!',
    enable_success_msg: 'Diaktifkan dengan sukses!',
    enable_all_success_msg: 'Diaktifkan semuanya dengan sukses!',
    withdraw_setting: 'Pengaturan undian',
    disable_confirm: 'Apakah Anda ingin menonaktifkan penarikan?',
    disable_all_confirm: 'Apakah Anda ingin menonaktifkan semua penarikan?',
  },
  salespoint: {
    amal: '4IRE',
    balances: 'Saldo',
    bonus: 'Bonus',
    buy: 'Beli',
    buy_fail: 'Pesanan Anda gagal!',
    buy_history: 'Riwayat Beli',
    enter_amount: 'Masukkan jumlah Anda',
    export_complete_buy_history: 'Ekspor Lengkap 4IRE History',
    export_complete_buy_history_tooltip: 'Hanya Ekspor Lengkap Riwayat Beli pada 6 bulan',
    login_or_register:
      '<a href="{login}" > Login </a> atau <a href="{register}">Daftar</a> untuk berdagang',
    total_remaining_supply: 'Total 4IRE sisa pasokan',
    buy_success: 'Pesanan Anda telah selesai!',
  },
  des: {
    des_spot_leaderboard:
      'Peringkat volume perdagangan leaderboard hanya menampilkan data dalam rentang tanggal dari {start} hingga {end}',
    des_margin_leaderboard:
      '- Berikut ini menunjukkan beberapa pedagang paling menguntungkan di NVX Exchange. <br /> - Daftar ini bersifat anonim. Setiap nama selalu mewakili pengguna yang sama, tetapi tidak dapat diidentifikasi secara pribadi. <br /> - Peringkat volume perdagangan leaderboard hanya menampilkan data dalam rentang tanggal dari {start} hingga {end}',
  },
  title: {
    Spot_Exchange_Leaderboard: 'Spot Exchange Leaderboard',
    settlement: 'Penyelesaian',
    then: 'kemudian',
    or: 'atau',
    settlement_history_table: 'Tabel Riwayat Penyelesaian',
    des: {
      this_list_contains: 'Daftar ini berisi semua riwayat penyelesaian.',
      daily_insurance_fund_balance:
        'NVX Exchange menggunakan Dana Asuransi untuk menghindari Deleveraging Otomatis di posisi pedagang ’. Dana tersebut digunakan untuk menyerang pesanan likuidasi yang tidak terisi sebelum diambil alih oleh sistem deleveraging otomatis. <br /> Dana Asuransi tumbuh dari likuidasi yang dapat dieksekusi di pasar dengan harga yang lebih baik daripada harga kebangkrutan dari posisi tertentu. <br /> Anda dapat melihat saldo Dana Asuransi saat ini dan historis di bawah ini.',
      settlement_history_table: 'Daftar ini berisi semua riwayat penyelesaian.',
    },
    daily_insurance_fund_balance: 'Saldo Dana Asuransi Harian',
    Leaderboard: {
      title: 'Leaderboard',
      des_title:
        'Secara default, kami menampilkan akun Anda di leaderboard, tetapi namanya tidak dapat diidentifikasi. Perubahan di sini mungkin memakan waktu hingga setengah jam untuk mulai berlaku.',
    },
    overview: 'Gambaran Umum',
    interest_rate_component: 'Komponen Suku Bunga',
    premium_discount_component: 'Komponen Premium / Diskon',
    more_information: 'Informasi Lebih Lanjut',
    bitcoin_series_xbt_guide: 'Bitcoin Series ( BTC ) Panduan',
    view_btc_guide: 'Lihat panduan seri BTC',
    symbol_index_historical_values: '{symbol} Nilai Historis Indeks',
    contract_specification: 'Spesifikasi Kontrak',
    symbol_index_detail: '{symbol} Detail Indeks',
    description: 'Deskripsi',
    longs: 'lama',
    shorts: 'celana pendek',
    price_index: 'Indeks Harga',
    eth: {
      price_index: {
        desc: 'Bertentangan dengan kepercayaan populer, Lorem Ipsum bukan hanya teks acak. Ini berakar pada sepotong sastra Latin klasik dari 45 SM, membuatnya lebih dari 2000 tahun. Richard McClintock, seorang profesor Latin di Hampden-Sydney College di Virginia, mencari salah satu kata Latin yang lebih tidak jelas, consectetur, dari bagian Lorem Ipsum, dan melalui kutipan kata dalam literatur klasik. <br /> Menemukan sumber yang tidak diragukan lagi. Lorem Ipsum berasal dari bagian 1.10.32 dan 1.10.33 dari "de Finibus Bonorum et Malorum" ( Ekstrem Baik dan Jahat ) oleh Cicero, ditulis pada 45 SM. Buku ini adalah risalah tentang teori etika, sangat populer selama Renaissance. Baris pertama Lorem Ipsum, "Lorem ipsum dolor sit amet ..", berasal dari baris di bagian 1.10.32.',
      },
    },
    marking_and_settlement: 'Penandaan dan Penyelesaian',
    full_contract_details: 'Rincian Kontrak Lengkap',
  },
  leaderboard: {
    top_25_traders_by_vol: '25 Pedagang Teratas berdasarkan Volume',
    top_25_traders_by_notional: '25 Pedagang Terbaik oleh Notional',
    top_25_traders_by_roe: '25 Pedagang Terbaik oleh ROE',
    top_25_teams_by_roe_entry: '25 Tim Teratas oleh ROE ( Entri )',
    top_25_traders_by_volume: '25 Pedagang Teratas berdasarkan Volume',
    show_real_username: 'Apakah Anda ingin menunjukkan nama pengguna palsu?',
    show_fake_username: 'Apakah Anda ingin menunjukkan nama pengguna asli?',
    off_to_show_fake_username:
      'Matikan untuk menunjukkan nama pengguna Anda yang sebenarnya di leaderboard.',
    your_fake_name_is: 'Nama palsu Anda adalah',
    on_to_show_real_username: 'Aktifkan untuk menunjukkan nama pengguna palsu Anda di leaderboard.',
    top_25_traders_by_roe_entry: '25 Pedagang Terbaik oleh ROE ( Entri )',
  },
  table: {
    trading_volume: 'Volume Perdagangan',
    email: 'Email',
    timestamp: 'Stempel waktu',
    symbol: 'Simbol',
    instrument_symbol: 'Simbol Instrumen',
    exec_type: 'Jenis Exec',
    side: {
      buy: 'Beli',
      sell: 'Jual',
    },
    text: 'Teks',
    note: 'Catatan',
    order_id: 'ID pesanan',
    settlement_type: 'Jenis Penyelesaian',
    settled_price: 'Harga yang Diselesaikan',
    option_strike_price: 'Opsi Harga Mogok',
    wallet_balance: 'Saldo Wallet',
    profit: 'Keuntungan',
    volume: 'Volume',
    rofit: 'Rofit',
    rank: 'Peringkat',
    is_real_name: 'Adalah Nama Nyata',
    interest_base: 'Basis Bunga',
    interest_quote: 'Kutipan Bunga',
    premium: 'Premium',
  },
  trading_fee_setting: {
    fee_maker: 'Pembuat Biaya',
    fee_taker: 'Pengambil Biaya',
    coin_hodling: '{COIN_HOLDING} Memegang',
    msg_coin_holding: 'Pengaturan memegang koin harus ditingkatkan pada setiap level.',
    id: 'ID',
    trading_fee_setting: 'BIAYA',
    trading_feeSetting: 'Pengaturan Biaya Perdagangan',
    trading_withdrawFeeSetting: 'Pengaturan Biaya Penarikan',
    update_confirm: 'Apakah Anda ingin mengubah biaya perdagangan?',
    update_success_msg: 'Diperbarui dengan sukses!',
  },
  button: {
    done: 'Selesai',
    reset: 'Atur ulang',
    clear: 'Bersih',
    save_as_CSV: 'Simpan sebagai CSV',
  },
  user_setting: {
    coin: 'Koin',
    blockchain_address: 'Alamat Blockchain',
    balance: 'Saldo',
    available_balance: 'Saldo Wallet ',
    confirm_off_otp: 'Apakah Anda ingin menonaktifkan OTP?',
    confirm_on_otp: 'Apakah Anda ingin mengaktifkan OTP?',
    update_otp_msg: 'Perbarui pengaturan OTP dengan sukses!',
    confirm_disable_account: 'Apakah Anda ingin menonaktifkan akun?',
    disable_account_success_msg: 'Nonaktifkan akun dengan sukses?',
    confirm_enable_account: 'Apakah Anda ingin mengaktifkan akun?',
    enable_account_success_msg: 'Aktifkan akun dengan sukses?',
  },
  exchange_balance: {
    available_balance: 'Saldo Wallet ',
    exchange_balance: 'Saldo Pertukaran',
  },
  exchange_fee: {
    level: 'Tingkat',
  },
  menu_bottom: {
    withdrawals_guide: 'Panduan Penarikan',
    terms_of_service: 'Ketentuan layanan',
    api_document: 'Dokumen API',
    leverage_reference: 'Referensi Leverage',
    contact_us: 'Hubungi Kami',
    faq: 'FAQ',
    faqs: 'FAQ',
    white_paper: 'Kertas putih',
    guidelines: 'Pedoman',
    recruitment_plan: 'Rencana Perekrutan',
    support: 'Dukungan',
    cooperative_partner: 'Mitra Koperasi',
    invitation_reward: 'Hadiah Undangan',
    help_center: 'Pusat Bantuan',
    company: 'Perusahaan',
    install_app: 'Instal Aplikasi',
  },
  search: {
    placeholder: {
      click_values_below_to_fillter: 'Klik nilai di bawah ini untuk mengisi',
    },
  },
  trezor_address_for_deposit: 'Alamat Trezor untuk setoran',
  trezor_device: 'Perangkat Trezor',
  trezor_device_information: 'Informasi Perangkat Trezor',
  trezor_label: 'Label TREZOR',
  trezor_path_for_deposit: 'Jalur Trezor untuk setoran',
  trezor_wallet: 'wallet Trezor',
  trezor: {
    landing: {
      title: 'Transaksi Anda menjadi dukungan sosial dan kami terutama akan fokus pada hal ini.',
      desc: 'Sebagai perusahaan pendukung SDGs, kami menggunakan sebagian dari keuntungan untuk dukungan sosial. Dengan menggunakan NVX Exchange Anda dapat membantu membangun sekolah atau menyelamatkan kelaparan.',
      title1: 'API tingkat lanjut',
      desc1:
        'Mesin perdagangan kami dibangun menggunakan teknologi yang sama yang digunakan oleh bank investasi dan dana lindung nilai.',
    },
  },
  datatable_empty: 'Tidak ada data yang tersedia di tabel.',
  datatable_search_empty: 'Tidak ada hasil yang cocok dengan pencarian Anda.',
  wallet: {
    create_receive_address: {
      currency: 'Mata uang',
      currency_list: 'Daftar Mata Uang',
      generate_address: 'Buat Alamat',
      the_number_of_remaining: 'Jumlah yang Tersisa',
      title: 'Buat alamat yang diterima',
      total_number: 'Jumlah total',
    },
  },
  receive_address_creation: 'menerima pembuatan alamat',
  user_wallet: 'wallet pengguna',
  coin_information: {
    btn_back: 'Kembali',
    btn_choose_file: 'Pilih file',
    btn_next: 'Lanjut',
    contact_address: 'Alamat kontrak',
    decimals: 'Desimal',
    explorer: 'Penjelajah',
    heading_title: 'Daftarkan ERC20 baru',
    icon: 'Ikon',
    image_note: 'Gambar harus berupa file jenis: png',
    required_confirmations: 'Konfirmasi yang diperlukan',
    tab_title: 'Informasi Token',
    token: 'Token',
    token_name: 'Nama token',
    type: 'Jenis',
  },
  trading_information: {
    btn_back: 'Kembali',
    btn_delete: 'Menghapus',
    btn_edit: 'Edit',
    btn_next: 'Lanjut',
    btn_register_trading_pair: 'Daftarkan pasangan perdagangan',
    delete_msg: 'Sukses',
    fee: 'Biaya',
    heading_title: 'Daftarkan ERC20 baru',
    market_price: 'Harga Market',
    minimum_amount: 'Jumlah Minimum',
    minimum_total: 'Total Minimum',
    pair: 'Pasangan',
    trading_information: 'Informasi Perdagangan',
  },
  withdrawal_information: {
    back: 'Kembali',
    currency: 'Mata uang',
    day: 'hari',
    days: 'hari',
    fee: 'Biaya',
    heading_title: 'Daftarkan ERC20 baru',
    limit: 'Batasi',
    minimum_withdrawal: 'Penarikan minimum',
    next: 'Lanjut',
    tab_title: 'Informasi Penarikan',
    time_reset: 'Atur ulang waktu',
  },
  review_register_erc_2_0: {
    '*_check_register_erc20_information': '* Periksa informasi register erc20',
    amount_limit: 'Batas Jumlah',
    btn_back: 'Kembali',
    btn_register: 'mendaftar',
    contract_address: 'Alamat kontrak',
    currency: 'Mata uang',
    days: 'hari',
    decimals: 'Desimal',
    error_msg: 'Memiliki kesalahan selama pengaturan. Silakan daftar lagi!',
    explorer: 'Penjelajah',
    fee: 'Biaya',
    icon: 'Ikon',
    limit: 'Batasi',
    market_price: 'Harga Market',
    minimum_amount: 'Jumlah Minimum',
    minimum_total: 'Total Minimum',
    minimum_withdrawal: 'Penarikan Minimum',
    pair: 'Pasangan',
    precision: 'Presisi',
    register_new_erc20: 'Daftarkan ERC20 baru',
    required_confirmations: 'Konfirmasi yang diperlukan',
    time_reset: 'Atur Ulang Waktu',
    token: 'Token',
    token_information: 'Informasi Token',
    token_name: 'Nama token',
    total_limit: 'Batas Total',
    trading_fee: 'Biaya Perdagangan',
    trading_limit: 'Batas perdagangan',
    trading_pair: 'Pasangan Perdagangan',
    type: 'Jenis',
    withdrawal_information: 'Informasi Penarikan',
    success_msg: 'Daftar dengan sukses!',
  },
  erc20_loading_modal: {
    create_account_message: 'Buat Akun ....',
    create_migration_message: 'Buat Migrasi ....',
    done_message: 'Selesai ....',
    loading_message: 'Memuat....',
    set_market_price_message: 'Tetapkan Harga Market ....',
    update_masterdata_message: 'Perbarui Masterdata ....',
  },
  eth_wallet_information: 'INFORMASI wallet ETH',
  barstyle_chart: {
    save_chart: 'Simpan Bagan',
    load_chart: 'Memuat Bagan',
    bars: 'Bar',
    candles: 'Lilin',
    hollow_candles: 'Lilin Berongga',
    heikin_ashi: 'Heikin Ashi',
    line: 'Baris',
    area: 'Area',
  },
  approved_order: {
    btn_search: 'Pencarian',
  },
  modal_trading_pair_information: {
    btn_submit: 'Kirim',
  },
  _validation: {
    precision: 'Atribut {attribute} harus memiliki presisi {precision}.',
    accepted: 'Atribut {attribute} harus diterima.',
    active_url: 'Atribut {attribute} bukan URL yang valid.',
    after: 'Atribut {attribute} harus tanggal setelah {date}.',
    after_or_equal: 'Atribut {attribute} harus tanggal setelah atau sama dengan {date}.',
    alpha: 'Atribut {attribute} hanya boleh berisi huruf.',
    alpha_dash:
      'Atribut {attribute} hanya boleh berisi huruf, angka, tanda hubung, dan garis bawah.',
    alpha_num: 'Atribut {attribute} hanya boleh berisi huruf dan angka.',
    array: 'Atribut {attribute} harus berupa array.',
    before: 'Atribut {attribute} harus tanggal sebelum {date}.',
    before_or_equal: 'Atribut {attribute} harus tanggal sebelum atau sama dengan {date}.',
    between: {
      numeric: 'Atribut {attribute} harus antara {min} dan {max}.',
      file: 'Atribut {attribute} harus antara {min} dan {max} kilobyte.',
      string: 'Atribut {attribute} harus antara karakter {min} dan {max}.',
      array: 'Atribut {attribute} harus memiliki antara item {min} dan {max}.',
    },
    boolean: 'Bidang {attribute} harus benar atau salah.',
    confirmed: 'Konfirmasi {attribute} tidak cocok.',
    date: 'Atribut {attribute} bukan tanggal yang valid.',
    date_format: 'Atribut {attribute} tidak cocok dengan format {date}.',
    different: 'Atribut {attribute} dan {other} harus berbeda.',
    digits: 'Atribut {attribute} harus {digit}.',
    digits_between: 'Atribut {attribute} harus antara {min} dan {max} digit.',
    dimensions: 'Atribut {attribute} memiliki dimensi gambar yang tidak valid.',
    distinct: 'Bidang {attribute} memiliki nilai duplikat.',
    email: 'Bidang {attribute} harus berupa email yang valid.',
    exists: 'Atribut {attribute} tidak valid.',
    file: 'Atribut {attribute} harus berupa file.',
    filled: 'Bidang {attribute} harus memiliki nilai.',
    gt: {
      numeric: 'Atribut {attribute} harus lebih besar dari nilai {value}.',
      file: 'Atribut {attribute} harus lebih besar dari {value} kilobyte.',
      string: 'Atribut {attribute} harus lebih besar dari karakter {value}.',
      array: 'Atribut {attribute} harus memiliki lebih dari {values} item.',
    },
    gte: {
      numeric: 'Atribut {attribute} harus lebih besar dari atau sama dengan nilai {value}.',
      file: 'Atribut {attribute} harus lebih besar dari atau sama dengan nilai {value} kilobyte.',
      string: 'Atribut {attribute} harus lebih besar dari atau sama dengan {value} karakter.',
      array: 'Atribut {attribute} harus memiliki item {value} atau lebih.',
    },
    image: 'Atribut {attribute} harus berupa gambar.',
    in: 'Atribut {attribute} tidak valid.',
    in_array: 'Bidang {attribute} tidak ada di {other}.',
    integer: 'Atribut {attribute} harus berupa bilangan bulat.',
    ip: 'Atribut {attribute} harus berupa alamat IP yang valid.',
    ipv4: 'Atribut {attribute} harus berupa alamat IPv4 yang valid.',
    ipv6: 'Atribut {attribute} harus berupa alamat IPv6 yang valid.',
    json: 'Atribut {attribute} harus berupa string JSON yang valid.',
    lt: {
      numeric: 'Atribut {attribute} harus kurang dari nilai {value}.',
      file: 'Atribut {attribute} harus kurang dari {value} kilobyte.',
      string: 'Atribut {attribute} harus kurang dari karakter {value}.',
      array: 'Atribut {attribute} harus memiliki kurang dari {value} item.',
    },
    lte: {
      numeric: 'Atribut {attribute} harus kurang dari atau sama dengan nilai {value}.',
      file: 'Atribut {attribute} harus kurang dari atau sama dengan nilai {value} kilobyte.',
      string: 'Atribut {attribute} harus kurang dari atau sama dengan nilai {value} karakter.',
      array: 'Atribut {attribute} tidak boleh memiliki lebih dari {value} item.',
    },
    max: {
      numeric: 'Atribut {attribute} mungkin tidak lebih besar dari {max}.',
      file: 'Atribut {attribute} mungkin tidak lebih besar dari {max} kilobyte.',
      string: 'Atribut {attribute} mungkin tidak lebih besar dari karakter {max}.',
      array: 'Atribut {attribute} mungkin tidak memiliki lebih dari item {max}.',
    },
    mimes: 'Atribut {attribute} harus berupa file tipe: {values}.',
    mimetypes: 'Atribut {attribute} harus berupa file tipe: {values}.',
    min: {
      numeric: 'Atribut {attribute} harus setidaknya {min}.',
      file: 'Atribut {attribute} harus setidaknya {min} kilobyte.',
      string: 'Atribut {attribute} harus setidaknya {min} karakter.',
      array: 'Atribut {attribute} harus memiliki setidaknya item {min}.',
    },
    base_price: {
      min: 'Harga berhenti harus setidaknya {min}',
      precision: 'Stop price harus memiliki ketepatan presisi {precision}',
    },
    quantity: {
      min: 'Jumlah harus setidaknya {min}',
      precision: 'Jumlah harus memiliki ketepatan presisi {precision}',
    },
    total: {
      min: 'Jumlah minimum transaksi setidaknya {min}',
      precision: 'Total harus memiliki presisi {precision}',
    },
    price: {
      min: 'Harga harus setidaknya {min}',
      precision: 'Harga harus memiliki ketepatan presisi {precision}',
    },
    google_authenticator_code: {
      length: 'Panjang kode otentikasi google harus 6',
    },
    tel: {
      numeric: 'Silakan masukkan nomor',
    },
    zip_code: {
      numeric: 'Silakan masukkan nomor',
    },
  },
  excel: {
    account_registrant: {
      no: 'Tidak',
      file_name: 'Pendaftar akun',
      already: 'Sudah',
      not_yet: 'Belum',
      mail_address: 'Email',
      mail_auth: 'Otentikasi',
      registered_information: 'Informasi Terdaftar',
      registered_date: 'Tanggal Terdaftar',
      last_login: 'Login Terakhir',
    },
    registered_person: {
      no: 'Tidak',
      file_name: 'Orang yang Terdaftar',
      mail_address: 'Email',
      name: 'Nama',
      tel: 'Telepon',
      country: 'Negara',
      upload_at: 'Tanggal Unggah',
      last_login: 'Login Terakhir',
    },
    id_registrant: {
      no: 'Tidak',
      file_name: 'Pendaftar KYC',
      country_name: 'Negara',
      name: 'Nama',
      tel: 'Telepon',
      mail_address: 'Email',
      created_at: 'Tanggal Dibuat',
      updated_at: 'Tanggal Unggah',
      last_login: 'Login Terakhir',
    },
    payment_history: {
      file_name: 'Riwayat Pembayaran',
      id: 'ID transaksi',
      user_name: 'Nama pengguna',
      currency: 'Mata uang',
      amount: 'Jumlah',
      created_at: 'Tanggal Dibuat',
    },
  },
  _auth: {
    failed: 'Anda memasukkan ID Pengguna atau kata sandi yang salah.',
    throttle: 'Terlalu banyak upaya masuk. Silakan coba lagi dalam {seconds} detik.',
    ip_verify: 'Silakan periksa email Anda untuk mengizinkan Alamat IP ini mengakses akun Anda.',
  },
  _pagination: {
    previous: '« Sebelumnya',
    next: 'Selanjutnya »',
  },
  _passwords: {
    password: 'Kata sandi harus minimal enam karakter dan cocok dengan konfirmasi.',
    reset: 'Kata sandi Anda telah diatur ulang!',
    sent: 'Kami telah mengirim email tautan reset kata sandi Anda!',
    token: 'Token pengaturan ulang kata sandi ini tidak valid.',
    user: 'Kami tidak dapat menemukan pengguna dengan alamat email itu.',
  },
  roe_num_ranking_one: 1,
  roe_num_ranking_two: 2,
  roe_num_ranking_three: 'Ke-3',
  roe_num_ranking_four: '4 〜 10',
  roe_num_ranking_rate: '{btc} masing-masing',
  roe_person_info: '{num} | {num} atau lebih | {num} BTC',
  roe_activity_tit: 'NVX Exchange leverage perdagangan kompetisi ROE',
  roe_activity_subtit:
    'NVX Exchange akan menjadi tuan rumah kontes ROE, semua orang dapat berpartisipasi selama Anda melakukan perdagangan leverage!<br /> Selama periode ini, ROE atas ( pengembalian ekuitas ) pengguna kompetisi akan ditampilkan di dasbor perdagangan secara real time.',
  roe_activity_competition_content: 'Konten kompetisi ROE：',
  roe_activity_competition_content_desc:
    'Pengguna yang berdagang dengan kontrak terbuka dan memiliki ROE yang lebih tinggi akan mendapatkan hadiah setelah kompetisi. Pengguna dapat memasuki kompetisi sendiri. Jumlah hadiah bervariasi tergantung pada jumlah total peserta.',
  roe_activity_compute2: 'Margin = Harga Masuk ÷ Leverage',
  roe_act_time_range: 'Dari {fromTime} ke {toTime} / UT （UTC） {allDay} minggu',
  roe_activity_quil: 'Persyaratan kompetisi：',
  roe_activity_quil_money: 'Tahan margin di atas {money} BTC',
  roe_acti_table: 'Jumlah peserta dan jumlah total hadiah：',
  roe_acti_table_person: 'Jumlah peserta',
  roe_acti_table_money: 'Jumlah total hadiah',
  roe_acti_rule: 'Aturan distribusi hadiah：',
  roe_acti_rule_ranking: 'Peringkat',
  roe_acti_rule_rate: 'Rasio bonus',
  roe_acti_rule_reward: 'Hadiah',
  roe_act_money_time:
    'Waktu distribusi hadiah: <br /> Setelah kompetisi, jumlah peserta dan adil atau tidak adil akan dikonfirmasi dalam {day} hari. Kemudian distribusi dalam {day_m} hari ke pemenang peringkat.',
  roe_act_money_time_table:
    'Contoh: Ketika jumlah peserta adalah {person} dan total hadiah menjadi {btcNum} BTC',
  roe_act_desc_tit: 'Catatan tambahan kompetisi：',
  roe_act_desc_list1:
    'Setelah kompetisi, jika Anda menarik margin atau tidak berdagang selama kompetisi, Anda mungkin didiskualifikasi.',
  roe_act_desc_list2:
    'Setelah kompetisi, jumlah peserta dan peringkat akan ditentukan dan diumumkan dalam {day} hari.',
  roe_act_desc_list3:
    'Kami berhak untuk mendiskualifikasi perdagangan apa pun yang dianggap sebagai perdagangan penjualan cuci, akun yang terdaftar secara ilegal, perdagangan mandiri atau perdagangan manipulasi pasar.',
  roe_act_desc_list4:
    'Kami berhak untuk membatalkan atau memodifikasi aturan kompetisi atau kompetisi.',
  roe_act_desc_cancel: 'Kondisi diskualifikasi:',
  roe_act_desc_cancel_list1:
    'Jika Anda memegang posisi selama kompetisi, tetapi margin kurang dari {btc} BTC sebelum kompetisi: kualifikasi akan dibatalkan. ( masih dapat masuk kembali jika Anda memenuhi syarat )',
  roe_act_desc_cancel_list2:
    'Jika Anda memegang posisi melewati tanggal mulai kompetisi, itu akan dihitung setelah penyelesaian. Jika margin turun di bawah {btc} BTC pada saat penyelesaian, kualifikasi akan dibatalkan.',
  roe_act_desc_cancel_list3:
    'Jika Anda memegang posisi melewati tanggal mulai kompetisi, kualifikasi akan dibatalkan.',
  roe_act_desc_cancel_list4:
    'Jika tidak ada perdagangan selama kompetisi, kualifikasi akan dibatalkan.',
  roe_act_about_money_tit: 'Tentang margin Bonus :',
  roe_act_about_money_list: {
    0: 'Bonus adalah 100% dari dana dan akan masuk ke saldo margin ketika Anda melamar partisipasi. ( tidak ada batas )',
    1: 'Bonus dapat digunakan sebagai margin bersama dengan saldo margin.',
    2: 'Tidak dapat ditransfer / ditarik',
    3: 'Jika dana ditransfer atau kurang dari saldo margin, bonus akan hilang.',
    4: 'Biaya transaksi dan biaya penanganan dana dapat dikurangkan dari saldo margin.',
    5: 'Kerugian perdagangan Anda dikurangkan dari margin',
    6: 'Jika hanya ada bonus, itu tidak dapat diperdagangkan. ( Anda dapat menambahkan saldo margin ke perdagangan dan bonus juga dapat digunakan sebagai deposit )',
    7: 'Potongan kerugian eksekusi dalam saldo margin.',
    8: 'Bonus akan hilang pada akhir permainan.',
    9: 'Jika Anda memegang posisi yang menggunakan bonus pada akhir permainan, bonus akan hilang saat posisi dibatalkan. (jika Anda memegang posisi dengan bonus pada entri selanjutnya, meskipun Anda dapat berpartisipasi, bonus tidak akan diberikan)',
  },
  roe_act_warn:
    'Peringatan: Perdagangan digital asset melibatkan risiko tinggi seperti fluktuasi harga yang cepat, harap membuat keputusan yang bijaksana dan berinvestasi dengan hati-hati sesuai dengan toleransi risiko Anda.',
  roe_competition_conten: {
    0: {
      tit: 'Isi kompetisi ROE',
      desc: 'Pengguna yang melakukan perdagangan dengan kontrak terbuka dan memiliki ROE yang lebih tinggi akan mendapatkan hadiah setelah kompetisi. Pengguna dapat masuk ke kompetisi tersebut secara mandiri. Jumlah hadiah bervariasi tergantung pada total jumlah peserta. Perhitungan kompetisi ROE tidak mempengaruhi jumlah dana yang dimiliki oleh pengguna. Contohnya, pengguna dengan margin 0,05 BTC dan pengguna dengan margin 5 BTC dihitung skor ROE secara adil.',
    },
    1: {
      tit: 'Metode perhitungan ROE (Return on Equity) adalah sebagai berikut:',
      desc: 'ROE (seluruh periode) = [(ROE (hari 1) + 1) * (ROE (hari 2) + 1) * (ROE (hari 3) + 1) * ... * (ROE pada tanggal terakhir)] - 1. Metode perhitungan ROE (Return on Equity) adalah sebagai berikut:',
    },
    2: {
      tit: 'Metode perhitungan ROE (Return on Equity) harian (per hari)',
      desc: 'ROE (ROE harian) = saldo margin (akhir hari) ÷ saldo margin (awal hari) – 1 "Saldo margin" mengacu pada jumlah dana yang tersedia di akun pada akhir hari dan awal hari. Perhitungan ini dilakukan dengan membagi saldo margin akhir hari dengan saldo margin awal hari, kemudian dikurangi dengan 1. Hasilnya adalah ROE harian yang mengindikasikan pertumbuhan atau penurunan ekuitas dalam satu hari. Penting untuk dicatat bahwa dalam konteks ini, "hari" mengacu pada periode waktu mulai pukul 12:00 waktu Jepang pada suatu hari hingga pukul 11:59 pada hari berikutnya. Untuk metode perhitungan saldo margin pada akhir hari, harap merujuk ke penjelasan berikutnya.',
    },
    3: {
      tit: 'Metode perhitungan saldo margin pada akhir hari',
      desc: 'Saldo margin (pada akhir hari) = saldo margin (pada awal hari) + laba rugi yang terealisasi (pada saat perdagangan di hari tersebut) + laba rugi yang belum terealisasi (pada akhir hari) - laba rugi yang belum terealisasi (pada awal hari)',
    },
    4: {
      tit: 'Periode pendaftaran kompetisi ROE adalah sebagai berikut:',
      desc: '8/2 01:00 ～ 8/12 08:59（UTC）<br /> Anda tidak dapat mendaftar ke dalam kontes di luar periode pendaftaran. Tidak diperbolehkan untuk berpartisipasi setengah jalan.',
    },
    5: {
      tit: 'Periode kompetisi ROE adalah sebagai berikut:',
      desc: '8/12 09:00 ～ 8/30 08:59 (UTC)',
    },
    6: {
      tit: 'Persyaratan kompetisi',
      desc: 'Memiliki margin di atas 0,02 BTC',
    },
  },
  index_cooperative_partner: 'Mitra kerjasama',
  index_cooperative_plan: 'Rencana perekrutan',
  index_buyback_fund: 'Dana pembelian kembali',
  index_insurance: 'Dana Asuransi Hacker',
  cooperation_invite: 'Hadiah undangan',
  profit_ranking_contest_time: {
    banner: 'Segera datang!!',
    activity: 'Anda tidak dapat masuk sekarang.',
    contest: 'Saat ini tidak ada kompetisi.',
  },
  coin_information_btn_link: 'Informasi koin',
  profit_ranking_contest_tit: 'Leverage Trading ROE Competition',
  profit_ranking_contest_btnTxt: 'Detail',
  deposit_bonus_tit: 'Setor <br /> Kampanye Bonus',
  deposit_bonus_subtit: 'Cukup setor selama periode <br /> Anda akan mendapatkan bonus',
  deposit_bonus_time: '20 Maret ~ 26 Maret',
  not_found: {
    title: '404 Halaman Tidak Ditemukan',
    text: 'Kami tidak dapat menemukan halaman yang Anda cari. Anda dapat kembali ke halaman sebelumnya, mengunjungi beranda kami atau menghubungi tim dukungan kami.',
    landing: 'Kembali ke Pendaratan',
  },
  footer: {
    copyright: 'Seluruh hak cipta.',
  },
  attributes: {
    email: 'email',
    user_id: 'ID pengguna',
    password: 'kata sandi',
    repeat_password: 'ulangi kata sandi',
    new_password: 'kata sandi baru',
    confirm_new_password: 'konfirmasi kata sandi baru',
    agree: 'setuju',
    google_authenticator_code: 'kode autentikator google',
    recovery_code: 'kode pemulihan',
    confirm_email: 'konfirmasi email',
    blockchain_address: 'alamat blockchain',
    transaction_explorer: 'penjelajah transaksi',
    minium_quantity: 'jumlah minimum',
    minium_amount: 'jumlah minimum',
    minium_total: 'total minimum',
    total_limit: 'batas total',
    amount_limit: 'batas jumlah',
    minium_withdrawal: 'penarikan minimum',
    zip_code: 'kode pos',
    state_region: 'negara bagian / wilayah',
    building_room: 'Ruang',
    coin_name: 'nama',
    token_name: 'nama token',
    precision: 'presisi',
    contract_address: 'alamat kontrak',
    coin: 'koin',
    token: 'token',
    icon: 'ikon',
    time_reset: 'mengatur ulang waktu',
    market_price: 'harga market',
    minimum_amount: 'jumlah minimum',
    minimum_total: 'total minimum',
    minimum_withdrawal: 'penarikan minimum',
    limit: 'batas',
    fee: 'biaya',
    pair: 'pasangan',
    name: 'nama',
    send_confirmer: 'konfirmer',
    tx_hash: 'hash transaksi',
  },
  roe_act_desc_list: {
    0: 'Jika ada kurang dari 20 orang selama kontes, kontes ( kontes individu ) akan ditangguhkan, tetapi kontes tim akan dimulai.',
    1: 'Jika 20 orang berkumpul dan kompetisi diadakan, tetapi karena tidak ada perdagangan atau karena penarikan, jumlah peserta kurang dari 20. Bahkan selama periode kontes, kontes tidak akan diadakan, juga tidak ada hadiah yang akan dibagikan.',
    2: 'Jika Anda memiliki posisi selama periode masuk dan posisi memegang memasuki kontes, ROE akan dihitung dari waktu setelah Anda benar-benar menutup posisi selama kontes.',
    3: 'Setelah kompetisi, jika Anda menarik margin atau tidak berdagang selama kompetisi, Anda mungkin didiskualifikasi',
    4: 'Setelah kompetisi, jumlah peserta dan peringkat akan ditentukan dan diumumkan dalam 20 hari.',
    5: 'Kami berhak untuk mendiskualifikasi perdagangan apa pun yang dianggap sebagai perdagangan penjualan cuci, akun yang terdaftar secara ilegal, perdagangan mandiri atau perdagangan manipulasi pasar.',
    6: 'Kami berhak untuk membatalkan atau memodifikasi aturan kompetisi atau kompetisi.',
  },
  joinUs_tit: 'Bergabunglah dengan kami',
  joinUs_subtit: 'BERSAMA KITA MENCIPTAKAN, MEMBANGUN DAN MEMBUAT PERBEDAAN',
  joinUs_duty: 'Tanggung jawab dan Tugas',
  joinUs_sen: 'Kualifikasi',
  contract_time: 'Transaksi leverage NVX Exchange 2020/03/27 akhirnya dirilis.',
  contract_explain: 'Di sini kami menjelaskan transaksi leveraged.',
  contract_release: 'Kontrak yang akan dirilis',
  contract_btc: 'Kontrak tidak terbatas BTC / USD',
  contract_eth: 'Eth / USD tidak terbatas',
  contract_month_6: 'BTC / USD 6 bulan Futur',
  contract_release_time: 'Tanggal rilis',
  contract_release_time_txt: '05:00 ( UTC ) 27 Maret 2020',
  contract_release_trait_tit: 'Fitur penawaran leverage NVX Exchange',
  contract_release_trait_tit_desc:
    'Penawaran leverage NVX Exchange adalah unik untuk NVX Exchange.',
  contract_times: 'Leverage maksimum hingga 100 kali.',
  contract_services: 'Anda dapat menggunakan token platform $ 4IRE untuk membayar setengah biaya.',
  contract_api: 'Ini mendukung perdagangan sistem menggunakan API.',
  contract_transition:
    'Anda dapat menerima sebagian dari keuntungan pertukaran dengan memegang lebih dari 10.000 amal.',
  contract_explain_tit: 'Penjelasan transaksi leverage',
  contract_explain_subtit:
    'Platform perdagangan leveraged NVX Exchange adalah platform yang memungkinkan Anda untuk memperdagangkan barang leveraged yang dijual sebagai koin palsu.',
  contract_explain_li_1:
    'NVX Exchange saat ini hanya menangani koin bit sebagai bukti. Semua untung dan rugi dihitung dengan konstruksi koin bit. Jika Anda memperdagangkan kontrak dengan koin alto, Anda juga akan menghitung untung dan rugi dalam koin bit. NVX Exchange tidak menangani mata uang legal.',
  contract_explain_li_2: 'NVX Exchange dapat leverage hingga 100 kali.',
  contract_contract_tit: 'Perjanjian Bursa NVX',
  contract_contract_subtit_2:
    "Dengan menjual ini, Anda dapat 'mengontrak' panjang atau pendek. Juga, NVX Exchange dapat dikatakan sebagai platform perdagangan yang menguntungkan untuk tujuan penjualan tanpa fakta bahwa ia dapat melakukan penjual pendek tanpa memegang kontrak.",
  contract_transition_tit: 'Transaksi margin',
  contract_transition_subtit:
    'NVX Exchange menawarkan leverage hingga 100 kali dalam beberapa kontrak.',
  contract_transition_li_1:
    'Misalnya, Anda dapat membeli hingga 100 kontrak BTC hingga emas bukti 1btc. Selain itu, leverage yang tinggi dapat meningkatkan laba, tetapi pada saat yang sama kerugian dapat meningkat.',
  contract_transition_li_2:
    'NVX Exchange menggabungkan resolusi leverage otomatis. Ini akan mengurangi posisi leveraged yang menarik dalam periode waktu tertentu ketika Marquette tidak dapat melakukan clearing order dalam urutan Marquette.',
  contract_methods_tit: 'Cara mulai memanfaatkan',
  contract_methods_subtit:
    'NVX Exchange memungkinkan Anda untuk mentransfer BTC dari akun utama Anda ke akun leverage.',
  contract_methods_li_1:
    'Akses saldo NVX Exchange dan klik BTC untuk memindahkan BTC dari akun utama ke saldo leverage.',
  contract_methods_li_2: 'Akses pertukaran leverage dan lakukan transaksi leveraged.',
  recruit: {
    0: {
      tit: 'Direktur Branding Luar Negeri',
      duty: {
        0: 'Bertanggung jawab atas implementasi perencanaan strategis pasar luar negeri perusahaan, merumuskan rencana kerja pasar keseluruhan perusahaan, dan mengusulkan arahan khusus dan rencana implementasi untuk promosi pasar, branding, hubungan masyarakat, kegiatan, dll.',
        1: 'Membangun sistem promosi dan mengembangkan sumber daya pasar, yang bertanggung jawab untuk mengembangkan dan memelihara mitra sumber daya, media dan saluran pemasaran',
        2: 'Merumuskan strategi hubungan masyarakat global perusahaan dan respons hubungan masyarakat',
        3: 'Bertanggung jawab atas pengembangan bisnis dan inovasi kegiatan pemasaran',
        4: 'Bertanggung jawab atas implementasi perencanaan strategis pasar luar negeri perusahaan, merumuskan rencana kerja pasar keseluruhan perusahaan, dan mengusulkan arahan khusus dan rencana implementasi untuk promosi pasar, branding, hubungan masyarakat, kegiatan, dll.',
      },
      need: {
        0: 'Membangun sistem promosi dan mengembangkan sumber daya pasar, yang bertanggung jawab untuk mengembangkan dan memelihara mitra sumber daya, media dan saluran pemasaran',
        1: 'Merumuskan strategi hubungan masyarakat global perusahaan dan respons hubungan masyarakat',
      },
    },
    1: {
      tit: 'Manajer Risiko dan Pengendalian Internal',
      duty: {
        0: 'Memimpin keterlibatan dan dukungan luas bisnis untuk kontrol internal yang sedang berlangsung. Bertanggung jawab atas manajemen risiko dalam proses operasi',
        1: 'Menganalisis perilaku perdagangan pengguna, status akun, mengidentifikasi risiko, dan merancang langkah-langkah manajemen risiko yang efektif',
        2: 'Lacak, pantau, kelola indikator data utama, analisis dan optimalkan solusi kontrol yang ada, dan tingkatkan pengalaman pengguna',
        3: 'Mengevaluasi proyek baru, pengguna baru, transaksi dan transfer pengguna, menganalisis dan mengidentifikasi risiko potensial dan merancang dan menerapkan manajemen risiko',
        4: 'Pengembangan dan pengiriman materi pelatihan dan pelatihan untuk setiap departemen dan secara teratur melaporkan informasi risiko',
        5: 'Pengembangan lebih lanjut dari proses manajemen Risiko Perusahaan. Lengkapi pekerjaan terkait lainnya.',
      },
      need: {
        0: 'Gelar sarjana penuh waktu atau lebih tinggi, jurusan keuangan, ekonomi, hukum, komputer, dll.',
        1: '1-3 tahun pengalaman kerja, manajemen risiko lembaga keuangan, industri blockchain, pengalaman lapangan digital asset digital lebih disukai',
        2: 'Akrab dengan undang-undang, peraturan, dan kebijakan peraturan yang relevan, yang akrab dengan fitur digital asset digital, metode operasional, dan alat manajemen risiko',
        3: 'Memiliki kemampuan berpikir logis yang kuat dan kemampuan analitis, pandai berpikir mandiri dan berpikir bijaksana',
        4: 'Bertanggung jawab, pandai komunikasi dan kerja tim, penuh antusiasme kerja, dan kemampuan promosi proyek yang kuat.',
      },
    },
    2: {
      tit: 'Spesialis Operasi ( Inggris, Korea, Jepang, Rusia, Vietnam )',
      duty: {
        0: 'Mengembangkan tujuan dan rencana tingkat komunitas, dan bertanggung jawab untuk membangun dan mengelola komunitas luar negeri pengguna ( termasuk tetapi tidak terbatas pada Facebook, BitcoinTalk, Twitter, Telegram, Medium, dll.) untuk melayani dan mencapai tujuan manajemen dan operasi pengguna',
        1: 'Kumpulkan kebutuhan pengguna dan promosikan peningkatan produk, teknologi, dan operasional',
        2: 'Selesaikan masalah bagi pengguna.',
      },
      need: {
        0: 'Gelar sarjana atau lebih tinggi; Studi di luar negeri atau pengalaman kerja lebih disukai',
        1: '1-3 tahun pengalaman dalam mengoperasikan komunitas Internet; memiliki lebih dari 2 tahun pengalaman kerja di media keuangan arus utama atau blockchain, atau media vertikal aset digital lebih disukai',
        2: 'Akrab dengan platform media sosial luar negeri seperti Facebook, twitter, telegram, dan media',
        3: 'Memiliki pemahaman tertentu tentang industri blockchain',
        4: 'Diperlukan keahlian dalam bahasa Inggris dan Cina. Keahlian dalam bahasa Korea, Jepang atau Rusia diperlukan untuk posisi spesialis operasi yang sesuai.',
      },
    },
    3: {
      tit: 'Spesialis Operasi Platform',
      duty: {
        0: 'Bertanggung jawab atas analisis data harian platform',
        1: 'Bertanggung jawab atas token / pekerjaan daftar koin dan docking pemegang proyek dan departemen bisnis',
        2: 'Membantu menyelesaikan kegiatan yang terkait dengan berbagai departemen lintas fungsi ( misalnya: layanan pelanggan, departemen pemasaran ) untuk mendukung tujuan dalam departemen',
        3: 'Mengembangkan, merekomendasikan, dan mengimplementasikan peningkatan proses untuk meningkatkan efisiensi dan layanan yang diberikan Melayani sebagai penghubung antar departemen ketika diperlukan',
        4: 'Mempresentasikan solusi yang diusulkan dengan cara yang jelas dan ringkas dan Menyelidiki masalah yang tidak biasa di bidang tanggung jawab',
        5: 'Posisi Tambahan Tanggung jawab dan tugas terkait sebagaimana ditugaskan oleh manajer.',
      },
      need: {
        0: 'Gelar Sarjana dalam bidang terkait diperlukan; Studi di luar negeri atau pengalaman kerja lebih disukai',
        1: 'Diperlukan 1-3 tahun pengalaman kerja dalam operasi; Lebih dari 2 tahun pengalaman kerja di media keuangan arus utama, blockchain, atau media vertikal aset digital lebih disukai',
        2: 'Keterampilan organisasi yang kuat dan kemampuan belajar dan eksekusi.',
      },
    },
  },
  tranding_bonus_tit: 'BONUS PERDAGANGAN',
  tranding_bonus_time: '29 Feb 2020 pukul 16:00 ( UTC + 00 ) ~ 4 Mar 2020 pukul 16:00 ( UTC + 00 )',
  tranding_bonus_btnText: 'Total 300.0004IRE',
  btc_tranding_bonus_tit: 'BTC TRADING BONUS',
  btc_tranding_bonus_time:
    '7 Maret 2020 pukul 00:00 ( UTC + 00 ) ~ 9 Maret 2020 pukul 23:59 ( UTC + 00 )',
  btc_tranding_bonus_btnText: 'Total 10 BTC',
  symbol_detail_left: [
    {
      title_txt: 'Bitcoin (BTC) ',
      id: 'btc',
    },
    {
      title_txt: 'Ethereum (ETH) ',
      id: 'eth',
    },
    {
      title_txt: 'Ripple (XRP) ',
      id: 'xrp',
    },
    {
      title_txt: 'Bitcoin Cash (BCH) ',
      id: 'bch',
    },
    {
      title_txt: 'LiteCoin (LTC) ',
      id: 'ltc',
    },
    {
      title_txt: 'Cardano (ADA) ',
      id: 'ada',
    },
    {
      title_txt: 'Binance Coin (BNB)',
      id: 'bnb',
    },
    {
      title_txt: 'Binance-Peg BUSD (BUSD)',
      id: 'busd',
    },
    {
      title_txt: 'Tether (USDT)',
      id: 'usdt',
    },
  ],
  symbol_detail_article: {
    btc: {
      article_tit: 'Bitcoin（BTC）',
      article_detail:
        'Bitcoin is a digital asset and a payment system invented by Satoshi Nakamoto who published a related paper in 2008 and released it as open-source software in 2009. The system is featured as peer-to-peer; users can transact directly without an intermediary.',
      article_link: [
        'https://bitcoin.org/en/',
        'https://www.blockchain.com/explorer',
        'https://bitcoin.org/bitcoin.pdf',
      ],
    },
    eth: {
      article_tit: 'Ethereum（ETH）',
      article_detail:
        'Ethereum adalah platform terdesentralisasi yang menjalankan kontrak cerdas: aplikasi yang berjalan persis seperti yang diprogram tanpa kemungkinan downtime, sensor, penipuan, atau campur tangan pihak ketiga.',
      article_link: [
        'https://ethereum.org/',
        'https://etherscan.io/',
        'https://github.com/ethereum/wiki/wiki/%5BJapanese%5D-Ethereum-TOC',
      ],
    },
    xrp: {
      article_tit: 'Ripple（XRP）',
      article_detail:
        'RippleNet delivers a single, frictionless experience for global payments. Rather than a constellation of disparate technologies, unstandardized communications, and centralized networks, RippleNet is a single, global network of banks that send and receive payments via Ripple’s distributed financial technology -- providing real-time messaging, clearing and settlement of transactions.',
      article_link: ['https://ripple.com/', 'https://livenet.xrpl.org/', ''],
    },
    bch: {
      article_tit: 'Bitcoin Cash（BCH）',
      article_detail:
        'Bitcoin Cash adalah implementasi node penuh dari Bitcoin Cash Protocol, yang bertujuan untuk menyediakan perangkat lunak yang solid dan stabil dan membantu memimpin pengembangan protokol Bitcoin Cash dengan proses terbuka dan kolaboratif.',
      article_link: ['https://www.bitcoinabc.org/', 'https://blockchair.com/bitcoin-cash', ''],
    },
    ltc: {
      article_tit: 'LiteCoin（LTC）',
      article_detail:
        'Litecoin adalah mata uang internet peer-to-peer yang memungkinkan pembayaran biaya instan, hampir nol kepada siapa pun di dunia. Litecoin adalah sumber terbuka, jaringan pembayaran global yang sepenuhnya terdesentralisasi tanpa otoritas pusat.',
      article_link: ['https://litecoin.com/en/', 'https://blockexplorer.one/litecoin', ''],
    },
    ada: {
      article_tit: 'Cardano（ADA）',
      article_detail:
        'ADA adalah aset kripto asli dari blockchain Cardano. Bersamaan dengan ini, Binance-PEG ADA berdiri sebagai token ADA yang tumbuh subur di dalam Binance Smart Chain (BEP20). Di sini, di NVX Exchange, pengguna kami dapat terlibat dalam perdagangan Binance-PEG ADA yang mulus, aset yang menampilkan potensi meningkatkan potensi dibandingkan dengan ADA asli. <br /> Binance Smart Chain adalah blockchain berkinerja tinggi dengan penekanan pada pemrograman kontrak pintar.',
      article_link: ['https://www.bnbchain.org/en/smartChain', 'https://bscscan.com/', ''],
    },
    bnb: {
      article_tit: 'Binance Coin (BNB) ',
      article_detail:
        'BNB adalah koin aset kripto yang menggerakkan ekosistem rantai BNB. Sebagai salah satu token utilitas paling populer di dunia, Anda tidak hanya dapat memperdagangkan BNB seperti aset kripto lainnya, Anda juga dapat menggunakan BNB dalam berbagai aplikasi dan kasus penggunaan.<br />Binance Smart Chain adalah blockchain berkinerja tinggi dengan penekanan pada pemrograman kontrak pintar.',
      article_link: ['https://www.bnbchain.org/en/smartChain', 'https://bscscan.com/', ''],
    },
    busd: {
      article_tit: 'Binance-Peg BUSD (BUSD)',
      article_detail:
        'BUSD adalah stablecoin 1: 1 yang didukung USD yang disetujui oleh Departemen Keuangan Negara Bagian New York (NYDFS) dan dikeluarkan dalam kemitraan dengan Paxos.<br /> Binance Smart Chain adalah blockchain berkinerja tinggi dengan penekanan pada pemrograman kontrak pintar.',
      article_link: ['https://www.bnbchain.org/en/smartChain', 'https://bscscan.com/', ''],
    },
    usdt: {
      article_tit: 'Tether (USDT)',
      article_detail:
        'USDT (Tether) berjalan di jaringan Ethereum sebagai token ERC-20. Ethereum adalah platform terdesentralisasi yang menjalankan kontrak pintar: aplikasi yang berjalan persis seperti yang diprogram tanpa kemungkinan downtime, sensor, penipuan atau gangguan pihak ketiga.',
      article_link: ['https://ethereum.org/', 'https://etherscan.io/', ''],
    },
  },
  symbol_detail_introduction: 'Perkenalan',
  symbol_detail_org: 'Situs Resmi: ',
  symbol_detail_block: 'Penjelajah Blockchain: ',
  symbol_detail_book: 'White Paper: ',
  btc_trading_rebate_tit: 'Rebate Perdagangan BTC',
  btc_trading_rebate_time: 'Buka Perdagangan',
  slide_expand: 'Perluas',
  slide_collapse: 'Ciutkan',
  high_rebate_banner: {
    rebate_tit: 'Program Referensi NVX Exchange',
    rebate_subtit: 'Undang teman-teman Anda untuk mendapatkan pengembalian biaya transaksi',
    rebate_step: {
      0: {
        num: 1,
        step_desc: 'Daftar NVX Exchange dan dapatkan tautan dan kode referral Anda',
      },
      1: {
        num: 2,
        step_desc: 'Daftar demo melalui tautan atau kode referral Anda',
      },
      2: {
        num: 3,
        step_desc: 'Anda akan mendapatkan maksimal 40% <br /> dari 5 tingkat',
      },
    },
    rebate_btn: 'Daftar untuk segera mengundang teman-teman',
  },
  high_rebate_detail: {
    rebate_tit: 'Suara Anda tentang <span>NVX Exchange<span>',
    rebate_subtit:
      'Bergabunglah dengan program referensi yang paling menguntungkan di dunia. Dapatkan komisi dari 5 tingkat.',
    rebate_rate: 'Maksimal<span>40%</span>',
    rebate_joinin: 'Daftar Sekarang!',
    rebate_register_step: {
      0: {
        step_img: '/images/rebate/number-one.png',
        step_num: 'Langkah 1: Daftar',
        step_desc:
          'Ajukan sekarang untuk bergabung dengan program referensi. Dapatkan akses ke admin kami yang akan memandu Anda melalui proses di komunitas.',
      },
      1: {
        step_img: '/images/rebate/number-two.png',
        step_num: 'Langkah 2: Promosikan',
        step_desc:
          'Beritahu komunitas Anda tentang NVX Exchange. Mereka harus mendaftar melalui kode referral Anda. Pantau keberhasilan kampanye Anda dengan wawasan komunitas.',
      },
      2: {
        step_img: '/images/rebate/number-three.png',
        step_num: 'Langkah 3: Dapatkan Pembayaran',
        step_desc:
          'Ketika pengguna yang Anda referensikan melakukan perdagangan, Anda akan dibayar dalam BTC, USDT, dan sebagainya langsung ke wallet Anda. Anda dapat menarik kripto Anda kapan saja.',
      },
    },
    rebate_five_tit: 'Struktur Komisi Lima Tingkat',
    rebate_five_subdesc:
      'Beri tahu teman Anda untuk memberi tahu teman-teman mereka sehingga Anda semua bisa menjadi teman.',
    rebate_five_desc:
      'Sebagai rujukan, Anda akan menerima persentase komisi yang dibayarkan oleh rujukan Anda hingga 5 tingkatan. Ketika pengguna mendaftar melalui kode rujukan Anda, mereka telah mendaftar dan menjadi rujukan dengan kampanye Anda. Pembayaran rujukan akan berlanjut tanpa batas waktu selama Anda dan pengguna yang dirujuk ( s ) memiliki dan mengoperasikan akun NVX Exchange mereka ( s ).',
    rebate_five_become: 'Menjadi pengguna komunitas',
    rebate_five_tips: 'Baca lebih lanjut tentang <span>Program rujukan NVX Exchange </span>',
    rebale_article_link: 'https://demo-exchange.zendesk.com/hc/en-us/articles/360039635991',
    rebate_explain: {
      0: {
        tit: 'Komisi Lima Tingkat',
        desc: 'Dapatkan bayaran 10% + total 10% komisi pada 5 tingkatan. <br /> ( Anda akan dibayar 20% + total 20% komisi pada 5 tingkatan ketika Anda merujuk 100 orang. )',
      },
      1: {
        tit: 'Dukungan Mitra',
        desc: 'Untuk menjadi mitra, Anda akan mendapatkan lebih banyak kesempatan untuk mendapatkan dividen yang dibayarkan.',
      },
      2: {
        tit: 'Manajemen Data Rujukan',
        desc: 'Dapatkan akses ke dasbor mitra rujukan langsung Anda dengan wawasan tentang pengguna yang Anda rujuk.',
      },
      3: {
        tit: 'Pembayaran Harian',
        desc: 'Komisi dibayarkan ke akun Anda di BTC, USDT dll ... diselesaikan pada hari yang sama dengan perdagangan pengguna yang Anda rujuk.',
      },
    },
    rebate_unique_tit: 'MENGAPA Anda harus berdagang di NVX Exchange?',
    rebate_unique: {
      0: {
        tit: 'Antarmuka yang mudah digunakan',
        subtit: 'Bahkan pedagang baru dapat berdagang dengan mudah di NVX Exchange.',
      },
      1: {
        tit: 'Bitcoin sebagai Aset yang Mendasari',
        subtit: 'Perdagangkan crypto menggunakan Bitcoin Anda, tanpa menerima pengiriman aset.',
      },
      2: {
        tit: 'Likuiditas dalam',
        subtit: 'Buku pesanan dalam dan lebih banyak likuiditas.',
      },
      3: {
        tit: 'Keamanan',
        subtit:
          'Aset klien selalu dipisahkan, dilindungi, dan disimpan dalam wallet dingin multi-tanda tangan – tidak pernah disimpan dalam wallet panas.',
      },
      4: {
        tit: '100x Leverage di Crypto',
        subtit: 'Leverage perdagangan dengan leverage hingga 100x pada cryptos.',
      },
      5: {
        tit: 'Dukungan Multi-bahasa',
        subtit:
          'NVX Exchange menawarkan dukungan obrolan langsung ke semua pengguna. Sebagian besar pertanyaan dapat diselesaikan melalui panduan bantuan kami, tetapi kadang-kadang penting untuk berbicara dengan manusia sejati. Kami ada di sana ketika Anda membutuhkan kami.',
      },
      6: {
        tit: 'Biaya Rendah',
      },
      7: {
        tit: 'Program Label Mitra',
        subtit: '200 + Mitra sedang menunggu Anda untuk bergabung!!',
      },
    },
  },
  coopeartion_d: {
    banner: {
      tit: 'Menjadi mitra dari',
      sub_title: 'Pertukaran Sotatek',
      subtit: 'Bekerja dengan teman-teman Anda dan tumbuh bersama dengan NVX Exchange!',
      login: 'Terapkan di sini',
      manage_login: 'Terapkan di sini',
      partner: 'Kelola akun Anda',
      manage_partner: 'Pendaftaran akun tiga menit',
      detail: 'Pelajari lebih lanjut tentang NVX Exchange',
      main: 'Subjek',
      main_step: 'NVX Exchange > Subjek',
      plan: 'NVX Exchange memiliki sistem unik yang disebut "Program Label Mitra". Selama Anda aktif di bidang Anda, Anda dapat menjadi mitra kami dalam sistem ini, membuat diri Anda dan NVX Exchange tumbuh bersama. Dengan menjadi mitra, pengguna akan menjadi anggota penting ekosistem NVX Exchange, dan NVX Exchange akan mendistribusikan token 4IRE sebagai dividen',
    },
    step: {
      0: {
        step_img: '/gambar / langkah-1-partner.svg',
        step_num: 'Langkah 1',
        step_desc:
          'Terapkan untuk Program Label Mitra. <br /> Jika Anda lulus ulasan kami, tautan undangan komunitas mitra akan dikirim, jadi silakan bergabung.',
      },
      1: {
        step_img: '/gambar / langkah-2-partner.svg',
        step_num: 'Langkah 2',
        step_desc:
          'Menjadi mitra kami. <br />   Menjadi mitra dan secara aktif memberikan pengaruh di bidang-bidang di mana Anda pandai.',
      },
      2: {
        step_img: '/gambar / langkah-3-partner.svg',
        step_num: 'Langkah 3',
        step_desc:
          'Berpartisipasi dalam program khusus mitra. <br />    NVX Exchange secara teratur melakukan tugas untuk mitra. <br />   Pemenang hadiah utama yang telah mencapai tugas akan menerima 4IRE token atau NVX Exchange barang dagangan terbatas.',
      },
    },
    reward: {
      tit: 'Kompensasi mitra',
      step: {
        0: 'Dividen permanen 4IRE disediakan oleh NVX Exchange <br /> Setelah menjadi mitra, Anda dapat menerima hadiah harian di BTC dengan memegang total 10.000 4IRE atau lebih yang diperoleh selama periode mitra atau 4IRE yang dimiliki oleh Anda sendiri. Namun, 4IRE yang diberikan oleh periode mitra ’ juga akan hilang pada akhir periode mitra ’. <br /> ( 4IRE yang Anda dapatkan tidak akan hilang. ) < br /> Bagian dari biaya transaksi pengguna yang Anda undang. ( komisi hingga 40% )',
        1: 'Sampai 40% dari komisi dapat diterima dari hingga 5 level pengguna yang Anda undang. Komisi yang dikembalikan ( seperti BTC, USDT, 4IRE, dan token lain ) dapat diperdagangkan dan ditarik secara bebas tanpa dikunci. ( Hingga 20% dari komisi dapat dikembalikan sebelum kondisi yang ditentukan tercapai. Jika kondisi terpenuhi, itu akan meningkat menjadi 40%. ) <br /> Tugas mitra saja lengkap',
        2: 'NVX Exchange secara teratur menyiapkan tugas untuk mitra. Ini memungkinkan mitra untuk mendapatkan dividen permanen 4IRE, USDT atau NVX Exchange produk eksklusif.',
      },
    },
    func: {
      tit: 'Fungsi yang tersedia untuk pasangan',
      step: {
        0: {
          step_num: 'Sistem pembayaran real time',
          step_desc:
            'Biaya layanan yang dibayarkan oleh pengguna yang diundang akan dikembalikan ke wallet Anda secara real time.',
        },
        1: {
          step_num: 'Sistem manajemen komisi',
          step_desc:
            'Kami memiliki sistem yang lengkap untuk mengelola jumlah pengguna yang diundang dan komisi yang dibebankan. Selain itu, Anda juga dapat mengunduh data ini sebagai file CSV.',
        },
        2: {
          step_num: 'Promosi rilis SNS',
          step_desc: 'Mitra dapat memposting kode rujukan mereka ke Twitter, Facebook, dll.',
        },
      },
    },
    partner: {
      tit: 'Mencari mitra',
      maintit:
        '<span>Saat ini, < kelas rentang = "teks biru" > NVX Exchange < / bentang > sedang mencari mitra berikut. < / bentang >',
      step: {
        0: 'KOL',
        1: 'anggota',
        2: 'media',
      },
    },
  },
  cooperation_banner_tit: 'Menjadi mitra NVX Exchange',
  cooperation_banner_subtit:
    'Bekerja dengan teman-teman Anda dan tumbuh bersama dengan NVX Exchange!',
  cooperation_banner_btntxt: 'SEKARANG',
  roe_personal_team_slide: {
    subtit:
      'Mari kita berpartisipasi dalam kontes perdagangan Individu / Tim!<br /> Kontes ini akan membandingkan total ROE tim Anda / Anda!!',
    personal_btn: 'Individu',
    team_btn: 'Tim batttle',
    bonus: 'Total hadiah <br /> 200BTC',
  },
  roe_team: {
    header_txt: {
      personal: {
        tit: 'NVX Exchange leverage perdagangan kompetisi ROE',
        subtit:
          'NVX Exchange akan menjadi tuan rumah kontes ROE, semua orang dapat berpartisipasi selama Anda melakukan perdagangan leverage!',
      },
      team: {
        tit: 'Kontes ROE Perdagangan Berleverage untuk Tim-tim Perusahaan di NVX Exchange. Dalam kontes ini, kami akan menghitung rata-rata ROE (return on equity) tim setelah kontes berakhir.',
        subtit: '',
      },
    },
    outline_list: {
      0: {
        tit: 'Rangkuman Kontes ROE (Gaya Tim Perusahaan)',
        subtit:
          'Tim dengan ROE tertinggi akan mendapatkan hadiah pada akhir kontes. Semua pengguna dapat menjadi pemimpin tim. Tim yang belum mencapai jumlah minimum peserta akan didiskualifikasi. Jumlah hadiah akan berbeda tergantung pada jumlah tim yang berpartisipasi. Selain kontes peringkat ROE, ada juga hadiah untuk volume perdagangan tim secara keseluruhan. Perhitungan ROE dalam kontes tidak mempengaruhi jumlah dana yang digunakan. Contoh: Pengguna dengan margin 0,05 BTC dan pengguna dengan margin 5 BTC akan dihitung skor ROE secara adil.',
      },
      1: {
        tit: 'Metode Perhitungan ROE',
        subtit:
          'ROE (seluruh periode) = [(ROE (hari 1) + 1) * (ROE (hari 2) + 1) * (ROE (hari 3) + 1) * ... (hingga tanggal berakhir)] - 1. Silakan lihat metode perhitungan ROE harian di bawah ini.',
      },
      2: {
        tit: 'Metode Perhitungan ROE Harian (Satu Hari)',
        subtit:
          'ROE (ROE harian) = saldo margin (awal hari) ÷ saldo margin (akhir hari) - 1.\nSatu hari berarti waktu Jepang dari pukul 12:00 hingga 11:59 pada hari berikutnya.\nSilakan lihat metode perhitungan saldo margin pada akhir hari di bawah ini.',
      },
      3: {
        tit: 'Metode Perhitungan Saldo Margin (Pada Akhir Hari)',
        subtit:
          'Saldo margin (pada akhir hari) = saldo margin (pada awal hari) + laba rugi yang terealisasi (pada saat perdagangan hari itu) + laba rugi yang belum terealisasi (pada akhir hari) - laba rugi yang belum terealisasi (pada awal hari).',
      },
      4: {
        tit: 'Periode Pendaftaran Kontes ROE (Pertempuran Tim)',
        subtit: '3 Juli 09:00 ～ 11 Juli 23:59 (UTC)',
      },
      5: {
        tit: 'Periode Kontes ROE (Pertempuran Tim)',
        subtit: '12 Juli 00:00 ～ 1 Agustus 23:59 (UTC)',
      },
      6: {
        tit: 'Kualifikasi',
        subtit: 'Setidaknya 3 anggota, maksimal 20 anggota dalam satu tim.',
      },
    },
    team_bonus: {
      tit: 'Volume perdagangan minimum selama kompetisi',
      money: '1 tim {btc} BTC. Setiap anggota tim memiliki margin lebih dari 0,05 BTC.',
    },
    team_txt: {
      teamAndAllMoney: 'Jumlah Tim yang Berpartisipasi dan Total Hadiah',
      team: 'Jumlah Tim yang Berpartisipasi',
      all_money: 'Total Hadiah',
      teamtxt: 'Tim',
      trading_txt: 'Kontes Volume Perdagangan',
      roe_txt: 'Kontes ROE',
    },
    bonus_rules: 'Aturan Distribusi Hadiah',
    bonus_rate: 'Rasio Hadiah',
    bonus_dis_demo: {
      0: {
        tit: 'Contoh Distribusi Hadiah (ROE)',
        subtit:
          '"Contoh: Jika ada 10 tim yang berpartisipasi dan total hadiah adalah 2 BTC, Tempat pertama  1,4 BTC, Tempat kedua  0,2 BTC, Tempat ketiga  0,1 BTC, Tempat ke-4 hingga ke-10  0,042 BTC<br />Contoh: Jika ada 1.000 tim yang berpartisipasi dan total hadiah adalah 100 BTC,\nTempat pertama  70 BTC, Tempat kedua  10 BTC, Tempat ketiga  10 BTC, Tempat ke-4 hingga ke-10  2,1 BTC"',
      },
      1: {
        tit: 'Contoh Distribusi Hadiah (Volume Perdagangan)',
        subtit:
          '"Contoh: Jika ada 10 tim yang berpartisipasi dan total hadiah adalah 1 BTC,\nTempat pertama  0,7 BTC, Tempat kedua  0,1 BTC, Tempat ketiga  0,05 BTC, Tempat ke-4 hingga ke-10  0,021 BTC<br />\nContoh: Jika ada 1.000 tim yang berpartisipasi dan total hadiah adalah 100 BTC,\nTempat pertama  70 BTC, Tempat kedua  10 BTC, Tempat ketiga  5 BTC, Tempat ke-4 hingga ke-10  2,1 BTC"',
      },
    },
    bonus_dis_demo_time: {
      tit: 'Waktu Distribusi Bonus',
      subtit:
        'Setelah kontes selesai, kami akan mengkonfirmasi kebenaran dan menentukan peringkat kontes dalam waktu 20 hari, kemudian mendistribusikan hadiah dalam waktu 10 hari. Hal-hal Tambahan tentang Kontes',
    },
    complement: {
      tit: 'Hal-hal Tambahan tentang Kontes',
      list: {
        0: 'Selama kontes, jika Anda menarik margin atau tidak melakukan perdagangan sama sekali, Anda akan didiskualifikasi.',
        1: 'Setelah kontes selesai, jumlah peserta dan peringkat akan ditentukan dan diumumkan dalam waktu 30 hari.',
        2: 'Kami berhak mendiskualifikasi pengguna yang dianggap terdaftar secara ilegal dan melakukan manipulasi pasar.',
        3: 'Kami berhak membatalkan atau mengubah kontes atau peraturan kontes.',
        4: 'Jika Anda memegang posisi saat memasuki kontes dan berpartisipasi dalam kontes tanpa menutup posisi, ROE dan volume perdagangan akan dihitung setelah posisi ditutup 100%.',
        5: 'Uang hadiah akan didistribusikan secara merata kepada anggota tim pemenang.',
        6: 'Anda perlu mendapatkan persetujuan dari pemimpin tim untuk bergabung dengan tim.',
        7: 'Anda hanya dapat bergabung dengan satu tim dalam satu kontes.',
        8: 'Saat masuk ke dalam tim, Anda harus menunjukkan ID pengguna Anda kepada pemimpin tim.',
        9: 'Leaderboard akan menampilkan jumlah tim secara real-time dan jumlah tim yang telah ditetapkan setelah kontes berakhir.',
        10: 'Jika jumlah tim kurang dari 10, kontes tidak akan diselenggarakan dan tidak akan berlangsung selama periode tersebut.',
        11: 'Jika pendapatan komisi selama kontes lebih rendah dari total hadiah, 70% dari pendapatan komisi bersih akan menjadi batas atas total hadiah.',
      },
    },
    cancel: {
      tit: 'Kebijakan Pembatalan',
      list: {
        0: 'Jika Anda memegang posisi untuk masuk ke kontes dan sebelum kontes margin Anda kurang dari 0,05 BTC: partisipasi Anda akan dibatalkan. (Dapat mendaftar ulang kontes lagi)',
        1: 'Jika Anda tidak melakukan perdagangan sama sekali selama kontes, Anda akan didiskualifikasi.',
        2: 'Tim yang tidak mencapai volume perdagangan minimum selama kontes, tim tersebut akan didiskualifikasi.',
      },
    },
  },
  license: {
    legal: 'Dokumen Hukum dan Keamanan',
    license_tit: 'Outline',
    license_footer: 'Licenses',
    license_subtit:
      'NVX, platform pertukaran aset kripto yang berbasis di Indonesia dan beroperasi di bawah Perusahaan PT. Aset Kripto Internasional, telah menyelesaikan proses pendaftarannya dan menerima lisensi dari Badan Pengawas Perdagangan Berjangka Komoditi (Bappebti). Dengan mendapatkan lisensi, pertukaran telah memenuhi semua persyaratan peraturan yang terkait dengan sistem transaksi, manajemen kontrol risiko, alokasi sumber daya, dan infrastruktur operasional.<br/>CEO NVX telah menyatakan komitmen untuk berkolaborasi dengan Bappebti untuk mempercepat pertumbuhan ekonomi digital dan mempromosikan inklusi keuangan melalui penerapan teknologi blockchain. Bappebti mengawasi semua kegiatan perdagangan aset kripto di Indonesia dan telah menetapkan peraturan yang membutuhkan kepatuhan dari semua platform pertukaran aset kripto. Platform pertukaran yang terdaftar secara hukum berwenang untuk menawarkan layanan perdagangan aset kripto di negara tersebut.',
    certificate_tit: 'Licenses',
    fincen: 'FinGEN',
  },
  google_auth: {
    activate: 'Aktifkan OTP dengan sukses.',
    deactivate: 'Berhenti menggunakan kesuksesan OTP!',
  },
  instant_convert: {
    title: 'Konversi Instan',
    from: 'Dari',
    placeholder_from: 'Harap masukkan jumlah >',
    to: 'Untuk',
    max: 'Max',
    available_amount: 'Jumlah yang Tersedia',
    current_having_amount: 'Jumlah Memiliki Saat Ini',
    conversion_rate: 'Tingkat Konversi',
    trading_fee: 'Biaya Perdagangan',
    refresh: 'Menyegarkan',
    refreshing: 'Menyegarkan',
    convert: 'Konversi',
    note1:
      'Perkiraan Jumlah yang Diterima hanya akan tersedia dalam beberapa detik sebelum Tingkat Konversi baru diterapkan pada saat harga Pasar berubah.',
    note2:
      'Perkiraan Jumlah Perdagangan hanya akan tersedia dalam beberapa detik sebelum Tingkat Konversi baru diterapkan pada saat harga Pasar berubah.',
    not_input_amount: 'Silakan masukkan jumlah untuk dikonversi!',
    not_amount: 'Silakan masukkan jumlah yang lebih tinggi dari 0 untuk dikonversi!',
    amount_lower1: 'Harap masukkan jumlah yang lebih tinggi dari',
    amount_lower2: 'Harap masukkan jumlah yang lebih tinggi dari',
    amount_higher: 'Harap masukkan jumlah yang lebih rendah dari',
    balance_not_enough: 'Saldo wallet  tidak mencukupi!',
    slippage_changed:
      'Pasangan ini tidak tersedia untuk Konversi Instan saat ini. Silakan kembali lagi nanti!',
    pair_disabled:
      'Pasangan ini tidak tersedia untuk Konversi Instan saat ini. Silakan kembali lagi nanti!',
    price_notfound:
      'Pasangan ini tidak tersedia untuk Konversi Instan saat ini. Silakan kembali lagi nanti!',
    price_invalid:
      'Pasangan ini tidak tersedia untuk Konversi Instan saat ini. Silakan kembali lagi nanti!',
    convert_success: 'Konversi dengan sukses!',
    tax: 'Pajak',
    history: {
      page_title: 'Riwayat Konversi',
      from: 'Dari',
      to: 'Untuk',
      received_token: 'Token yang diterima',
      all: 'Semua',
      search: 'Pencarian',
      reset: 'Atur ulang',
      export: 'Ekspor Riwayat Konversi Lengkap',
      export_tooltip: 'Hanya Ekspor Riwayat Konversi Lengkap pada 6 bulan',
      date: 'Tanggal',
      traded_token: 'Token yang Diperdagangkan',
      traded_amount: 'Jumlah yang Diperdagangkan',
      conversion_rate: 'Tingkat Konversi',
      received_amount: 'Jumlah yang diterima',
      fee: 'Biaya',
      no_data: 'Anda tidak memiliki data',
      tax: 'Pajak',
    },
  },
  'funds.deposit_fiat.transaction_history_table.account': 'Akun ',
  'funds.deposit_fiat.transaction_history_table.received_amount': 'Jumlah yang diterima',
  'funds.deposit_fiat.transaction_history_table.time': 'Waktu',
  'funds.deposit_fiat.maximum_deposit': 'Jumlah setoran maksimum adalah {amount}',
  'funds.deposit_fiat.minimum_deposit': 'Jumlah setoran minimum adalah {amount}',
  'funds.deposit_fiat.maximum_deposit_with_credit_card':
    'Jumlah Setoran Maksimum Dari Metode Pembayaran Kartu Kredit Adalah 999.999.999. Lain Metode Mohon Gunakan',
  home_page: {
    title: 'Jual dan Beli <br /> Aset Digital <br /> Dengan Cara Baru',
    subtitle: 'Berdagang dengan mudah dan aman',
    get_started: 'Mulai',
    download_app: 'Unduh <br />\naplikasi',
    possibilities: {
      txt_1: 'Buka Peluang Baru <br /> dengan',
      txt_2: 'Ubah pengalaman perdagangan Anda dengan',
      txt_3: 'Mitra Perdagangan Aset Kripto Anda yang Aman dan Transparan',
    },
    about_us: {
      title: 'Tentang kami',
      content:
        'Dukungan Pelanggan: Nikmati dukungan yang cepat dan bermanfaat melalui tim dukungan pelanggan kami yang tersedia 24/7 <br /><br /> Perangkat Perdagangan Mutakhir: Gunakan layanan perdagangan aset kripto kami untuk meningkatkan strategi perdagangan Anda. <br /><br /> Penyimpanan Cold Storage yang Aman: Lindungi aset digital Anda dengan solusi cold storage luring, untuk menjamin lapisan perlindungan tambahan dari ancaman serangan siber.',
    },
    acc_management: {
      title: 'Manajemen Akun <br /> yang Komprehensif',
      title_1: 'Dukungan Mata Uang IDR',
      desc_1:
        'Konversikan mata uang fiat Anda dengan mudah menjadi aset kripto dengan authenticator 3FA yang aman.',
      title_2: 'Biaya kompetitif',
      desc_2:
        'Dapatkan manfaat dari struktur biaya kompetitif, memastikan perdagangan yang hemat biaya untuk memaksimalkan laba Anda.',
      title_3: 'Layanan Pertukaran Instan',
      desc_3:
        'Akses layanan swap instan untuk perdagangan volume besar, memberikan privasi, dukungan yang dipersonalisasi, dan harga kompetitif.',
      learn_more: 'Pelajari lebih lanjut',
    },
    our_partners: {
      title: 'Mitra kami',
    },
    news: {
      title: 'Berita dan Artikel',
      desc: 'Akses konten pembelajaran kami termasuk webinar dan wawasan pasar untuk meningkatkan pengetahuan dan keterampilan perdagangan aset kripto Anda',
    },
    advanced_tools: {
      title: 'Alat Charting Canggih',
      desc: 'Analisa tren harga, pola, dan indikator dengan alat charting canggih, memberdayakan Anda untuk membuat keputusan perdagangan berbasis data.',
    },
  },
  menu_contract: {
    historical_data: 'Data Historis',
    insurance_fund: 'Dana Asuransi',
    settlement_history: 'Riwayat Penyelesaian',
    leaderboard: 'Leaderboard',
    contract_guides: 'Panduan Kontrak',
    btc_contract: 'BTC ( Bitcoin )',
    eth_contract: 'ETH ( Ethereum )',
    eth_contract_guide: 'Ethereum Series ( ETH ) Panduan',
    ada_contract: 'ADA ( Cardano )',
    bch_contract: 'BCH ( Kas Bitcoin )',
    ltc_contract: 'LTC ( Litecoin )',
    trx_contract: 'TRX ( TRON )',
    xrp_contract: 'XRP ( Ripple )',
    contracts: 'Kontrak',
    indices: 'Indeks',
  },
  time_in_force: {
    good_till_cancel: 'Bagus Sampai Membatalkan',
    immediate_or_cancel: 'Segera Atau Batalkan',
    fill_or_kill: 'Isi atau Bunuh',
  },
  verify_code: {
    sms_invalid_or_expired: 'Silakan masukkan kode yang valid',
    phone_verified: '-',
    code_not_found: '-',
    phone_no_required: '-',
    phone_not_found: '-',
    sms_sent_to_phone: '-',
  },
  otp: {
    title: 'Kode Verifikasi',
    subtitle:
      'Silakan masukkan kode verifikasi, kami mengirimkan OTP ke {phone_no} menggunakan WhatsApp',
    'dont-receive': 'Tidak menerima OTP?',
    resend: 'Kirim ulang OTP?',
    continue: 'Selanjutnya',
  },
  exception: {
    otp_sms: {
      not_map: 'Kode Anda tidak valid. Silakan coba lagi',
      expired: 'Kode Anda kedaluwarsa. Silakan coba lagi',
      required: 'Bidang OTP SMS diperlukan.',
    },
  },
  slippery_note: {
    title: 'Slippery Note',
    price: 'Harga',
    travel_percent: 'Pergerakan Harga',
    turn_on: 'Nyalakan',
    turn_off: 'Matikan',
    field_required: 'Bidang ini tidak boleh kosong',
    price_invalid: 'Harga harus lebih besar dari 0',
    percent_invalid: 'Persentase harus lebih besar dari 0',
    cancel: 'Batalkan',
    save: 'Simpan',
    update_failed: 'Gagal mengubah Slippery Note',
    update_success: 'Berhasil mengubah Slippery Note',
    slippery_reset:
      'Perubahan Anda akan dikembalikan ke awal. <br /> Apakah Anda yakin ingin membatalkan?',
    clear_all: 'Kosongkan semua',
    no_notify: 'Tidak ada pemberitahuan',
    has_hit: 'Telah tercapai',
    last_price_has_hit: 'harga terakhir telah tercapai',
    below: 'di bawah',
    above: 'di atas',
    trigger_time: 'Waktu terpicu:',
    fi: 'Bidang ini tidak boleh kosong',
  },
  about_us: {
    about_us: 'Tentang Kami',
    about_us_content_1:
      'NVX adalah platform yang diatur oleh Indonesia yang memungkinkan pengguna untuk membeli, menjual, dan memperdagangkan digital asset. Kami beroperasi online dan memungkinkan pengguna untuk bertukar digital asset dengan aset lain, seperti mata uang fiat atau mata uang digital lainnya. Salah satu fitur utama kami adalah likuiditas, yang mengacu pada seberapa mudah pengguna dapat membeli dan menjual digital asset pada platform. NVX adalah pertukaran cair di mana ada sejumlah besar pembeli dan penjual, dan harga digital asset selalu relatif stabil. Ini memudahkan semua orang untuk membeli dan menjual digital asset dengan cepat dan dengan harga yang wajar.',
    about_us_content_2:
      'Fitur penting lainnya untuk NVX adalah keamanan kami. Karena pertukaran aset kripto berurusan dengan sejumlah besar uang, kami sering ditargetkan oleh peretas dan penjahat cyber lainnya. Akibatnya, penting bagi pertukaran untuk memiliki langkah-langkah keamanan yang kuat untuk melindungi dana pengguna dan informasi pribadi.',
    about_us_content_3:
      'Platform Pertukaran aset kripto juga tunduk pada regulasi, yang dapat bervariasi tergantung pada negara tempat platform tersebut beroperasi. Dalam beberapa kasus, platform pertukaran aset kripto mungkin diminta untuk mendaftar dengan badan pengatur dan mematuhi aturan dan peraturan tertentu. Ini membantu melindungi pengguna dan memastikan bahwa platform beroperasi dengan cara yang adil dan transparan. NVX mengimplementasikan langkah-langkah keamanan seperti sistem deteksi intrusi sambil secara teratur mengaudit dan menguji langkah-langkah keamanan platform untuk mengidentifikasi dan memperbaiki kerentanan. NVX juga mengimplementasikan rencana untuk menanggapi insiden keamanan, termasuk langkah-langkah seperti mematikan platform sementara untuk mengatasi masalah tersebut sementara semua aset di NVX diasuransikan terhadap kerugian karena pelanggaran keamanan atau insiden lainnya',
    our_vision: 'Visi kami',
    our_vision_content:
      'Visi kami adalah untuk menjadi platform global terkemuka untuk perdagangan aset kripto, memberikan pengguna akses ke berbagai macam aset kripto serta alat dan fitur perdagangan paling canggih. Kami berusaha keras untuk terus meningkatkan dan memperluas layanan kami, mendorong batas-batas yang mungkin terjadi di dunia aset kripto dan mendorong adopsi teknologi revolusioner ini. Dengan memberdayakan individu untuk mengendalikan masa depan keuangan mereka sendiri, kami bertujuan untuk menciptakan sistem keuangan yang lebih inklusif dan adil untuk semua',
    our_mission: 'Misi kami',
    our_mission_content:
      'Misi kami adalah untuk menyediakan platform yang aman dan ramah pengguna untuk membeli, menjual, dan memperdagangkan aset kripto. Kami berusaha untuk membuat dunia aset kripto dapat diakses oleh semua orang, terlepas dari keahlian teknis atau latar belakang keuangan mereka. Melalui komitmen kami terhadap inovasi dan transparansi, kami bertujuan untuk menumbuhkan kepercayaan dan kepercayaan diri di antara pengguna kami dan berkontribusi pada pertumbuhan dan pengembangan ekosistem aset kripto.',
    features: 'Fitur',
    features_content:
      'NVX menawarkan berbagai macam aset kripto untuk perdagangan, serta fitur dan alat canggih untuk membantu pengguna mengelola investasi mereka. Ini termasuk alat charting dan analisis, data pasar real-time, dan penyimpanan aman untuk aset digital. Sebagai pemimpin dalam ruang pertukaran aset kripto, platform NVX berada di garis depan industri. Dengan fokus pada keamanan, kegunaan, dan berbagai opsi perdagangan, platform ini adalah pilihan utama bagi siapa pun yang ingin membeli, menjual, atau memperdagangkan aset kripto. Dengan menyediakan platform tepercaya dan komprehensif untuk mengakses dunia aset kripto, NVX membantu membuat teknologi baru yang menarik ini lebih mudah diakses dan dimengerti audiens yang lebih luas. Baik Anda seorang pemula yang ingin memulai dengan aset kripto maupun pedagang berpengalaman yang mencari fitur canggih, platform ini memiliki sesuatu untuk ditawarkan.',
  },
  help_center_question_1: 'Q. Apa itu digital asset?',
  help_center_question_2: 'Q. Apa itu NVX?',
  help_center_question_3: 'Q. Apa yang menentukan pertukaran digital asset yang baik?',
  help_center_question_4: 'Q. Bagaimana cara kerja NVX?',
  help_center_question_5: 'Q. digital asset apa yang didukung di bursa?',
  help_center_question_6: 'Q. Apakah ada biaya untuk menggunakan pertukaran?',
  help_center_question_7: 'Q. Apakah NVX aman?',
  help_center_question_8: 'Q. Bagaimana cara menarik dana dari bursa?',
  help_center_question_9: 'Q. Bisakah saya menukar digital asset dengan mata uang fiat di bursa?',
  help_center_question_10: 'Q. Bagaimana cara kerja pertukaran mata uang fiat?',
  help_center_question_11: 'Q. Apa likuiditas dalam konteks NVX?',
  help_center_question_12: 'Q. Apakah NVXS diatur?',
  help_center_question_13: 'Q. Bagaimana saya bisa memilih pertukaran digital asset terkemuka?',
  help_center_question_14: 'Q. Apa saja fitur umum dari pertukaran digital asset?',
  help_center_question_15: 'Q. Apakah ada risiko yang terkait dengan penggunaan NVX?',
  help_center_question_16: 'Q. Apakah ada berbagai jenis pertukaran digital asset?',
  help_center_question_17:
    'Q. Bisakah saya menggunakan NVX untuk mengubah digital asset saya menjadi mata uang fiat?',
  help_center_question_18: 'Q. Apakah ada biaya yang terkait dengan penggunaan NVX?',
  help_center_question_19:
    'Q. Apakah ada faktor penting lain yang perlu dipertimbangkan ketika menggunakan pertukaran digital asset?',
  help_center_question_20: 'Q. Apakah aman untuk menyimpan digital asset saya di NVX?',
  help_center_question_21:
    'Q. Bisakah saya menggunakan NVX untuk membeli digital asset dengan kartu kredit?',
  help_center_question_22:
    'Q. Apakah ada batasan pada siapa yang dapat menggunakan pertukaran digital asset?',
  help_center_question_23: 'Q. Apakah mungkin untuk mendapatkan uang dengan berdagang di NVX?',
  help_center_question_24: 'Q. Bisakah saya menggunakan NVX untuk mentransfer dana ke orang lain?',
  help_center_question_25: 'Q. Bisakah saya menggunakan NVX untuk membayar barang dan jasa?',
  help_center_question_26:
    'Q. Apakah ada batasan jumlah digital asset yang dapat saya beli atau jual di NVX?',
  help_center_question_27:
    'Q. Bisakah saya menggunakan NVX untuk berdagang di banyak digital asset?',
  help_center_question_28: 'Q. Bisakah saya menggunakan NVX untuk berdagang 24/7?',
  help_center_question_29:
    'Q. Bisakah saya menggunakan NVX untuk melakukan short sell digital asset?',
  help_center_question_30:
    'Q. Bisakah saya menggunakan pertukaran digital asset untuk mengatur perdagangan otomatis?',
  help_center_question_31:
    'Q. Bisakah saya menggunakan pertukaran digital asset untuk mengatur pesanan stop-loss?',
  help_center_question_32:
    'Q. Bisakah saya menggunakan pertukaran digital asset untuk mengakses wallet digital asset saya?',
  help_center_question_33:
    'Q. Bisakah saya menggunakan NVX untuk mengakses data pasar dan alat analisis?',
  help_center_question_34: 'Q. Bisakah saya menggunakan NVX untuk mengakses dukungan pelanggan?',
  help_center_question_35:
    'Q. Bisakah saya menggunakan NVX untuk mengakses sumber daya pendidikan?',
  help_center_question_36:
    'Q. Bisakah saya menggunakan NVX untuk mentransfer digital asset saya ke pertukaran lain?',
  help_center_question_37:
    'Q. Dapatkah saya menggunakan NVX untuk memperdagangkan aset berjangka atau produk turunan lainnya?',
  help_center_question_38: 'Q. Bisakah saya menggunakan NVX untuk berdagang di beberapa perangkat?',
  help_center_question_39:
    'Q. Bisakah saya menggunakan NVX untuk menerima minat pada digital asset saya?',
  help_center_answer_1:
    'A. digital asset adalah mata uang digital atau virtual yang menggunakan kriptografi untuk keamanan dan didesentralisasi, yang berarti tidak dikendalikan oleh otoritas pusat seperti pemerintah atau lembaga keuangan. digital asset dibuat dan dikelola menggunakan teknologi canggih seperti blockchain, yang menyediakan cara yang aman dan transparan untuk mencatat transaksi pada jaringan yang didesentralisasi. Beberapa contoh digital asset termasuk Bitcoin, Ethereum, dan Litecoin. Mata uang ini dapat digunakan sebagai media pertukaran untuk barang dan jasa, dan sering diperdagangkan di bursa online untuk mata uang fiat atau digital asset lainnya.',
  help_center_answer_2:
    'A. NVX adalah platform pertukaran digital asset yang memungkinkan pengguna untuk membeli, menjual, dan memperdagangkan digital asset. Pertukaran ini biasanya beroperasi secara online dan memungkinkan pengguna untuk bertukar digital asset dengan aset lain, seperti mata uang fiat atau mata uang digital lainnya.',
  help_center_answer_3:
    "A. Ada beberapa faktor yang dapat menentukan apakah pertukaran digital asset baik atau tidak. Beberapa faktor ini termasuk biaya yang dibebankan oleh pertukaran, ketersediaan berbagai mata uang kripto, keramahan pengguna platform, dan langkah-langkah keamanan yang ada untuk melindungi pengguna' dana. Selain itu, dukungan pelanggan dan reputasi keseluruhan pertukaran juga dapat menjadi faktor penting untuk dipertimbangkan. Penting bagi pengguna untuk melakukan penelitian sendiri dan membandingkan pertukaran yang berbeda untuk menemukan yang paling memenuhi kebutuhan dan preferensi mereka.",
  help_center_answer_4:
    'A. NVX beroperasi dengan cara yang mirip dengan bursa saham tradisional. Pengguna dapat membuat akun di bursa dan menyetor dana ke akun mereka menggunakan berbagai metode pembayaran. Setelah mereka memiliki dana yang tersedia di akun mereka, mereka dapat melakukan pembelian atau penjualan pesanan untuk digital asset yang didukung. Ketika pesanan beli atau jual dicocokkan dengan pesanan pengguna lain, pertukaran akan memfasilitasi perdagangan dan mentransfer dana atau digital asset di antara akun pengguna.',
  help_center_answer_5:
    'A. NVX memiliki daftar besar digital asset yang didukung, Anda mungkin melihat mana yang Anda minati untuk diperdagangkan dengan mengklik perdagangan sekarang.',
  help_center_answer_6:
    'A. NVX mengenakan biaya minimal untuk layanan mereka. Bergantung, pada pertukaran dan jenis perdagangan yang dibuat beberapa layanan membebankan biaya tetap untuk semua perdagangan, sementara yang lain membebankan persentase dari jumlah perdagangan. Yakinlah bahwa NVX memiliki misi untuk menjaga agar semua biaya tetap minimum dan adil.',
  help_center_answer_7:
    'A. Keamanan adalah prioritas utama kami karena mereka menangani sejumlah besar uang dan informasi pengguna yang sensitif. NVX memiliki perlindungan yang sesuai untuk melindungi dana dan informasi pengguna. Ini dapat mencakup langkah-langkah seperti server aman, enkripsi, dan otentikasi dua faktor.',
  help_center_answer_8:
    'A. Untuk menarik dana dari bursa, Anda perlu memiliki wallet yang disiapkan untuk menerima dana tersebut. Langkah-langkah khusus untuk menarik dana akan bervariasi tergantung pada bursa tersebut, tetapi umumnya, Anda perlu menentukan jumlah dana yang ingin Anda tarik, alamat wallet untuk mengirim dana, dan informasi keamanan yang diperlukan. Setelah Anda memasukkan informasi ini dan mengonfirmasi penarikan, bursa akan memproses transaksi dan mentransfer dana ke wallet Anda.',
  help_center_answer_9:
    'A. NVX memungkinkan pengguna untuk menukar mata uang kripto dengan mata uang fiat seperti Rupiah Indonesia. Ini bisa menjadi cara yang nyaman untuk mengkonversi kripto Anda menjadi uang tunai yang dapat digunakan dalam kehidupan nyata.',
  help_center_answer_10:
    'A. NVX memungkinkan pengguna untuk mendepositokan dana ke akun mereka menggunakan berbagai metode, seperti transfer bank atau pembayaran kartu kredit. Setelah dana didapat, pengguna dapat menggunakan platform tersebut untuk membeli dan menjual berbagai mata uang kripto. Bursa bertindak sebagai perantara, memfasilitasi transaksi dan mengambil biaya kecil untuk layanan mereka.',
  help_center_answer_11:
    'A. Likuiditas mengacu pada seberapa mudah pengguna dapat membeli dan menjual mata uang kripto di sebuah bursa. Bursa yang likuid adalah tempat di mana terdapat banyak pembeli dan penjual, dan harga mata uang kripto relatif stabil. Hal ini memudahkan pengguna untuk membeli dan menjual mata uang kripto dengan cepat dan dengan harga yang wajar.',
  help_center_answer_12:
    'A. Ya, NVX diatur oleh pemerintah Indonesia. NVX terdaftar dengan badan regulator dan mematuhi peraturan dan ketentuan yang berlaku di Indonesia. Ini membantu melindungi pengguna dan memastikan bahwa bursa beroperasi dengan cara yang adil dan transparan.',
  help_center_answer_13:
    'A. Ada beberapa faktor yang perlu dipertimbangkan saat memilih bursa kripto yang terpercaya. Ini termasuk reputasi bursa, biaya yang mereka kenakan, rentang mata uang kripto yang mereka tawarkan, dan langkah-langkah keamanan yang mereka terapkan. NVX menjaga semuanya terkendali untuk memastikan perdagangan yang adil selalu terjadi.',
  help_center_answer_14:
    'A. NVX mencakup kemampuan untuk mendepositokan dan menarik dana, kemampuan untuk membeli dan menjual berbagai mata uang kripto, dan kemampuan untuk melacak dan mengelola transaksi Anda yang mencakup alat grafik, analisis pasar, dan dukungan pelanggan.',
  help_center_answer_15:
    'A. Ada beberapa risiko yang perlu diperhatikan. Ini termasuk risiko serangan siber dan peretasan, risiko kehilangan akses ke dana Anda, dan risiko perubahan regulasi yang dapat memengaruhi operasi bursa. Namun, NVX diasuransikan terhadap kerugian akibat pelanggaran keamanan atau insiden lainnya.',
  help_center_answer_16:
    'A. Ya, ada beberapa jenis bursa kripto yang berbeda. Ini termasuk bursa terpusat, bursa terdesentralisasi, dan bursa peer-to-peer. Setiap jenis bursa memiliki fitur dan keuntungan uniknya sendiri. NVX adalah bursa terpusat yang diatur yang terdaftar dengan pemerintah Indonesia.',
  help_center_answer_17:
    'A. Ya, NVX menawarkan kemampuan untuk mengubah aset digital Anda menjadi mata uang fiat tradisional, seperti IDR. Ini dapat berguna jika Anda ingin mengakses dana di akun aset digital Anda untuk penggunaan sehari-hari atau untuk mentransfer dana ke rekening bank.',
  help_center_answer_18:
    'A. Ya, kami mengenakan biaya minimal untuk layanan kami. Biaya ini dapat berupa biaya tetap untuk semua perdagangan, sementara yang lain mengenakan persentase dari jumlah perdagangan. Namun, Anda dapat yakin bahwa NVX memiliki misi untuk menjaga semua biaya tetap minimal dan adil.',
  help_center_answer_19:
    'A. Selain faktor yang disebutkan di atas, ada beberapa hal lain yang perlu dipertimbangkan saat menggunakan bursa kripto. Ini termasuk antarmuka pengguna dan pengalaman pengguna bursa, dukungan pelanggan yang ditawarkan, dan rentang metode pembayaran yang diterima. Juga penting untuk memeriksa promosi atau diskon yang mungkin ditawarkan oleh NVX.',
  help_center_answer_20:
    'A. Meskipun secara umum dianggap lebih aman untuk menyimpan kripto Anda di wallet pribadi daripada di NVX, mungkin lebih nyaman untuk menyimpannya di bursa. Namun, NVX diasuransikan terhadap kerugian akibat pelanggaran keamanan atau insiden lainnya. Penting untuk mempertimbangkan dengan seksama pro dan kontra serta memilih opsi yang terbaik untuk kebutuhan Anda sendiri.',
  help_center_answer_21:
    'A. Saat ini NVX belum mengizinkan pembelian digital asset menggunakan kartu kredit dikarenakan regulasi yang belum memungkinkan.',
  help_center_answer_22:
    'A. Ya, NVX mungkin memiliki pembatasan tentang siapa yang dapat menggunakan platform mereka. Silakan mendaftar dan selesaikan proses KYC untuk memeriksa kelayakan Anda dan melihat apakah ada pembatasan yang berlaku bagi Anda.',
  help_center_answer_23:
    'A. Berdagang di bursa kripto dapat menjadi cara untuk menghasilkan uang, tetapi penting untuk diingat bahwa ini juga melibatkan risiko. Harga mata uang kripto dapat fluktuatif, dan mungkin saja Anda kehilangan uang selain memperoleh keuntungan. Sebaiknya melakukan penelitian yang cermat dan mengedukasi diri sebelum memulai perdagangan di bursa. Anda juga harus siap menerima risiko dan hanya menginvestasikan uang yang mampu Anda rugi.',
  help_center_answer_24:
    'A. Ya, NVX menawarkan kemampuan untuk mentransfer dana ke orang lain menggunakan platform kami. Ini bisa menjadi pilihan yang nyaman jika penerima juga memiliki akun di bursa yang sama. Namun, mungkin ada biaya dan batasan yang terlibat.',
  help_center_answer_25:
    'A. Hal ini tergantung pada pedagang dan metode pembayaran yang digunakan. Beberapa pedagang mungkin menerima aset digital sebagai pembayaran, dalam hal ini Anda dapat menggunakan bursa kripto untuk membeli aset digital yang diperlukan dan kemudian membayar pedagang secara langsung. Namun, tidak semua pedagang menerima aset digital. Di Indonesia, membayar untuk membeli barang atau jasa menggukanan digital asset merupakan hal yang dilarang undang-undang.',
  help_center_answer_26:
    'A. Hal ini dapat bervariasi tergantung pada bursa dan mata uang kripto tertentu yang diperdagangkan. Beberapa bursa mungkin memiliki batas minimum atau maksimum pada jumlah mata uang kripto yang dapat dibeli atau dijual dalam satu transaksi. Batasan ini mungkin ada untuk mencegah penipuan atau memastikan bahwa bursa memiliki likuiditas yang cukup untuk memfasilitasi perdagangan. Penting untuk memeriksa bursa dan mata uang kripto tertentu yang Anda minati untuk melihat apakah ada batasan yang berlaku.',
  help_center_answer_27:
    'A. Ya, NVX menawarkan kemampuan untuk melakukan perdagangan dalam beberapa mata uang kripto. Ini dapat nyaman jika Anda tertarik untuk mendiversifikasi portofolio Anda atau ingin memanfaatkan peluang arbitrase antara mata uang kripto yang berbeda.',
  help_center_answer_28: 'A. Ya, NVX menawarkan perdagangan 24/7.',
  help_center_answer_29:
    'A. Tidak, saat ini NVX belum memungkinkan short sell digital asset. Fitur ini sedang dalam pengembangan',
  help_center_answer_30:
    'A. Ya, NVX menawarkan kemampuan untuk mengatur perdagangan otomatis, yang dapat berguna jika Anda ingin memanfaatkan kondisi pasar tertentu atau ingin menerapkan strategi investasi jangka panjang. Namun, tidak semua bursa menawarkan fitur ini, jadi penting untuk memeriksa bursa tertentu yang Anda pertimbangkan untuk melihat opsi apa yang tersedia.',
  help_center_answer_31:
    'A. Ya, NVX menawarkan kemampuan untuk mengatur pesanan stop-loss, yang memungkinkan pengguna untuk secara otomatis menjual mata uang kripto mereka jika harga pasar mencapai tingkat tertentu. Ini dapat menjadi alat pengelolaan risiko yang berguna, karena memungkinkan pengguna untuk membatasi kerugian potensial jika pasar bergerak melawan mereka. Namun, penting untuk melakukan penelitian dan memahami dengan baik bagaimana pesanan stop-loss bekerja sebelum menggunakan fitur ini, karena mungkin tidak selalu efektif dalam melindungi dari kerugian.',
  help_center_answer_32:
    'A. Ya, NVX menawarkan kemampuan untuk mengakses wallet mata uang kripto melalui platform kami. Ini bisa menjadi pilihan yang nyaman jika Anda ingin mengelola kepemilikan mata uang kripto Anda di satu tempat. Penting untuk melakukan penelitian dan membandingkan opsi yang berbeda sebelum memilih di mana menyimpan mata uang kripto Anda.',
  help_center_answer_33:
    'A. Ya, NVX menawarkan akses ke data pasar dan alat analisis yang dapat berguna untuk melacak kinerja mata uang kripto yang berbeda dan membuat keputusan perdagangan yang terinformasi. Alat-alat ini dapat mencakup grafik, indikator pasar, dan sumber berita.',
  help_center_answer_34:
    'A. Ya, sebagian besar NVX menawarkan beberapa bentuk dukungan pelanggan, biasanya melalui email, telepon, atau sistem tiket dukungan. Ini bisa berguna jika Anda memiliki pertanyaan atau menghadapi masalah saat menggunakan bursa. Sebaiknya periksa situs web kami atau hubungi dukungan pelanggan untuk mengetahui opsi dukungan apa yang tersedia dan seberapa responsifnya mereka.',
  help_center_answer_35:
    'A. Ya, NVX menawarkan akses ke sumber daya pendidikan, seperti tutorial, panduan, dan webinar, untuk membantu pengguna mempelajari lebih lanjut tentang mata uang kripto dan cara berdagang di bursa. Ini bisa berguna jika Anda baru mengenal dunia mata uang kripto dan ingin belajar lebih lanjut sebelum memulai. Sebaiknya periksa situs web kami untuk melihat apakah kami menawarkan sumber daya pendidikan apa pun dan seberapa komprehensifnya.',
  help_center_answer_36:
    'A. Ya, NVX menawarkan kemampuan untuk mentransfer mata uang kripto Anda ke bursa lain. Ini bisa berguna jika Anda ingin memanfaatkan peluang perdagangan yang berbeda atau jika Anda ingin memindahkan mata uang kripto Anda ke bursa lain dengan alasan apa pun. Penting untuk melakukan penelitian dan membandingkan opsi yang berbeda sebelum mentransfer mata uang kripto Anda ke bursa lain.',
  help_center_answer_37:
    'A. NVX berencana untuk memungkinkan perdagangan kontrak futures dan derivatif lain, selama produk-produk ini berada di bawah regulasi digital asset di Indonesia',
  help_center_answer_38:
    'A. Ya, NVX menawarkan aplikasi seluler atau desain web responsif yang memungkinkan pengguna mengakses platform dari berbagai perangkat, seperti smartphone, tablet, atau laptop. Ini bisa nyaman jika Anda ingin berdagang saat bepergian atau jika Anda menggunakan beberapa perangkat untuk mengelola investasi Anda. Sebaiknya periksa situs web atau hubungi dukungan pelanggan kami untuk mengetahui perangkat apa yang didukung dan seberapa ramah pengguna platform tersebut di setiap perangkat.',
  help_center_answer_39:
    'A. Ya, NVX mungkin menawarkan kemampuan untuk mendapatkan bunga atas mata uang kripto Anda dengan meminjamkannya kepada pengguna lain atau berpartisipasi dalam staking atau protokol proof-of-stake lainnya. Penting untuk melakukan penelitian dan membandingkan opsi yang berbeda sebelum memilih bursa untuk mendapatkan bunga atas mata uang kripto Anda.',
  withdraw_fiat: {
    title: 'Penarikan IDR',
    note: 'Informasi rekening bank yang telah diverifikasi akan digunakan sebagai akun utama bank Anda.',
    bank_name: 'Nama Bank',
    account_name: 'Nama akun',
    account_number: 'Nomor akun',
    inputted_amount: 'Jumlah yang dimasukkan',
    amount: 'Jumlah',
    fee: 'Biaya',
    tax: 'Pajak',
    you_will_receive: 'Anda akan menerima',
    notes: {
      please_note: 'Mohon dicatat',
      note_1: 'Pastikan Virtual Account (VA) dimasukkan dengan benar',
      note_2:
        'Pastikan nama Virtual Account (VA) dari PT Aset Kripto Internasional dan Bank adalah sama',
      note_3:
        'PT Aset Kripto Internasional tidak bertanggung jawab atas masukan informasi yang salah',
      note_4: 'Pembayaran Virtual Account (VA) hanya dapat diproses melalui mobile banking.',
      note_5: 'Pembayaran Virtual Account (VA) tidak dapat dikelola melalui setoran ATM',
    },
  },
  chainalysis_note:
    'NVX menyediakan penyelidikan aset kripto yang komprehensif dan solusi pemantauan transaksi. Kami menawarkan data dan analisis blockchain ke lembaga pemerintah, pertukaran, dan lembaga keuangan di lebih dari 70 negara. Alat kepatuhan dan investigasi kami, sumber daya pendidikan, dan dukungan pelanggan bertujuan untuk membantu pelanggan memahami kegiatan blockchain. Platform data juga mendukung penyelidikan, kepatuhan, dan alat manajemen risiko yang telah berkontribusi untuk menyelesaikan kasus kriminal cyber profil tinggi dan menyediakan akses aset kripto yang aman kepada konsumen.',
  withdraw_fiat_modal: {
    title:
      'Informasi tambahan yang diperlukan untuk penarikan di atas IDR {travelRuleAmount} atau setara nilai tersebut',
    form: {
      title: 'Informasi pengirim',
      full_name: 'Nama lengkap',
      address: 'Alamat',
      excution_date: 'Tanggal eksekusi',
      source_of_funds: 'Sumber dana',
      source_of_funds_placeholder: 'Harap tentukan',
      upload_supporting_document: 'Unggah dokumen pendukung',
      field_required: 'Bidang ini tidak boleh kosong',
      submit: 'Kirim',
      invalid_document_type: 'Jenis dokumen harus gambar atau .pdf',
      document_size_over_limit: 'Ukuran dokumen harus kurang dari 10MB',
    },
  },
  withdraw_crypto_modal: {
    request: 'Permintaan berhasil dikirim!',
    sub_request: 'Silakan periksa email Anda untuk konfirmasi',
    title_1:
      'Di bawah informasi yang diperlukan untuk penarikan di bawah {amount} IDR atau setara nilai tersebut',
    title_2:
      'Informasi Tambahan Diperlukan untuk Penarikan Di Atas {amount} IDR Atau Setara Nilai Tersebut',
    form: {
      originator_info: 'Informasi pengirim',
      recipient: 'Penerima',
      full_name: 'Nama lengkap',
      full_name_placeholder: 'Harap masukkan nama lengkapnya',
      address: 'Alamat',
      address_placeholder: 'Harap masukkan alamat',
      identity_number: 'Nomor identitas',
      wallet_address: 'Alamat dompet kripto',
      source_of_funds: 'Sumber dana',
      source_of_funds_placeholder: 'Harap tentukan',
      upload_supporting_document: 'Unggah dokumen pendukung',
      field_required: 'Bidang ini tidak boleh kosong',
      submit: 'Kirim',
      invalid_document_type: 'Jenis dokumen harus gambar atau .pdf',
      document_size_over_limit: 'Ukuran dokumen harus kurang dari 10MB',
    },
  },
  source_of_funds: {
    choose: 'Pilih sumber',
    salary: 'Gaji',
    personal_saving: 'Tabungan Pribadi',
    employment_income: 'Pendapatan pekerjaan',
    property_sale: 'Penjualan properti',
    inheritances: 'Warisan dan hadiah',
    legal_settlements: 'Penyelesaian hukum',
    others: 'Lain-lain',
  },
  deposit_fiat: {
    title: 'Deposit IDR',
    destination_bank: 'Bank tujuan',
    account_name: 'Nama akun',
    va_number: 'Nomor VA',
    desc: 'Informasi rekening bank yang telah diverifikasi akan digunakan sebagai akun bank utama Anda.',
    notes: {
      please_note: 'Mohon dicatat',
      note_1: 'Pastikan Virtual Account (VA) dimasukkan dengan benar',
      note_2:
        'Pastikan nama Virtual Account (VA) dan Bank dari PT Aset Kripto Internasional adalah sama',
      note_3: 'Metode transfer adalah Online Transfer (Tidak bisa menggunakan BI FAST / SKN)',
      note_4:
        'PT Aset Kripto Internasional tidak bertanggung jawab atas masukan informasi yang salah',
      note_5: 'Pembayaran Virtual Account (VA) hanya dapat diproses melalui mobile banking.',
      note_6: 'Pembayaran Virtual Account (VA) tidak dapat dikelola melalui setoran ATM',
    },
    credit_card: 'Kartu kredit',
    bank_transfer: 'Transfer Bank',
    see_instruction: 'Cara Pembayaran',
    pay_within: 'Bayar dalam',
    expired: 'Kedaluwarsa',
    history_table: {
      time: 'Waktu',
      date: 'Tanggal',
      amount: 'Jumlah',
      status: 'Status',
      payment_method: 'Cara Pembayaran',
      action: 'Tindakan',
      bank: 'Bank',
      account: 'Akun',
    },
  },
  deposit_fiat_modal: {
    title_1: 'Konfirmasi',
    title_2: 'Pilih cara pembayaran',
    total: 'Total',
    pay_within: 'Bayar dalam',
    choose_payment_method: 'Pilih cara pembayaran',
    credit_card: 'Kartu kredit',
    bank_transfer: 'Transfer Bank',
    expires_on: 'Kedaluwarsa pada',
    expired: 'Kedaluwarsa',
    expiry: 'Kedaluwarsa',
    add_new_card: 'Tambahkan kartu baru',
    card_number: 'Nomor kartu',
    cancel: 'Batal',
    add: 'Tambahkan',
    confirm: 'Konfirmasi',
    credit_card_payment_confirmation: 'Konfirmasi Pembayaran Kartu Kredit',
    available_transfer: 'Tersedia untuk Internet Banking/Mobile Banking',
    transfer_to: 'Transfer ke',
    complete_payment: 'Lengkapi pembayaran dari {code} ke nomor akun virtual di bawah ini.',
    bill_key: 'Kunci Biller',
    bill_code: 'Kode Biller',
    account_name: 'Nama akun',
    virtual_account_number: 'Nomor Virtual Account',
    how_to_pay: 'Cara membayar',
    got_it: 'Mengerti',
    add_new_card_success: 'Berhasil menambahkan kartu baru',
    remove_card_success: 'Berhasil menghapus kartu kredit',
    confirm_remove_card: 'Apakah Anda yakin untuk menghapus kartu kredit ini?',
  },
  'card.exists': 'Kartu kredit sudah terdaftar. Silakan coba lagi',
  'bank.account.exists': 'Ada rekening bank',
  sms_otp_required: 'Mohon isi bidang kode pemulihan.',
  sms_otp_incorrect: 'Kode pemulihan salah.',
  otp_recovery_code_incorrect: 'Kode pemulihan salah.',
  not_complete_kyc: 'Harap selesaikan proses KYC untuk mengaktifkan fungsi ini',
  otp_sms_required: 'Bidang OTP SMS diperlukan.',
  bank_account_management: {
    title: 'Kelola Rekening Bank',
    bank_required: 'Anda harus memilih bank',
    add_bank: 'Tambahkan bank',
    add_new_bank: 'Tambahkan Rekening Bank Baru',
    maximum_bank_note:
      "Maksimal rekening bank adalah {limit}.\nSilakan kunjungi <a href='/account'> manajemen rekening bank </a> untuk menghapus atau menambahkan rekening bank",
    choose_bank: 'Pilih Bank',
    enter_bank_account: 'Masukkan nomor rekening bank',
    enter_bank_name: 'Masukkan nama pemilik rekening bank',
    fill_bank_auto: 'Diisi secara otomatis berdasarkan identitas KYC Anda',
    bank: 'Bank',
    account_number: 'Nomor akun',
    owner_name: 'Nama pemilik',
    action: 'Tindakan',
    add: 'Tambah',
    cancel: 'Batal',
    add_bank_success: 'Sukses menambah rekening bank baru',
    add_bank_faild: 'Tambahkan Bank Baru Gagal!',
    max_bank_account:
      'Jumlah maksimum rekening bank adalah {limit}. Harap hapus akun untuk menambahkan yang baru',
    remove_bank_success: 'Berhasil menghapus rekening bank',
    remove_bank_faild: 'Rekening bank yang dihapus gagal!',
    confirm_remove_bank: 'Apakah Anda yakin untuk menghapus akun bank ini?',
  },
  'history_table.payment_method': 'Cara Pembayaran',
  coming_soon: {
    title: 'Segera hadir',
    text_1: '🚀 Bersiaplah untuk peluncuran yang menyenangkan! 🚀',
    text_2: 'Selamat datang di intip eksklusif NVX!',
    text_3:
      'Kami senang mengumumkan bahwa sesuatu yang luar biasa akan mencapai dunia crypto, dan Anda adalah yang pertama diketahui! Tim kami telah bekerja keras membuat platform pertukaran crypto yang luar biasa yang akan merevolusi cara Anda berdagang dan berinvestasi dalam digital asset.',
    text_4: '🔥 Apa yang akan datang? 🔥',
    text_5:
      '🌟 Pengalaman perdagangan yang mulus: Bersiaplah untuk transaksi yang cepat dan antarmuka ramah pengguna yang akan membuat perdagangan menjadi mudah.',
    text_6:
      '🌐 Aksesibilitas Global: Perdagangan dari mana saja di dunia, terhubung dengan komunitas penggemar crypto yang beragam.',
    text_7:
      '💡 Keamanan mutakhir: Aset Anda adalah prioritas utama kami. Kami telah menerapkan langkah-langkah keamanan canggih untuk memastikan ketenangan pikiran Anda.',
    text_8:
      'Tapi inilah bagian terbaiknya - Anda bisa menjadi bagian dari perjalanan inovatif ini sejak awal!',
    text_9: '💌 Jadilah yang pertama tahu! 💌',
    text_10:
      'Untuk memastikan Anda salah satu yang pertama mengalami pertukaran crypto kami dan memanfaatkan manfaat burung awal eksklusif, cukup kirimkan email kepada kami di hello@nvx.co.id. Dengan bergabung dengan daftar pra-peluncuran kami, Anda akan menerima:',
    text_11:
      '- 🎁 Akses Prioritas: Dapatkan kepala mulai menjelajahi platform dan fitur-fiturnya sebelum orang lain.',
    text_12:
      '- 📈 Pembaruan orang dalam: Tetap di loop dengan mengintip, laporan kemajuan, dan pengumuman yang menarik.',
    text_13:
      '- 🤝 Penawaran Eksklusif: Nikmati promosi dan diskon khusus sebagai tanda penghargaan kami atas dukungan awal Anda.',
    text_14:
      '<p> Jangan lewatkan kesempatan ini untuk berada di garis depan revolusi crypto. Jadilah salah satu pelopor yang menyaksikan masa depan perdagangan dan investasi! </p> <p> Nantikan pembaruan lebih lanjut dengan mengikuti kami di media sosial, dan pastikan untuk berbagi berita dengan sesama penggemar crypto. Hitungan mundur ke pengalaman perdagangan yang luar biasa dimulai sekarang. </p> <p>Sampai jumpa di sisi lain inovasi!</p> <p>Salam hangat,<br />Tim NVX</p>',
  },
  intruction_modal: {
    title: 'Bagaimana cara membayar',
    content:
      '<div>Dari <strong>Aplikasi permata</strong></div><ol> <li>Buka Aplikasi <strong>Permata</strong> seluler, pilih <strong>Pembayaran Tagihan</strong></li> <li>Pilih <strong>Akun Virtual</strong></li> <li>Di <strong>Akun Virtual Nomor</strong>, input <strong>Nomor VA</strong></li> <li>Klik <strong>Berikutnya</strong>, dalam jumlah transfer input Nominal</li> <li>Klik <strong>OK</strong>, lakukan <strong>Konfirmasi Pembayaran</strong></li> </ol> <div>Dari <strong>Bank lain</strong></div> <ol> <li>Buka Aplikasi Perbankan Seluler Anda, pilih <strong>Transfer Antar Rekening Bank</strong> </li> <li>Pilih <strong>Permata</strong> sebagai rekening bank penerima target anda</li> <li>Di <strong>Nomor Rekening</strong>, input <strong>Nomor VA<strong></li> <li>Klik <strong>Berikutnya</strong>, di input <strong>Nominal</strong> jumlah transfer</li> <li>Klik <strong>OK</strong>, lakukan <strong>Konfirmasi Pembayaran</strong></li> </ol> </div>',
    permata: {
      title: 'Dari <strong>Aplikasi permata</strong>',
      content:
        "<ol class='list-intruction'> <li>Buka Aplikasi <strong>Permata</strong> seluler, pilih <strong>Pembayaran Tagihan</strong></li> <li>Pilih <strong>Akun Virtual</strong></li> <li>Di <strong>Akun Virtual Nomor</strong>, input <strong>Nomor VA</strong></li> <li>Klik <strong>Berikutnya</strong>, dalam jumlah transfer input Nominal</li> <li>Klik <strong>OK</strong>, lakukan <strong>Konfirmasi Pembayaran</strong></li> </ol>",
    },
    other: {
      title: 'Dari <strong>Bank lain</strong>',
      content:
        "<ol class='list-intruction'> <li>Buka Aplikasi Perbankan Seluler Anda, pilih <strong>Transfer Antar Rekening Bank</strong> </li> <li>Pilih <strong>Permata</strong> sebagai rekening bank penerima target anda</li> <li>Di <strong>Nomor Rekening</strong>, input <strong>Nomor VA</strong></li> <li>Metode transfer adalah <strong>Online Transfer (Tidak bisa menggunakan BI FAST / SKN)</strong></li> <li>Klik <strong>Berikutnya</strong>, di input <strong>Nominal</strong> jumlah transfer</li> <li>Klik <strong>OK</strong>, lakukan <strong>Konfirmasi Pembayaran</strong></li> </ol> ",
    },
  },
  withdraw_guide: {
    kyc: 'KYC',
    kyc_level: 'Level KYC',
    trade: 'Berdagang',
    yes: 'Ya',
    no: 'Tidak',
    unlimited: 'Tak terbatas',
    unlimited_monthly: 'Bulanan Tidak Terbatas',
    fiat_deposit: 'Deposit IDR',
    fiat_deposit_limit: 'Batas setoran IDR',
    fiat_withdrawal: 'Penarikan IDR',
    fiat_withdrawal_limit: 'Batas penarikan IDR',
    crypto_deposit: 'Crypto Deposit',
    crypto_deposit_limit: 'Batas setoran crypto',
    crypto_withdraw: 'Penarikan Crypto',
    crypto_withdraw_limit: 'Batas penarikan crypto',
    fiat_withdrawal_lv3_limit: 'IDR 250.000.000 per 24 jam',
    crypto_withdrawal_lv3_limit: 'IDR 250.000.000 setara per 24 jam',
    fiat_withdrawal_lv4_limit: 'Informasi tambahan diperlukan di atas IDR 250.000.000 per 24 jam',
    crypto_withdrawal_lv4_limit: 'Informasi tambahan diperlukan di atas IDR 250.000.000 per 24 jam',
  },
  // kyc digisign error
  digisign_error: {
    expired:
      'Tautan aktivasi digisign Anda telah kedaluwarsa. Silakan klik tombol “Kirim” lagi untuk langkah berikutnya',
    name_invalid: 'Format nama yang salah',
    id_invalid: 'Format NIK yang salah',
    id_not_found: 'NIK/ID tidak ditemukan',
    name_dob_not_match: 'Nama dan tanggal lahir tidak cocok',
    dob_not_match: 'Tanggal lahir tidak cocok',
    name_not_match: 'Nama tidak cocok di lembaga verifikasi',
    name_selfife_not_match: 'Nama dan foto selfie tidak cocok',
    name_dob_not_match: 'Nama dan tanggal lahir tidak cocok',
    no_face_selfie: 'Tidak ada wajah yang ditemukan di selfie',
    no_face_ktp: 'Tidak ada wajah yang ditemukan di KTP',
    face_verify_failed: 'Verifikasi wajah gagal. Verifikasi gagal di lembaga pemerintah',
    ktp_invalid:
      'Verifikasi Pengguna Gagal. Pastikan lampu sudah cukup dan foto KTP/ID cocok dengan foto selfie',
    phone_number_invalid: 'Format hp harus 62, 08 atau +62',
    data_invalid:
      'Pengguna telah mencoba terdaftar lebih dari 3 kali dengan data yang tidak valid. Silakan periksa data terlebih dahulu.',
    system_timeout: 'Batas waktu sistem, coba lagi 10 menit kemudian',
    unkown_error: 'Kesalahan yang tidak diketahui!',
    ktp_taken:
      'NIK sudah terdaftar dengan email <strong>{email}</strong>, silahkan login atau daftar dengan email yang sesuai NIK atau gunakan NIK lain.',
  },
};
