<template>
  <div class="datatable" id="table_wd_deposit">
    <div class="title-table" v-show="configs.is_show_title">
      <div class="item left">
        <span>{{ $t('funds.withdrawals.history') }}</span>
        <div class="item group-tooltip">
          <span class="help-box">
            <img src="/images/icon/WarningCircle2.png" alt="" height="18" width="18" />
            <div class="box-tooltip">
              <div class="bubble top" id="still-not-receive">
                <div>{{ $t('funds.withdrawals.tooltip') }}</div>
                <a @click="openPopupContactUs()">{{ $t('menu_bottom.contact_us') }}</a>
              </div>
            </div>
          </span>
          <span>{{ $t('funds.withdrawals.did_not_receive_the_mail') }}</span>
        </div>
      </div>
      <div class="item right">
        <div class="item">
          <router-link
            :to="{ name: 'Fund History', query: { type: configs.type } }"
            target="_blank"
          >
            <span class="view_all">{{ $t('funds.withdrawals.view_all') }}</span>
          </router-link>
        </div>
      </div>
      <div class="item responsive">
        <router-link :to="{ name: 'Fund History', query: { type: configs.type } }" target="_blank">
          <span class="view_all">{{ $t('funds.withdrawals.view_all') }}</span>
        </router-link>
      </div>
      <!-- <div class="item group-tooltip responsive">
        <span class="icon-help">
          <div class="box-tooltip">
            <div
              class="bubble top"
              id="still-not-receive"
            >
              <div>{{ $t('funds.withdrawals.tooltip') }}</div>
              <a @click="openPopupContactUs()">{{ $t('menu_bottom.contact_us')}}</a>
            </div>
          </div>
        </span>
        <span>{{ $t('funds.withdrawals.did_not_receive_the_mail') }}</span>
      </div> -->
    </div>

    <!-- MOBILE VIEW  -->
    <template v-if="isMobile">
      <div class="transaction-history__mobile-wrapper">
        <data-list-mobile
          ref="datatable"
          :msg-empty-data="''"
          :get-data="getData"
          :isExpand="true"
          :limit="10"
          :height="30"
        >
          <template slot="table-empty-header">
            <div class="transaction-history__mobile-title">
              <div class="mobile-title__item">
                {{ $t('funds.history.status') }}
              </div>
              <div class="mobile-title__item">
                {{ $t('funds.history.coin') }}
              </div>
              <div class="mobile-title__item">
                {{ $t('funds.history.recevied_amount') }}
              </div>
            </div>
          </template>
          <template slot="header" slot-scope="props">
            <div class="transaction-history__mobile-title">
              <div class="mobile-title__item" data-sort-field="status" @click="props.onSort">
                {{ $t('funds.history.status') }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              </div>
              <div class="mobile-title__item" data-sort-field="currency" @click="props.onSort">
                {{ $t('funds.history.coin') }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              </div>
              <div
                class="mobile-title__item"
                id="nowrap"
                data-sort-field="amount"
                @click="props.onSort"
              >
                {{ $t('funds.history.recevied_amount') }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              </div>
            </div>
          </template>
          <template slot="body" slot-scope="props">
            <div class="transaction-history__mobile-item">
              <div class="mobile-item__wrapper">
                <div class="item-wrapper__title">
                  <div
                    v-if="props.item.status === 'failed' && props.item.remarks"
                    class="item group-tooltip"
                    :class="`td-strong ${props.item.status}`"
                  >
                    <span style="font-size: 16px">{{
                      $t(`common.transaction_status.${props.item.status}`)
                    }}</span>
                    <span class="help-box">
                      <img src="/images/icon/Question.png" alt="tolltip" />
                      <div class="box-tooltip">
                        <div class="bubble top" id="still-not-receive">
                          <div>{{ $t(`common.transaction_message.${props.item.remarks}`) }}</div>
                          <a @click="openPopupContactUs()">{{ $t('menu_bottom.contact_us') }}</a>
                        </div>
                      </div>
                    </span>
                  </div>
                  <div v-else :class="`td-strong ${props.item.status}`">
                    <strong>{{ $t(`common.transaction_status.${props.item.status}`) }}</strong>
                  </div>
                </div>
                <div class="item-wrapper__title">
                  {{ props.item.currency | uppercase }}
                </div>
                <div class="item-wrapper__title">
                  {{ props.item.amount | abs | formatCurrencyAmount(null, '0') }}
                </div>
                <div class="item-wrapper__dropdown-icon" @click="props.onClickExpandItem(props)">
                  <img
                    src="/images/instant-convert/icon-dropdown.svg"
                    alt=""
                    v-if="!props.isActive"
                  />
                  <img src="/images/instant-convert/icon-dropdown2.svg" alt="" v-else />
                </div>
              </div>
              <div class="mobile-item__expand-content" :class="{ active: props.isActive }">
                <div class="expand-content__wrapper" v-if="configs.type === 'withdraw'">
                  <div class="expand-content__title">
                    {{ $t('withdraw_fiat.fee') }}
                  </div>
                  <div class="expand-content__desc">
                    {{ formatFeeTax(props.item.fee, null, '0') }}
                  </div>
                </div>
                <div
                  class="expand-content__wrapper"
                  v-if="isUserIndo && configs.type === 'withdraw'"
                >
                  <div class="expand-content__title">
                    {{ $t('withdraw_fiat.tax') }}
                  </div>
                  <div class="expand-content__desc">
                    {{ props.item.tax | abs | formatCurrencyAmount('idr', '0') }}
                  </div>
                </div>
                <div class="expand-content__wrapper">
                  <div class="expand-content__title">
                    {{ $t('funds.history.date') }}
                  </div>
                  <div class="expand-content__desc">
                    <span>{{ props.item.created_at | orderDate }} </span>
                    <span>{{ props.item.created_at | orderTime }}</span>
                  </div>
                </div>
                <hr />

                <template>
                  <div v-if="configs.type === 'withdraw'" class="expand-content__wrapper">
                    <div class="expand-content__title">
                      {{ $t('funds.history.to_address') }}:&nbsp;
                    </div>
                    <div class="expand-content__desc">
                      {{ removeTag(props.item.to_address) }}
                    </div>
                  </div>
                  <div v-else class="expand-content__wrapper">
                    <div class="expand-content__title">
                      {{ $t('funds.history.from_address') }}:&nbsp;
                    </div>
                    <div class="expand-content__desc">
                      {{ removeTag(props.item.from_address) }}
                    </div>
                  </div>
                  <template
                    v-if="
                      (props.item.currency === 'xrp' || props.item.currency === 'eos') &&
                      configs.type === 'withdraw'
                    "
                  >
                    <div class="expand-content__wrapper">
                      <div class="expand-content__title">{{ $t('funds.history.tag') }}:&nbsp;</div>
                      <div class="expand-content__desc">
                        {{ props.item.blockchain_sub_address }}
                      </div>
                    </div>
                  </template>
                  <div class="expand-content__wrapper">
                    <div class="expand-content__title">{{ $t('funds.history.txhash') }}:&nbsp;</div>
                    <div
                      class="expand-content__desc link"
                      v-if="props.item.tx_hash"
                      @click="gotoTransactionUrl(props.item)"
                    >
                      {{ props.item.tx_hash }}
                    </div>
                    <!-- <div class="expand-content__desc" v-else>
                      {{ props.item.transaction_id }}
                    </div> -->
                  </div>
                  <div class="expand-content__wrapper">
                    <div class="expand-content__title">
                      {{ $t('funds.history.number_of_confirmations') }}:&nbsp;
                    </div>
                    <div class="expand-content__desc">
                      {{ props.item.confirmation_number }}
                    </div>
                    <div
                      v-if="props.item.confirmation_number && props.item.status === 'success'"
                      class="td-strong success"
                    >
                      {{ $t('funds.history.confirmed') }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </data-list-mobile>
      </div>
    </template>

    <!-- WEB VIEW -->

    <template v-else>
      <data-table3
        :getData="getData"
        :limit="10"
        :widthTable="'100%'"
        ref="datatable"
        :msgEmptyData="configs.msgEmptyData"
        @DataTable:finish="onDatatableFinish"
      >
        <template slot-scope="props">
          <th class="cl1 t-left sort" data-sort-field="status" @click="props.echoclick">
            <div class="th-group">
              <strong class="th-strong">{{ $t('funds.history.status') }}</strong>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </div>
          </th>
          <th class="cl2 t-left sort" data-sort-field="currency" @click="props.echoclick">
            <div class="th-group">
              <strong class="th-strong">{{ $t('funds.history.coin') }}</strong>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </div>
          </th>
          <th class="cl3 t-left sort" data-sort-field="amount" @click="props.echoclick">
            <div class="th-group">
              <strong class="th-strong">{{ $t('funds.history.recevied_amount') }}</strong>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </div>
          </th>
          <th
            class="cl4 t-left sort"
            data-sort-field="fee"
            @click="props.echoclick"
            v-if="configs.type === 'withdraw'"
          >
            <div class="th-group">
              <strong class="th-strong">{{ $t('funds.history.fee') }}</strong>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </div>
          </th>
          <th
            class="cl5 t-left sort"
            data-sort-field="tax"
            @click="props.echoclick"
            v-if="isUserIndo && configs.type === 'withdraw'"
          >
            <div class="th-group">
              <strong class="th-strong">{{ $t('funds.history.tax') }}</strong>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </div>
          </th>
          <th
            class="cl6 t-right sort"
            data-sort-field="created_at"
            @click="props.echoclick"
            colspan="2"
          >
            <div class="th-last-group">
              <strong class="th-strong">{{ $t('funds.history.date') }}</strong>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </div>
          </th>
        </template>
        <template slot="body" slot-scope="props">
          <tr>
            <td class="cl1 t-left item group-tooltip" :class="{ even: props.index % 2 === 1 }">
              <div
                v-if="props.item.status === 'failed' && props.item.remarks"
                :class="`td-strong ${props.item.status}`"
              >
                <span style="font-size: 16px">{{
                  $t(`common.transaction_status.${props.item.status}`)
                }}</span>
                <span class="help-box">
                  <img src="/images/icon/Question.png" alt="tolltip" />
                  <div class="box-tooltip">
                    <div class="bubble top" id="still-not-receive">
                      <div class="">
                        {{ $t(`common.transaction_message.${props.item.remarks}`) }}
                      </div>
                      <a @click="openPopupContactUs()">{{ $t('menu_bottom.contact_us') }}</a>
                    </div>
                  </div>
                </span>
              </div>
              <div v-else :class="`td-strong ${props.item.status}`">
                {{ $t(`common.transaction_status.${props.item.status}`) }}
              </div>
            </td>
            <td class="cl2 t-left">
              <span>
                <strong class="td-strong">{{ props.item.currency | uppercase }}</strong>
              </span>
            </td>
            <td class="cl3 t-left">
              <strong class="td-strong">{{
                props.item.amount | abs | formatCurrencyAmount(null, '0')
              }}</strong>
            </td>
            <td class="cl4 t-left" v-if="configs.type === 'withdraw'">
              <strong class="td-strong">{{ formatFeeTax(props.item.fee, null, '0') }}</strong>
            </td>
            <td class="cl5 t-left" v-if="isUserIndo && configs.type === 'withdraw'">
              <strong class="td-strong">{{ formatFeeTax(props.item.tax, null, '0') }}</strong>
            </td>
            <td class="cl6 t-right">
              <div class="date-action">
                <strong class="td-strong">{{ props.item.created_at | timestampToDate }}</strong>
                <span
                  class="icon"
                  :class="isActiveRow === props.index ? 'icon-arrow2' : 'icon-arrow1'"
                  @click="activeRow(props.index)"
                ></span>
              </div>
            </td>
            <!-- <td class="t-right" @click="activeRow(props.index)">
              <span
                v-if="isActiveRow === props.index"
                class="icon-arrow2 icon"
              ></span>
              <span v-else class="icon-arrow1 icon"></span>
            </td> -->
          </tr>
          <tr v-if="isActiveRow === props.index" :class="{ even: props.index % 2 === 1 }">
            <td class="info" :colspan="isUserIndo ? 7 : 6">
              <template>
                <div>
                  <!-- UNIQUE CODE -->
                  <div v-if="configs.type === 'withdraw'" class="address_txid">
                    <span class="address_txid1">{{ $t('funds.history.unique_code') }}:&nbsp;</span>
                    <span>{{ props.item.unique_code }}</span>
                  </div>

                  <span v-if="configs.type === 'withdraw'" class="label-text">
                    {{ $t('funds.history.to_address') }}:&nbsp;</span
                  >
                  <span v-else class="label-text">
                    {{ $t('funds.history.from_address') }}:&nbsp;</span
                  >
                  <span v-if="configs.type === 'withdraw'" class="label-address">
                    {{ removeTag(props.item.to_address) }}
                  </span>
                  <span v-else class="label-address">
                    {{ removeTag(props.item.from_address) }}
                  </span>

                  <template
                    v-if="
                      (props.item.currency === 'xrp' || props.item.currency === 'eos') &&
                      configs.type === 'withdraw'
                    "
                  >
                    <div>
                      <span class="label-text">{{ $t('funds.history.tag') }}:&nbsp;</span>
                      <span class="label-address">{{ props.item.blockchain_sub_address }}</span>
                    </div>
                  </template>
                  <div class="txid-link">
                    <span class="label-text">{{ $t('funds.history.txhash') }}:&nbsp;</span>
                    <span
                      class="link"
                      v-if="props.item.tx_hash"
                      @click="gotoTransactionUrl(props.item)"
                      >{{ props.item.tx_hash }}</span
                    >

                    <!-- <span
                      class="link"
                      v-if="props.item.is_external && props.item.tx_hash"
                      @click="gotoTransactionUrl(props.item)"
                      >{{ props.item.tx_hash }}</span
                    >
                    <span v-else>{{ props.item.transaction_id }}</span> -->
                  </div>
                  <span v-if="props.item.confirmation_number" class="label-text">
                    {{ $t('funds.history.number_of_confirmations') }}:&nbsp;
                  </span>
                  <span v-if="props.item.confirmation_number" class="label-address">
                    {{ props.item.confirmation_number }}
                  </span>
                  <span
                    v-if="props.item.confirmation_number && props.item.status === 'success'"
                    class="td-strong success"
                  >
                    {{ $t('funds.history.confirmed') }}
                  </span>
                </div>
              </template>
            </td>
          </tr>
        </template>
      </data-table3>
    </template>
  </div>
</template>
<script>
import rf from '@/request/RequestFactory';
import Utils from '@/common/Utils';
import BigNumber from 'bignumber.js';

export default {
  props: {
    configs: {
      type: Object,
      default: function () {
        return {
          type: '',
          is_show_title: true,
          msgEmptyData: '',
          linkContactUs: '',
        };
      },
    },
    isUserIndo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      FixSortIE: null,
      isActiveRow: -1,
      coin: null,
      kyc: {
        status: '',
        country: '',
      },
      blockExplorers: {},
    };
  },

  watch: {
    configs: function () {
      this.refresh();
    },
  },
  computed: {
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
  },

  methods: {
    removeTag(address) {
      return address.split('|')[0];
    },
    formatFeeTax(amount, currency) {
      if (
        amount != 0 &&
        new BigNumber(amount).lt(0.00000001) &&
        currency !== 'idr' &&
        currency !== 'usdt' &&
        currency !== 'busd'
      )
        return '0.00000001';
      return Utils.formatCurrencyAmount(amount, currency, '0');
    },
    openPopupContactUs() {
      window.FreshworksWidget('open');
    },

    activeRow(rowIndex) {
      this.isActiveRow = this.isActiveRow === rowIndex ? -1 : rowIndex;
    },

    onDatatableFinish() {
      const datatable = this.$refs.datatable;
      // Only sort 'amount' field a again. Because amount field can be negative (withdraw) or positive (deposit).
      if (datatable.params.sort !== 'amount') {
        return;
      }
      const result = window._.chain(datatable.rows)
        .map((item) => {
          item.amount = Math.abs(parseFloat(item.amount));
          return item;
        })
        .orderBy([datatable.params.sort], [datatable.params.sort_type])
        .value();
      this.$refs.datatable.rows = result;
    },

    getData(params) {
      this.isActiveRow = -1;
      let meta = {
        type: this.configs.type,
      };
      return rf
        .getRequest('TransactionRequest')
        .getCoinHistory(Object.assign({}, params, meta), this.coin ? this.coin : null);
    },
    onSelectedCoinChange(coin) {
      this.coin = coin;
      this.refresh();
    },

    getTransactionUrl(currency, transactionId) {
      return Utils.getTransactionUrl(currency, transactionId);
    },

    onTransactionCreated() {
      this.refresh();
    },

    getSocketEventHandlers() {
      return {
        TransactionCreated: this.onTransactionCreated,
        TransactionUpdated: this.onTransactionCreated,
        selectedCoinChange: this.onSelectedCoinChange,
      };
    },

    refresh() {
      this.$refs.datatable.refresh();
    },

    gotoTransactionUrl(tx) {
      let blockExplorer = this.blockExplorers.find((item) => item.coin == tx.currency);
      let url = blockExplorer.transaction_tx_path.replace('{$transaction_id}', tx.tx_hash);

      if (tx.network === 'trc20' && tx.currency == 'usdt') {
        url = blockExplorer.transaction_tx_path_trc20.replace('{$transaction_id}', tx.tx_hash);
      }

      window.open(url, '_blank');
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    let rootPath = this.$router.options.routes.find((el) => el.name == 'Layout');
    let path =
      rootPath && rootPath.children && rootPath.children.find((el) => el.name == 'Contact US');
    this.linkContactUs = path && path.path;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.blockExplorers = this.$store.state.masterdata.coins;
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
};
</script>

<style lang="scss" scoped>
.cl1,
.cl2,
.cl4,
.cl5,
.cl6 {
  width: auto;
}

.cl3 {
  width: 150px;
}

.cl6.t-right {
  min-width: 135px;
}

.date {
  white-space: nowrap;
}

.date-action {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}

#table_wd_deposit {
  position: relative;
  overflow-x: hidden;
  background-color: $color-white;
  min-height: 200px;

  .transaction-history__mobile-wrapper {
    .data-list__wrapper {
      .transaction-history__mobile-title {
        display: grid;
        border-radius: 10px 10px 0px 0px;
        background-color: $bg-main4;
        padding: 8px 16px;
        grid-template-columns: repeat(3, 1fr);

        .mobile-title__item {
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          color: $text-secondary;

          &#nowrap {
            display: flex;
            white-space: nowrap;
          }
        }
      }

      .transaction-history__mobile-item {
        background-color: $color-white;
        padding: 10px 16px;
        border-bottom: 1px solid $bg-main2;

        .mobile-item__wrapper {
          display: grid;
          position: relative;
          grid-template-columns: repeat(3, 1fr);

          .item-wrapper__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
            color: $text-main;
          }

          .item-wrapper__dropdown-icon {
            position: absolute;
            right: 0px;
          }
        }

        .mobile-item__expand-content {
          display: none;

          &.active {
            display: flex;
          }

          margin-top: 12px;
          background-color: $bg-main4;
          padding: 16px;
          flex-direction: column;
          border-radius: 8px;
          gap: 8px;

          .expand-content__wrapper {
            display: flex;
            justify-content: space-between;

            .expand-content__title {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              color: $text-main;
            }

            .expand-content__desc {
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 22px;
              color: $text-main;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              word-wrap: break-word;

              &.link {
                color: $color-blue;
                cursor: pointer;
              }
            }
          }

          hr {
            margin: 10px 0px;
            border-color: #ddd;
          }
        }
      }
    }
  }

  table {
    thead {
      th {
        height: 40px;
        background: #f8f8f8;
        color: $text-main2;
        border: none;
        font-family: $helvetica;
        padding: 8px 5px;

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }
      }
    }

    tbody {
      tr {
        &:hover {
          background: white;
        }
      }

      td {
        padding: 15px 5px;
        font-family: $helvetica !important;

        &:first-child {
          padding-left: 24px;
        }

        &:last-child {
          padding-right: 24px;
        }

        &.info {
          padding: 0 16px 16px 16px;
          background: white;

          > div {
            background: #f8f8f8;
            padding: 16px;
            border-radius: 8px;

            .label-text {
              color: $text-main;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;

              @include mobile {
                font-size: 14px;
              }
            }

            .label-address {
              color: $text-main;
              font-size: 16px;
              font-weight: 300;
              line-height: 24px;

              @include mobile {
                font-size: 14px;
              }
            }
          }

          p {
            color: $text-main;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  :deep .empty-data .empty-noti .text-wrapper {
    gap: 0;

    span {
      color: $text-secondary;
      font-family: $helvetica;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      @include mobile {
        font-size: 10px;
        line-height: 13px;
      }
    }
  }
}

.icon-arrow1 {
  padding-left: 10px;
  color: $dark-3;

  &.icon {
    cursor: pointer;
  }
}

.icon-arrow2 {
  padding-left: 10px;
  color: $dark-3;

  &.icon {
    cursor: pointer;
  }
}

.sort,
.glyphicon {
  cursor: pointer;
}

.txid-link {
  .link {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: $color-blue;
    cursor: pointer;

    @include mobile {
      font-size: 14px;
    }
  }

  span {
    color: $text-main;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;

    @include mobile {
      font-size: 14px;
    }
  }
}

.th-group,
.th-last-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.th-last-group {
  justify-content: flex-end;
}

.th-strong {
  color: $text-secondary;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;

  @include mobile {
    font-size: 12px;
  }
}

.td-strong {
  color: $text-main;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;

  @include mobile {
    font-size: 14px;
  }

  &.success {
    color: $color-green;
    font-weight: 700;
  }

  &.pending {
    color: $color-yellow !important;
    font-weight: 700;
  }

  &.rejected {
    color: $color-red;
    font-weight: 700;
  }

  &.failed {
    color: $color-red;
    font-weight: 700;
  }

  &.cancel {
    color: $text-main-blur !important;
    font-weight: 700;
  }
}

.title-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 0 24px;
  margin-bottom: 18px;

  @include tablet {
    min-height: unset;
  }

  .responsive {
    display: none;
  }

  .item {
    .view_all {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: $text-main;
    }
  }

  .left {
    color: $text-main;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    display: flex;
    gap: 10px;
  }

  .right {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    gap: 40px;
  }
}

.group-tooltip {
  color: $bg-main6;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 5px;

  .help-box {
    position: relative;

    img {
      cursor: pointer;
    }

    + span {
      white-space: nowrap;
    }
  }

  .box-tooltip {
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 100%;
      translate: -50%;
      border: 5px solid transparent;
      border-bottom-color: $tool-tip-color;
    }

    width: 200px;
    position: absolute;
    display: none;
    z-index: 999999;
    text-align: center;
    background: $tool-tip-color;
    left: 50%;
    top: calc(100% + 0.75rem);
    translate: -50%;
  }

  &:hover .box-tooltip {
    display: block;
  }
}

.bubble {
  color: $color-white;
  font-family: $helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  padding: 15px;
  width: 100%;

  a {
    &:hover {
      color: $color-white;
      font-family: $helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-decoration: none;
      color: $color-blue !important;
    }
  }

  /*triangle side*/

  /*top*/
  &.top:after {
    border-color: $color-white transparent;
    border-width: 0 13px 12px 13px;
    top: -12px;
    left: 45%;
  }

  &.top:before {
    border-color: #d0cfd2 transparent;
    border-width: 0 13px 11px 13px;
    top: -13px;
    left: 45%;
  }
}

tr {
  vertical-align: top;
  height: 40px;
  overflow-y: hidden;
  transition-property: height;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease, ease-in;
  background: $color-white;

  &.active {
    max-height: 300px;
    transition-property: height;
    transition-duration: 0.3s, 0.3s;
    transition-timing-function: ease, ease-in;

    .icon-arrow_2_1 {
      transition-duration: 0.5s;
      color: $color-yellow-pale;
      transform: rotate(180deg);
    }
  }

  &:hover {
    background: $color-bright-gray;
  }

  th {
    white-space: nowrap;
  }

  td {
    &.active {
      background-color: $color-bright-gray;
    }
  }
}

.datatable {
  th {
    background-color: $color-white;
  }
}

.address_txid {
  display: flex;

  .address_txid1 {
    color: $text-main;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  & > :nth-child(2) {
    color: $text-main;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.item_email_user {
  display: block;
  position: relative;

  .txt_email_user {
    display: block;
    max-width: 500px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tooltip_email_user {
    position: absolute;
    width: 500px;
    top: -17px;
    left: 20px;
    line-height: 20px;
    padding: 5px 20px;
    background-color: $color_white;
    white-space: normal;
    word-wrap: break-word;
    z-index: 10;
    font-size: $font_root;
    font-weight: 500;
    color: $color-grey-dark;
    transition: 0.5s;
    display: none;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);

    &:after {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-right-color: $color_white;
      border-width: 5px;
      margin-top: -5px;
    }
  }

  &:hover {
    .tooltip_email_user {
      display: block;
      transition: 0.5s;
    }
  }
}

:deep() {
  table {
    @include mobile {
      min-width: fit-content;
    }
  }
}
</style>

<style lang="scss">
#still-not-receive {
  a {
    color: $color-blue-custom !important;
  }
}

#table_wd_deposit {
  .tableContainer {
    width: 100%;
    position: relative;
    min-height: 700px;
    overflow-x: auto;

    @include tablet {
      min-height: unset;
    }
  }

  .VuePagination {
    .pagination {
      margin-bottom: 0;

      @include mobile {
        margin-bottom: 20px;
      }
    }
  }

  @include tablet-d {
    .empty-data .empty-noti {
      min-height: auto;
      height: unset;
    }
  }

  @include mobile {
    .tableContainer > :nth-child(2) {
      position: sticky;
      left: 0;
      width: fit-content;
    }

    .title-table {
      flex-wrap: wrap;
      gap: 10px;

      .item {
        .view_all {
          color: $text-main;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
        }
      }

      .left {
        color: $text-main;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        width: 70%;
        align-items: center;
      }

      .right {
        display: none;
      }

      .responsive {
        display: flex;
        margin-left: auto;
      }

      .group-tooltip {
        flex-basis: 100%;
        font-size: 10px;
      }
    }

    .group-tooltip {
      .box-tooltip {
        left: 0;
        top: calc(100% + 0.75rem);
        translate: none;

        &::before {
          left: 0.25rem;
          translate: none;
        }
      }
    }
  }
}
</style>
